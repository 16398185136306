/* eslint-disable */
import * as React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import MaterialReactTable from 'material-react-table';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useSnackbar } from 'notistack';
import EditIcon from '@mui/icons-material/Edit';
import { Delete as DeleteIcon } from '@mui/icons-material';
import { useNavigate, Link as RouterLink } from 'react-router-dom';
// import {Container} from '@mui/material'
// import { PATH_DASHBOARD } from '../../../routes/paths';

import { Box, IconButton, Typography, Button, Paper, Container } from '@mui/material';
import axiosInstance from '../../../../utils/axios';
import Page from '../../../../components/Page';
// import { PATH_DASHBOARD } from '../../routes/paths';

import Iconify from '../../../../components/Iconify';
import HeaderBreadcrumbs from '../../../../components/HeaderBreadcrumbs';

import Dia from './Dia';

export default function PdfScore() {
  const { enqueueSnackbar } = useSnackbar();

  const navigate = useNavigate();
  const handleCloseModal1 = () => {
    setIsModalOpen1(false)
};
  const [isModalOpen1, setIsModalOpen1] = React.useState(false);

  const handleOpenModal1 = () => {
    console.log('');
    // setCommentView(report?.report);
    setIsModalOpen1(true);
  };
  const [Data, setdata] = React.useState([]);
  const [loader, setloader] = React.useState(false);
  // console.log(Data, 'Data');
  const GetAllAdmin = () => {
    setloader(true)
    axiosInstance
      .get('admin/scorecards')
      .then((response) => {
        if (response.data.scoreCards) {
          // console.log(response?.data);
          setdata(response?.data?.scoreCards);
          setloader(false)
        }
        console.log(response, 'resp');
      })
      .catch((error) => {
        console.error(error);
        setloader(false)
      });
  };
  React.useEffect(() => {
    GetAllAdmin();
  }, []);
  async function handleDelete(rowdata) {
    // console.log(rowdata);
    try {
      await axiosInstance.delete(`admin/scorecards/${rowdata}`).then((response) => {
        if (response?.data?.message === 'Deleted successfully') {
          enqueueSnackbar(response?.data?.message);
          GetAllAdmin();
        }
      });
    } catch (error) {
      enqueueSnackbar(error?.message, {
        variant: 'error',
      });
      console.error(error);
    }
  }
  const columns = React.useMemo(
    () =>[
        {
          accessorKey: 'id',
          header: 'ID',
          size: 10,
        },
        {
          accessorKey: 'transporterId',
          header: 'Transporter ID',
          size: 20,
        },
        {
          accessorKey: 'name',
          header: 'Name',
          size: 20,
        },
        {
          accessorKey: 'transporter_id',
          header: 'Transporter ID',
          size: 20,
        },
        {
          accessorKey: 'overall_tier',
          header: 'Overall Tier',
          size: 20,
        },
        {
          accessorKey: 'delivered',
          header: 'Delivered',
          size: 20,
        },
        {
          accessorKey: 'key_focus_area',
          header: 'Key Focus Area',
          size: 20,
        },
        {
          accessorKey: 'fico_score',
          header: 'Fico Score',
          size: 20,
        },
        {
          accessorKey: 'seatbelt_off_rate',
          header: 'Seatbelt Off Rate',
          size: 20,
        },
        {
          accessorKey: 'speeding_event_rate',
          header: 'Speeding Event Rate',
          size: 20,
        },
        {
          accessorKey: 'distractions_rate',
          header: 'Distractions Rate',
          size: 20,
        },
        {
          accessorKey: 'following_distance_rate',
          header: 'Following Distance Rate',
          size: 20,
        },
        {
          accessorKey: 'sign_signal_violations_rate',
          header: 'Sign Signal Violations Rate',
          size: 20,
        },
        {
          accessorKey: 'cdf',
          header: 'Cdf',
          size: 20,
        },
        {
          accessorKey: 'ced',
          header: 'Ced',
          size: 20,
        },
        {
          accessorKey: 'dcr',
          header: 'Dcr',
          size: 20,
        },
        {
          accessorKey: 'dsb',
          header: 'Dsb',
          size: 20,
        },
        {
          accessorKey: 'swcpod',
          header: 'Swcpod',
          size: 20,
        },
        {
          accessorKey: 'swccc',
          header: 'Swccc',
          size: 20,
        },
        {
          accessorKey: 'swcad',
          header: 'Swcad',
          size: 20,
        },
        {
          accessorKey: 'dsb_dnr',
          header: 'Dsb Dnr',
          size: 20,
        },
        {
          accessorKey: 'pod_opps',
          header: 'Pod Opps',
          size: 20,
        },
        {
          accessorKey: 'cc_opps',
          header: 'Cc opps',
          size: 20,
        },
        {
          accessorKey: 'week',
          header: 'Week',
          size: 20,
        },
        {
            accessorKey: 'actions',
            header: 'Actions',
            size: 10,
            Cell: ({ row }) => (
              <Box>
             
                <IconButton
                  color="error"
                  sx={{
                    border: '1px solid',
                    borderColor: 'error.main',
                  }}
                  onClick={() => {
                    handleDelete(row.original.id);
                  }}
                >
                  <DeleteIcon />
                </IconButton>
              </Box>
            ),
          },
      ]
,      
    []
  );
  
  const data = [
    {
      id: 1,
      associateName: 'John Doe',
      transporterID: '12345',
      driverID: '67890',
      phoneNumber: '123-456-7890',
      email: 'john@example.com',
      startDate: '2022-01-01',
      status: 'Active',
      CDV: 'Yes',
      stepVan: 'No',
      electricVehicle: 'Yes',
      preferredVehicle1: 'Toyota Camry',
      preferredVehicle2: 'Honda Accord',
      preferredVehicle3: 'Tesla Model S',
    },
    {
      id: 2,
      associateName: 'Jane Smith',
      transporterID: '54321',
      driverID: '09876',
      phoneNumber: '987-654-3210',
      email: 'jane@example.com',
      startDate: '2022-02-01',
      status: 'Inactive',
      CDV: 'No',
      stepVan: 'Yes',
      electricVehicle: 'No',
      preferredVehicle1: 'Ford Fusion',
      preferredVehicle2: 'Chevrolet Malibu',
      preferredVehicle3: 'N/A',
    },
    // Add more data as needed
  ];
  
  const [tableData, setTableData] = React.useState([]);


  React.useEffect(() => {
    setTableData(data);
  }, []);

  return (
<Page title="Score Card">
  <Container maxWidth="lg">
    <HeaderBreadcrumbs
      heading="Score Card"
      links={[{ name: '', href: '' }]}
      action={
        <>
          <Button
            variant="contained"
            startIcon={<Iconify icon="eva:plus-fill" />}
            component={RouterLink}
            to={''}
            onClick={handleOpenModal1}
            style={{ marginRight: '8px' }} // Add margin to the right for spacing
          >
            Import Pdf
          </Button>
{/*           
          <Button
            variant="contained"
            startIcon={<Iconify icon="eva:plus-fill" />}
            component={RouterLink}
            to={PATH_DASHBOARD.element.addelement}
          >
            Vehicle
          </Button> */}
        </>
      }
    />
    <MaterialReactTable
    state={{loader ,showSkeletons:loader}}
      columns={columns}
      data={Data}
      enableRowActions={false}
    />
  </Container>
<Dia handleCloseModal1={handleCloseModal1} isModalOpen1={isModalOpen1} GetAllAdmin={GetAllAdmin}/>

</Page>

  );
}
