
/* eslint-disable */
import * as React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import MaterialReactTable from "material-react-table";
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import EditIcon from "@mui/icons-material/Edit";
import { Delete as DeleteIcon } from "@mui/icons-material";
import { useNavigate, Link as RouterLink } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { Box, IconButton, Container,Button } from '@mui/material';
import Page from '../../../components/Page';
import { PATH_DASHBOARD } from '../../../routes/paths';
// import HeaderBreadcrumbs from 'src/components/HeaderBreadcrumbs';
import HeaderBreadcrumbs from '../../../components/HeaderBreadcrumbs';
// import Button from 'src/theme/overrides/Button';
// import Button from '../../../theme/overrides/Button';
import Iconify from '../../../components/Iconify';
import axiosInstance from '../../../utils/axios';

export default function FilterTableEmployee({dataone}) {
  console.log(dataone[0]?.vehicle_demages,'vehci');

 

  const columns = React.useMemo(
    () => [
      {
        accessorKey: "id",
        header: "ID",
        size: 20,
      },
      {
        accessorKey: "week",
        header: "Week",
        size: 20,
      },
      {
        accessorKey: "overall_tier",
        header: "Scorecard Rating",
        size: 20,
      },
      
      {
        accessorKey: "Vehicle_Demage",
        header: "Vehicle Demage",
         size: 20,
        Cell: ({ row }) => {
          
          const filter = dataone[0]?.vehicle_demages.filter(e=>(e?.year === dataone[0]?.score_cards[0]?.year && e?.week === dataone[0]?.score_cards[0]?.week ))
          // console.log(filter,'hellowrodl');
            return (
              <Box>
                {filter?.length}
              </Box>
            );
          }
        },
    
      {
        accessorKey: "Counseling",
        header: "Counseling",
        size: 20,
        Cell: ({ row }) => {
          
          const filter = dataone[0]?.employee_counselings.filter(e=>(e?.year === dataone[0]?.score_cards[0]?.year && e?.week === dataone[0]?.score_cards[0]?.week ))
          // console.log(filter,'hellowrodl');
            return (
              <Box>
                {filter?.length}
              </Box>
            );
          }
      },
      {
        accessorKey: "Call Outs",
        header: "Call Outs",
        size: 20,
        Cell: ({ row }) => {
          const filter = dataone[0]?.attendance.filter(e=>(e?.year === dataone[0]?.score_cards[0]?.year && e?.week === dataone[0]?.score_cards[0]?.week && e?.Issue_type === 'Call Outs' ))
          // console.log(filter,'hellowrodl');
            return (
              <Box>
                {filter?.length}
              </Box>
            );
          }
      },
      {
        accessorKey: "No Show",
        header: "No Show",
        size: 20,
        Cell: ({ row }) => {
          
          const filter = dataone[0]?.attendance.filter(e=>(e?.year === dataone[0]?.score_cards[0]?.year && e?.week === dataone[0]?.score_cards[0]?.week && e?.Issue_type === 'No Show' ))
          // console.log(filter,'hellowrodl');
            return (
              <Box>
                {filter?.length}
              </Box>
            );
          }
      },
      {
        accessorKey: "Late",
        header: "Late",
        size: 20,
        Cell: ({ row }) => {
          
          const filter = dataone[0]?.attendance.filter(e=>(e?.year === dataone[0]?.score_cards[0]?.year && e?.week === dataone[0]?.score_cards[0]?.week && e?.Issue_type === 'Late' ))
          // console.log(filter,'hellowrodl');
            return (
              <Box>
                {filter?.length}
              </Box>
            );
          }
      },
      {
        accessorKey: "Early Departure",
        header: "Early Departure",
        size: 20,
        Cell: ({ row }) => {
          
          const filter = dataone[0]?.attendance.filter(e=>(e?.year === dataone[0]?.score_cards[0]?.year && e?.week === dataone[0]?.score_cards[0]?.week && e?.Issue_type === 'Early Departure' ))
          // console.log(filter,'hellowrodl');
            return (
              <Box>
                {filter?.length}
              </Box>
            );
          }
      },
      {
        accessorKey: "Injury",
        header: "Injury",
        size: 20,
        Cell: ({ row }) => {
          const filter = dataone[0]?.incident_and_injuries.filter((e, i) => (
              e?.year === dataone[i]?.score_cards[i]?.year &&
              e?.week === dataone[i]?.score_cards[i]?.week &&
              e?.category === 'Injury'
          ));
          
          return (
              <Box>
                  {filter?.length}
              </Box>
          );
      }
      
      },
      {
        accessorKey: "Incident",
        header: "Incident",
        size: 20,
        Cell: ({ row }) => {
          
          const filter = dataone[0]?.incident_and_injuries.filter(e=>(e?.year === dataone[0]?.score_cards[0]?.year && e?.week === dataone[0]?.score_cards[0]?.week && e?.category === 'Incident' ))

            return (
              <Box>
                {filter?.length}
              </Box>
            );
          }
      },
    //   {
    //     accessorKey: "actions",
    //     header: "Actions",
    //     size: 10,
    //     Cell: ({ row }) => (
    //       <Box>
    //         <IconButton
    //           color="primary"
    //           sx={{
    //             border: "1px solid",
    //             borderColor: "primary.main",
    //             marginRight: "5px" // Add margin to create a gap
    //           }}
    //           onClick={() => { navigate(`/dashboard/maintenance/${row.original.id}`) }}
    //         >
    //           <EditIcon />
    //         </IconButton>
    //         <IconButton
    //           color="error"
    //           sx={{
    //             border: "1px solid",
    //             borderColor: "error.main",
    //           }}
    //           onClick={() => {    handleDelete(row.original.id);
    //           }}
    //         >
    //           <DeleteIcon />
    //         </IconButton>
    //       </Box>
    //     ),
    //   },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dataone]
  );

  const [tableData, setTableData] = React.useState([]);

  const data = [
    {
      id: 1,
      date: "2024-04-09",
      warranty: "Yes",
      body: "Sedan",
      engine: "V6",
      shop: "ABC Auto Repair",
      description: "Oil change and filter replacement",
      estCost: "$50",
    },
    {
      id: 2,
      date: "2024-04-10",
      warranty: "No",
      body: "Truck",
      engine: "V8",
      shop: "XYZ Auto Service",
      description: "Brake pad replacement",
      estCost: "$100",
    },
    // Add more data as needed
  ];

  React.useEffect(() => {
    setTableData(data);
  }, []);

  return (
    <Page title="Repair And Maintenance">
      <Container maxWidth='lg'>
      <HeaderBreadcrumbs
          heading="Employee OverAll Summaray"
          links={[
            { name: '', href: '' },]}
          action={
            <>
            </>
            // <Button
            //   variant="contained"
            //   startIcon={<Iconify icon="eva:plus-fill" />}
            //   component={RouterLink}
            //   to={'/dashboard/maintenance'}
            //   // onClick={() => { navigate(`/dashboard/maintenance`) }}


            // >
            //   Repair And Maintenance
            // </Button>
          }
        />
        <MaterialReactTable
          columns={columns}
          data={dataone[0]?.score_cards  || []}
          enableRowActions={false} // Disable default row actions
        />
      </Container>
    </Page>
  );
}
