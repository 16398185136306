/* eslint-disable */
import * as React from 'react';

import MaterialReactTable from 'material-react-table';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { Box, Container, Divider, MenuItem } from '@mui/material';
import { RHFSelect, FormProvider } from '../../../components/hook-form';
import Page from '../../../components/Page';

import HeaderBreadcrumbs from '../../../components/HeaderBreadcrumbs';
import { useEffect } from 'react';
import axiosInstance from '../../../utils/axios';
import { useMemo } from 'react';
import { useState } from 'react';

export default function AttendanceTracking() {
  // const [Years, setyear] = useState([]);
  const NewSubSchema = Yup.object().shape({});
  const defaultValues = useMemo(
    () => ({
      vehicle: '',
    }),
    []
  );

  const methods = useForm({
    resolver: yupResolver(NewSubSchema),
    defaultValues,
  });

  const {
    handleSubmit,
    control,
    formState: { isSubmitting, errors },
  } = methods;

//   const data = [];

//   for (var i = 1; i <= 60; i++) {
//     data.push({
//       name: `week ${i}`,
//     });
//   }

  const data = [
    {
        name:'30 days',
        value:30

  },
    {
        name:'45 days',
        value:45

  },
    {
        name:'90 days',
        value:90

  }
] 
  const [Data, setdata] = React.useState([]);
  const [selectedItem, setSelectedItem] = useState(30);
  const GetAllAdmin = () => {
    axiosInstance
      .get(`admin/attendances?no_of_days=${selectedItem}`)
      .then((response) => {
        if (response.data.attendance) {
          // console.log(response?.data);
          setdata(response?.data?.attendance);
        }
        console.log(response, 'resp');
      })
      .catch((error) => {
        console.error(error);
      });
  };
  React.useEffect(() => {
    GetAllAdmin();
  }, [selectedItem]);
  const columns = React.useMemo(
    () => [

      {
        accessorKey: 'issue_type',
        header: 'Attendance',
        size: 20,
        // Cell: ({ row }) => {
        //     return (
        //         <Box>
        //            {row.original?.attendance?.map((e) => e?.delivery_associate)}
        //         </Box>
        //     );
        // }
      },
      {
        accessorKey: 'count',
        header: 'Number',
        size: 20,
        // Cell: ({ row }) => {
        //     return (
        //         <Box>
        //            {row.original?.attendance?.map((e) => e?.length)}
        //         </Box>
        //     );
        // }
      },
      {
        accessorKey: 'delivery_associate',
        header: 'Repeat Offenders',
        size: 20,
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const [tableData, setTableData] = React.useState([]);

  const rows = [
    {
      id: 1,
      attendance: 'Snow',
      number: 123,
      repeat: 'Hello world',
    },
  ];
  const handleOptionChange = (event) => {
    console.log(event,'event');
    setSelectedItem(event.target.value);
  };
  React.useEffect(() => {
    setTableData(rows);
  }, []);
  const onSubmit = async (data) => {};
  return (
    <Page title="Dashboard">
      <Container maxWidth="lg">
        <HeaderBreadcrumbs heading="Attendance Tracking" links={[{ name: '', href: '' }]} action={<></>} />
        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
          <div style={{ display: 'flex', width: '50%' }}>
            <div style={{ flex: '20%', marginRight: '2%' }}>
              <RHFSelect
                name={`week1`}
                label="Select Days"
                size="large"
                InputLabelProps={{ shrink: true }}
                SelectProps={{ native: false, sx: { textTransform: 'capitalize' } }}
                onChange={handleOptionChange}
              >
                {data?.map((e, i) => (
                  <MenuItem
                    key={e?.id}
                    value={e?.value}
                    sx={{
                      mx: 1,
                      my: 0.5,
                      borderRadius: 0.75,
                      typography: 'body2',
                      textTransform: 'capitalize',
                    }}
                  >
                    {e?.name}
                  </MenuItem>
                ))}
              </RHFSelect>
            </div>
            {/* <div style={{ flex: '20%' }}>
              <RHFSelect
                name={`year`}
                label="Year"
                size="large"
                InputLabelProps={{ shrink: true }}
                SelectProps={{ native: false, sx: { textTransform: 'capitalize' } }}
              >
                {year?.map((e, i) => (
                  <MenuItem
                    key={e?.name}
                    value={e?.name}
                    sx={{
                      mx: 1,
                      my: 0.5,
                      borderRadius: 0.75,
                      typography: 'body2',
                      textTransform: 'capitalize',
                    }}
                  >
                    {e?.name}
                  </MenuItem>
                ))}
              </RHFSelect>
            </div> */}
          </div>
        </FormProvider>
        <MaterialReactTable columns={columns} checkboxSelection data={Data || []} />
      </Container>
    </Page>
  );
}
