/* eslint-disable */
import React, { useMemo ,useEffect ,useState} from 'react';
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { LoadingButton } from '@mui/lab';
import { Card, Grid, Stack, Container, MenuItem } from '@mui/material';
import { FormProvider, RHFTextField,RHFSelect } from '../../components/hook-form';
import axiosInstance from '../../utils/axios';
import HeaderBreadcrumbs from '../../components/HeaderBreadcrumbs';

export default function EditzEslaction() {
    const navigate = useNavigate();
    const [Data, setdata] = useState([]);
    const { enqueueSnackbar } = useSnackbar();
    const data = [];
    const year = [];
    for (var i = 1; i <= 60; i++) {
      data.push({
        id: i,
        name: `week ${i}`,
      });
    }
    for (var i = Data?.startYear; i <= Data?.currentYear; i++) {
      year.push({
        // id: i,
        name: `${i}`,
      });
    }
    const NewSubSchema = Yup.object().shape({
        date_of_occurrence: Yup.string().required('Date is required'),
        tier: Yup.number().required('Type is required'),
        week: Yup.string().required('Week is required'),
        year: Yup.string().required('year is required'),

        description: Yup.string().required('Category is required'),

    });

    const defaultValues = useMemo(
        () => ({
            date_of_occurrence: '',
            tier: '',
            week:'',
            year:'',
            description: '',

        }),
        []
    );

    const methods = useForm({
        resolver: yupResolver(NewSubSchema),
        defaultValues,
    });

    const {
        handleSubmit,
        formState: { isSubmitting, errors },
    } = methods;

    const idone = localStorage.getItem('e_id');
    const onSubmit = async (data) => {
        console.log(data);
        try {
            const dorm = new FormData();
            dorm.append('date_of_occurrence', data?.date_of_occurrence);
            dorm.append('employee_id', idone);
            dorm.append('week', data?.week);
            dorm.append('year', data?.year);
            dorm.append('tier', data?.tier);
            dorm.append('description', data?.description);

            //  console.log(user?.id);

            await axiosInstance.post(`/admin/customerescalationdefects`, dorm).then((response) => {
                console.log(response?.data, '=========');
                if (response.data?.customerEscalationDefect) {
                    enqueueSnackbar('Add SuccessFully');
                    // reset();
                    navigate('/dashboard/consuling');
                }
            });
        } catch (error) {
            // enqueueSnackbar(error?.message, {
            // variant: 'error',
            // });
            console.error(error);
        }
    };
    const GetYear = () => {
        axiosInstance
          .get('/admin/years')
          .then((response) => {
            // console.log(response.data)
            if (response?.data) {
              // console.log(response?.data);
              setdata(response?.data);
            }
          })
          .catch((error) => {
            console.error(error);
          });
      };
      useEffect(() => {
        GetYear();
      }, []);
    
    return (
        <Container maxWidth="sm">
            <HeaderBreadcrumbs heading="Add Escalation" links={[{ name: '', href: '' }]} />
            <Card>
                <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
                    <Grid container spacing={1}>
                        <Grid item xs={12} md={12}>
                            <Card sx={{ p: 3 }}>
                                <Stack spacing={3}>
                                <RHFSelect
                    name="week"
                    label="Select Week"
                    size="large"
                    InputLabelProps={{ shrink: true }}
                    SelectProps={{ native: false, sx: { textTransform: 'capitalize' } }}
                  >
                    {data?.map((e) => (
                      <MenuItem
                        value={e?.name}
                        sx={{
                          mx: 1,
                          my: 0.5,
                          borderRadius: 0.75,
                          typography: 'body2',
                          textTransform: 'capitalize',
                        }}
                      // onClick={()=>setemID(e?.id)}
                      >
                        {e?.name}
                        {/* hi */}
                      </MenuItem>
                    ))}
                  </RHFSelect>
        
                  <RHFSelect
                    name="year"
                    label="Select year"
                    size="large"
                    InputLabelProps={{ shrink: true }}
                    SelectProps={{ native: false, sx: { textTransform: 'capitalize' } }}
                  >
                    {year?.map((e) => (
                      <MenuItem
                        value={e?.name}
                        sx={{
                          mx: 1,
                          my: 0.5,
                          borderRadius: 0.75,
                          typography: 'body2',
                          textTransform: 'capitalize',
                        }}
                      // onClick={()=>setemID(e?.id)}
                      >
                        {e?.name}
                        {/* hi */}
                      </MenuItem>
                    ))}
                  </RHFSelect>
                                    <RHFTextField name="date_of_occurrence" label="Date" type="date" InputLabelProps={{ shrink: true }} />
                                    <RHFTextField name="tier" label="tier" InputLabelProps={{ shrink: true }} />
                                    <RHFTextField name="description" label="Description" InputLabelProps={{ shrink: true }} />
                                   
                               
                                    <Grid item xs={6} md={6}>
                                        <LoadingButton type="submit" variant="contained" size="large" loading={isSubmitting}>
                                            Add Escalation
                                        </LoadingButton>
                                    </Grid>
                                </Stack>
                            </Card>
                        </Grid>
                    </Grid>
                </FormProvider>
            </Card>
        </Container>
    );
}
