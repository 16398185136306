import { Icon } from '@iconify/react';
import Person3Icon from '@mui/icons-material/Person3';
import DriveEtaIcon from '@mui/icons-material/DriveEta';
// import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
// import DevicesIcon from '@mui/icons-material/Devices';
import GasMeterIcon from '@mui/icons-material/GasMeter';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
// import { PATH_DASHBOARD } from '../../../routes/paths';

const navConfig = [
  {
    items: [
      { title: 'Daily Dashboard', path: '/dashboard/home', icon: <Icon icon="ri:dashboard-line" />,  children: [
        { title: 'Dashboard', path: '/dashboard/home' },
        { title: 'Perfomance', path: '/dashboard/perfomance' },
        { title: 'Fleet', path: '/dashboard/fleet' },
        // { title: 'Score Card', path: '/dashboard/score_card' },
      ], },
      { title: 'Sub-Admin', path: '/dashboard/sub-admin', icon: <Person3Icon /> },
      // { title: 'Customer FeedBack', path: '/dashboard/pdf', icon: <PictureAsPdfIcon /> },
      {
        title: 'Weekly Data Import',
        path: '/dashboard/pdf',
        icon: <PictureAsPdfIcon />,
        children: [
          { title: 'Customer FeedBack', path: '/dashboard/pdf' },
          { title: 'Pod Report', path: '/dashboard/pod_report' },
          { title: 'Score Card', path: '/dashboard/score_card' },
        ],
      },
      // { title: 'Video', path: PATH_DASHBOARD.element.element, icon: <Icon icon="octicon:organization-16" /> },
      {
        title: 'Daily Data',
        path: '/dashboard/safety',
        icon: <GasMeterIcon />,
        children: [
          { title: 'Safety', path: '/dashboard/safety' },
          { title: 'Quality', path: '/dashboard/quality' },
          { title: 'Contact', path: '/dashboard/slot' },
          { title: 'EOC', path: '/dashboard/appointment' },
        ],
      },
      // { title: 'Safety', path: '/dashboard/safety', icon: <Icon icon="ri:dashboard-line" /> },
      // { title: 'Quality', path: '/dashboard/quality', icon: <Icon icon="octicon:organization-16" /> },
      // { title: 'Contact', path: '/dashboard/slot', icon: <Icon icon="ri:dashboard-line" /> },
      // { title: 'EOC', path: '/dashboard/appointment', icon: <Icon icon="octicon:organization-16" /> },
      {
        title: 'Static Data',
        path: '/dashboard/element',
        icon: <DriveEtaIcon />,
        children: [
          { title: 'Vehicle', path: '/dashboard/element' },
          { title: 'Employee', path: '/dashboard/employee' },
          { title: 'Device', path: '/dashboard/device' },
          { title: 'Gas Card', path: '/dashboard/gascard' },
        ],
      },
      { title: 'Filter Vehicle And Employee', path: '/dashboard/filter', icon: <FilterAltIcon /> },
      // { title: 'Vechile', path: '/dashboard/element', icon: <DriveEtaIcon /> },
      // { title: 'Employee', path: '/dashboard/employee', icon: <PeopleAltIcon /> },
      // { title: 'Device', path: '/dashboard/device', icon: <DevicesIcon /> },
      // { title: 'Gas Card', path: '/dashboard/gascard', icon: <GasMeterIcon /> },
      
    ],
  },
];

export default navConfig;
