/* eslint-disable */
import React, { useMemo, useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router';
import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { styled } from '@mui/material/styles';
import makeAnimates from 'react-select/animated';
import Select from 'react-select';
import { LoadingButton } from '@mui/lab';
import { Card, Grid, Stack, Container, MenuItem, Button, Typography, Box, TextField } from '@mui/material';
import { FormProvider, RHFTextField, RHFSelect } from '../../../../components/hook-form';
import axiosInstance from '../../../../utils/axios';
import HeaderBreadcrumbs from '../../../../components/HeaderBreadcrumbs';

const LabelStyle = styled(Typography)(({ theme }) => ({
  ...theme.typography.subtitle2,
  color: theme.palette.text.secondary,
  marginBottom: theme.spacing(1),
}));
export default function AddStandy() {
  const navigate = useNavigate();
  const [Data, setdata] = useState([]);
  const [emplyoee, setemployee] = useState([]);
  const [Device, setDevice] = useState([]);
  const [vehcile, setvehicle] = useState([]);
  const animatedComponents = makeAnimates();
  const { enqueueSnackbar } = useSnackbar();

  const NewSubSchema = Yup.object().shape({
    delivery_associate: Yup.array().min(1, 'delivery_associate is required').required('delivery_associate is required'),
    date: Yup.string().required('date is required'),
  });

  const defaultValues = useMemo(
    () => ({
      delivery_associate: '',
      date: '',
    }),
    []
  );

  const methods = useForm({
    resolver: yupResolver(NewSubSchema),
    defaultValues,
  });

  const {
    handleSubmit,
    control,
    formState: { isSubmitting, errors },
  } = methods;
  console.log(errors, 'errors');

  // const idone = localStorage.getItem('e_id');
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'items',
  });

  const onSubmit = async (data) => {
    const PushData = [];
    PushData?.push(data?.delivery_associate?.value);
    console.log(
      data?.delivery_associate.map((e) => e.value),
      'delivery_associate'
    );
    console.log(PushData, 'delivery_associate');
    try {
      const dorm = new FormData();
      data?.delivery_associate?.forEach((e) => {
        dorm.append('employee_id[0]', e?.value);
      });

      dorm.append('date[0]', data?.date);
      await axiosInstance.post(`/admin/standbyassociates`, dorm).then((response) => {
        if (response.data?.message) {
          enqueueSnackbar(response.data?.message);
          navigate('/dashboard/home');
        }
      });
    } catch (error) {
      enqueueSnackbar(error?.message, {
        variant: 'error',
      });
    }
  };

  const GetEmployee = () => {
    axiosInstance
      .get(`/admin/employees`)
      .then((response) => {
        if (response?.data) {
          // console.log(response?.data);
          setemployee(response?.data?.employee);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const Vehicle = () => {
    axiosInstance
      .get(`/admin/vehicledetails`)
      .then((response) => {
        // console.log(response.data?.employee?.name)
        if (response?.data) {
          // console.log(response?.data);
          setvehicle(response?.data?.vehicleDetail);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };
  const GetDevice = () => {
    axiosInstance
      .get(`/admin/devices`)
      .then((response) => {
        if (response?.data) {
          setDevice(response?.data?.device);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };
  useEffect(() => {
    GetEmployee();
    GetDevice();
    Vehicle();
  }, []);

  const arr = [
    {
      name: 'OnTime',
    },
    {
      name: 'Late',
    },
  ];
  const options = emplyoee?.map((e) => {
    return { value: e?.id, label: e?.name };
  });
  const selectStyles = {
    control: (provided) => ({
      ...provided,

      marginTop: '10px',
      '@media (max-width: 600px)': {
        width: '320px',
      },
    }),
    menu: (provided) => ({
      ...provided,
      zIndex: 9999,
      marginTop: '0px',

      '@media (max-width: 600px)': {
        width: '320px',
      },
    }),
  };

  return (
    <Container maxWidth="sm">
      <HeaderBreadcrumbs heading="Add StandBy " links={[{ name: '', href: '' }]} />
      <Card>
        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={1}>
            <Grid item xs={12} md={12}>
              <Card sx={{ p: 3, height: '300px' }}>
                <Stack spacing={1.5}>
                  <Box sx={{ marginBottom: 2 }}>
                    <Controller
                      name="delivery_associate"
                      control={control}
                      render={({ field }) => (
                        <Select
                          {...field}
                          components={animatedComponents}
                          isMulti
                          placeholder="delivery_associate"
                          options={options}
                          onChange={(e) => {
                            field.onChange(e);
                          }}
                        />
                      )}
                    />
                    {errors.delivery_associate && (
                      <LabelStyle style={{ fontSize: '12px', color: 'red', fontWeight: 300, marginTop: '5px' }}>
                        {errors.delivery_associate.message}
                      </LabelStyle>
                    )}
                  </Box>

                  <Box sx={{ marginBottom: 2 }}>
                    <RHFTextField name="date" label="Date" type="date" InputLabelProps={{ shrink: true }} />
                  </Box>

                  <Grid item xs={12} md={12}>
                    <LoadingButton type="submit" variant="contained" size="large" loading={isSubmitting}>
                      Add Stand By
                    </LoadingButton>
                  </Grid>
                </Stack>
              </Card>
            </Grid>
          </Grid>
        </FormProvider>
      </Card>
    </Container>
  );
}
