
/* eslint-disable */
import React, { useMemo, useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';
import { useLocation, useNavigate, useParams } from 'react-router';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { DatePicker, LoadingButton } from '@mui/lab';
import { Card, Grid, Stack, Container, MenuItem, Button, Divider, Box, TextField } from '@mui/material';
import { FormProvider, RHFTextField, RHFSelect } from '../../../../components/hook-form';
import axiosInstance from '../../../../utils/axios';
import HeaderBreadcrumbs from '../../../../components/HeaderBreadcrumbs';
import Iconify from '../../../../components/Iconify';

export default function EditRoster() {
  const { state } = useLocation()
  const { id } = useParams()
  const navigate = useNavigate();
  const [Data, setdata] = useState([]);
  const [Device, setDevice] = useState([]);
  const [emplyoee, setemployee] = useState([])
  const [vehcile, setvehicle] = useState([])
  const { enqueueSnackbar } = useSnackbar();

  // console.log(Data, 'state');
  const NewSubSchema = Yup.object().shape({
    roster: Yup.array().of(Yup.object().shape({
      // device_id1: Yup.string().required('device_id1 is required'),
      delivery_associate: Yup.string().required('delivery_associate is required'),
      // device_id2: Yup.string().required('device_id2 is required'),
      // vehicle: Yup.string().required('vehicle is required'),
      // status: Yup.string().required('status is required'),
      // route: Yup.string().required('route is required'),
      date: Yup.string().required('date is required'),
    })),
    // device_id1: Yup.string().required('device_id1 is required'),
    // delivery_associate: Yup.number().required('delivery_associate is required'),
    // device_id2: Yup.string().required('device_id2 is required'),
    // vehicle: Yup.string().required('vehicle is required'),
    // status: Yup.string().required('status is required'),
    // route: Yup.string().required('route is required'),
    // date: Yup.string().required('date is required'),
    // message: Yup.string().required('Message is required'),

  });


  const defaultValues = useMemo(
    () => ({

      roster: Data?.map((e) => ({
        // status: e?.status || '',
        delivery_associate: e?.employee?.id || "",
        // vehicle: e?.vehicle_detail?.id || "",
        // device_id1: e?.device1?.id || "",
        // device_id2: e?.device2?.id || "",
        // route: e?.route || "",
        date: e?.date || "",
        // message: e?.message || "",
      }))


    }),

    [Data]

  );

  const methods = useForm({
    resolver: yupResolver(NewSubSchema),
    defaultValues,
  });

  const {
    handleSubmit,
    watch,
    reset,
    setValue,
    formState: { isSubmitting, errors },
  } = methods;


  const value = watch()

  console.log(value, "value"
  )
  console.log(errors, "errors"
  )


  useEffect(() => {
    if (Data) {
      reset(defaultValues);
    }
  }, [Data, reset, defaultValues]);
  // const idone = localStorage.getItem('e_id');
  const handleAdd = () => {
    console.log(value, 'values')
    if (value.roster.length < 4) {
      setValue('roster', [...value.roster, '']);
    }
  };

  const handleRemove = (index) => {
    // eslint-disable-next-line prefer-const
    let la = value.roster;
    la.splice(index, 1);
    setValue('roster', la);
  };

  const onSubmit = async (data) => {
    // console.log(data);
    try {

      const dorm = new FormData();
        data.roster.forEach((item, index) => {
          // dorm.append(`device_id1[${index}]`, item?.device_id1);
          dorm.append(`employee_id[${index}]`, item.delivery_associate);
          // dorm.append(`vehicle_detail_id[${index}]`, item?.vehicle);
          // dorm.append(`device_id2[${index}]`, item?.device_id2);
          // dorm.append(`route[${index}]`, item?.route);
          // dorm.append(`status[${index}]`, item?.status);
          dorm.append(`date[${index}]`, item?.date);
          // dorm.append(`message[${index}]`, item?.message);
          });
        dorm.append(`_method`, 'PUT');
        dorm.append(`rostered_associate_id[0]`, id);



      //  console.log(user?.id);

      await axiosInstance.post(`/admin/rosteredassociates/${id}`, dorm).then((response) => {
        // console.log(response?.data, '=========');
        if (response.data?.message) {
          enqueueSnackbar(response.data?.message);
          // reset();
          navigate('/dashboard/home');
        }
      });
    } catch (error) {
      // enqueueSnackbar(error?.message, {
      // variant: 'error',
      // });
      console.error(error);
    }
  };


  const GetEmployee = () => {
    axiosInstance
      .get(`/admin/employees`)
      .then((response) => {
        // console.log(response.data?.employee?.name)
        if (response?.data) {
          // console.log(response?.data);
          setemployee(response?.data?.employee);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };
  const GetDevice = () => {
    axiosInstance
      .get(`/admin/devices`)
      .then((response) => {
        // console.log(response.data?.employee?.name)
        if (response?.data) {
          // console.log(response?.data);
          setDevice(response?.data?.device);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const Vehicle = () => {
    axiosInstance
      .get(`/admin/vehicledetails`)
      .then((response) => {
        // console.log(response.data?.employee?.name)
        if (response?.data) {
          // console.log(response?.data);
          setvehicle(response?.data?.vehicleDetail);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };
  const HandleDate = (e) => {
    axiosInstance
      .get(`/admin/rosteredassociates?date=${e}`)
      .then((response) => {
        // console.log(response.data?.employee?.name)
        if (response?.data?.rosteredAssociate) {
          // console.log(response?.data);
          setdata(response?.data?.rosteredAssociate);
          console.log(response?.data?.rosteredAssociate, 'response?.data?.rosteredAssociate');
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }
  useEffect(() => {
    GetEmployee()
    Vehicle()
    GetDevice()
  }, []);

  const arr = [
    {
      name: 'OnTime',
    },
    {
      name: 'Late',
    }

  ]
  return (
    <Container maxWidth="sm">
      <HeaderBreadcrumbs heading="Edit Rostered " links={[{ name: '', href: '' }]} />
      <Card>
        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={1}>
            <Grid item xs={12} md={12}>
              <Card sx={{ p: 3 }}>

      
                <Stack spacing={1.5}>
                <input type='date' style={{  borderRadius: "10px",padding:"15px 20px"}}  onChange={(e) => HandleDate(e.target.value)} />

                  <Divider sx={{ my: 3, borderStyle: 'dashed' }} />
                  <Stack divider={<Divider flexItem sx={{ borderStyle: 'dashed' }} />} spacing={3}>
                    {value?.roster?.map((item, index) => (
                      <Stack spacing={1.5} key={item.id}>
                        <Box
                          sx={{
                            display: 'grid',
                            columnGap: 2,
                            rowGap: 3,
                            gridTemplateColumns: { xs: 'repeat(1, 1fr)', sm: 'repeat(2, 1fr)' },
                          }}
                        >
                          {/* <RHFSelect
                            name={`roster[${index}].vehicle`}
                            // name="vehicle"
                            label="vehicle"
                            size="large"
                            InputLabelProps={{ shrink: true }}
                            SelectProps={{ native: false, sx: { textTransform: 'capitalize' } }}
                          >
                            {vehcile.map((e, i) => {
                              return (

                                <MenuItem
                                  value={e?.id}
                                  sx={{
                                    mx: 1,
                                    my: 0.5,
                                    borderRadius: 0.75,
                                    typography: 'body2',
                                    textTransform: 'capitalize',
                                  }}

                                >
                                  {e?.name}

                                </MenuItem>
                              )
                            })
                            }
                          </RHFSelect> */}
                          <RHFSelect
                            name={`roster[${index}].delivery_associate`}
                            label="Delivery Associate"
                            size="large"
                            InputLabelProps={{ shrink: true }}
                            SelectProps={{ native: false, sx: { textTransform: 'capitalize' } }}
                          >

                            {emplyoee.map((e, i) => {
                              return (
                                <MenuItem
                                  value={e?.id}
                                  sx={{
                                    mx: 1,
                                    my: 0.5,
                                    borderRadius: 0.75,
                                    typography: 'body2',
                                    textTransform: 'capitalize',
                                  }}

                                >
                                  {e?.name}

                                </MenuItem>
                              )
                            })
                            }
                          </RHFSelect>
                          {/* <RHFTextField name={`roster[${index}].device_id1`} label="Device#1" InputLabelProps={{ shrink: true }} /> */}
                          {/* <RHFSelect
                            name={`roster[${index}].device_id1`}
                            label="Device#1"
                            size="large"
                            InputLabelProps={{ shrink: true }}
                            SelectProps={{ native: false, sx: { textTransform: 'capitalize' } }}
                          >

                            {Device.map((e, i) => {
                              return (
                                <MenuItem
                                  value={e?.id}
                                  sx={{
                                    mx: 1,
                                    my: 0.5,
                                    borderRadius: 0.75,
                                    typography: 'body2',
                                    textTransform: 'capitalize',
                                  }}

                                >
                                  {e?.device_id}

                                </MenuItem>
                              )
                            })
                            }
                          </RHFSelect> */}
                          {/* <RHFSelect
                            name={`roster[${index}].device_id2`}
                            label="Device#2"
                            size="large"
                            InputLabelProps={{ shrink: true }}
                            SelectProps={{ native: false, sx: { textTransform: 'capitalize' } }}
                          >

                            {Device.map((e, i) => {
                              return (
                                <MenuItem
                                  value={e?.id}
                                  sx={{
                                    mx: 1,
                                    my: 0.5,
                                    borderRadius: 0.75,
                                    typography: 'body2',
                                    textTransform: 'capitalize',
                                  }}

                                >
                                  {e?.device_id}

                                </MenuItem>
                              )
                            })
                            }
                          </RHFSelect> */}
                          {/* <RHFTextField name={`roster[${index}].device_id2`} label="Device#2"  InputLabelProps={{ shrink: true }} /> */}
                          {/* <RHFTextField name={`roster[${index}].route`} label="Route" InputLabelProps={{ shrink: true }} /> */}
                          <RHFTextField name={`roster[${index}].date`} label="Date" type='date' InputLabelProps={{ shrink: true }} />
                          {/* <RHFTextField name={`roster[${index}].message`} label="Message" InputLabelProps={{ shrink: true }} /> */}


                          {/* <RHFSelect
                            name={`roster[${index}].status`}
                            label="Status"
                            size="large"
                            InputLabelProps={{ shrink: true }}
                            SelectProps={{ native: false, sx: { textTransform: 'capitalize' } }}
                          >
                            {arr?.map((e) => (
                              <MenuItem
                                value={e?.name}
                                sx={{
                                  mx: 1,
                                  my: 0.5,
                                  borderRadius: 0.75,
                                  typography: 'body2',
                                  textTransform: 'capitalize',
                                }}

                              >
                                {e?.name}

                              </MenuItem>
                            ))}
                          </RHFSelect> */}


                        </Box>
                        <Button
                          size="small"
                          color="error"
                          startIcon={<Iconify icon="eva:trash-2-outline" />}
                          onClick={() => handleRemove(index)}
                        >
                          Remove
                        </Button>
                      </Stack>
                    ))}
                  </Stack>
                  <Divider sx={{ my: 3, borderStyle: 'dashed' }} />

                  <Button
                    size="small"
                    startIcon={<Iconify icon="eva:plus-fill" />}
                    onClick={handleAdd}
                    sx={{ flexShrink: 0 }}
                  >
                    Add New Rostered
                  </Button>
                  <Grid item xs={6} md={6}>
                    <LoadingButton type="submit" variant="contained" size="large" loading={isSubmitting}>
                      Edit Rostered
                    </LoadingButton>
                  </Grid>
                </Stack>
              </Card>
            </Grid>
          </Grid>
        </FormProvider>
      </Card>
    </Container>
  );
}
