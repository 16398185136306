
/* eslint-disable */
import React, { useMemo, useEffect, useState } from 'react';
import MaterialReactTable from 'material-react-table';
import TextsmsIcon from '@mui/icons-material/Textsms';
import EditIcon from '@mui/icons-material/Edit';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import SettingsIcon from '@mui/icons-material/Settings';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import * as XLSX from 'xlsx';
import { Delete as DeleteIcon, RemoveRedEye } from '@mui/icons-material';
import { useNavigate, Link as RouterLink } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import {
  Box,
  IconButton,
  Button,
  Modal,
  Typography,
  TextField,
  Tooltip,
  CircularProgress,
  MenuItem,
} from '@mui/material';
import Page from '../../../../components/Page';
import HeaderBreadcrumbs from '../../../../components/HeaderBreadcrumbs';
import Iconify from '../../../../components/Iconify';
import axiosInstance from '../../../../utils/axios';
import { RHFSelect, FormProvider, RHFTextField } from '../../../../components/hook-form';
import Dia from './Dia';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  height: '400px',
  overflowY: 'scroll',
  backgroundColor: 'background.paper',
  borderRadius: '10px',
  boxShadow: 24,
  padding: 4,
};
export default function Standy({ date }) {
  const handleCloseModal1 = () => {
    setIsModalOpen1(false);
  };
  const [isModalOpen1, setIsModalOpen1] = React.useState(false);
  const [propsdata, setpropsdata] = React.useState(false);

  const handleOpenModalone = (row) => {
    setIsModalOpen1(true);
    setpropsdata(row);
  };
  const [vehcile, setvehicle] = useState([]);
  const GetRostered = () => {
    axiosInstance
      .get(`admin/standbyassociates?date=${date}`)
      .then((response) => {
        if (response.data.standByAssociate) {
          // console.log(response?.data);
          setdata(response?.data?.standByAssociate);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };
  useEffect(() => {
    GetEmployee();
    Vehicle();
    GetDevice();
    GetRostered();
    
  }, []);
  const NewSubSchema = Yup.object().shape({
    item: Yup.array().of(
      Yup.object().shape({
        device_id1: Yup.string().required('device_id1 is required'),
        device_id2: Yup.string().required('device_id2 is required'),
        vehicle: Yup.string().required('vehicle is required'),
        status: Yup.string().required('status is required'),
        route: Yup.string().required('route is required'),
      })
    ),
  });
  const [Data, setdata] = React.useState([]);
  console.log(Data, 'Data');
  const defaultValues = useMemo(
    () => ({
      item: Data?.map((e) => ({
        status: e?.status || '',
        vehicle: e?.vehicle_detail?.id || '',
        device_id1: e?.device1?.id || '',
        device_id2: e?.device2?.id || '',
        route: e?.route || '',
      })),
    }),
    [Data]
  );
  //   console.log(defaultValues,'defaultValues');
  const { enqueueSnackbar } = useSnackbar();
  const [emplyoee, setemployee] = useState([]);
  const [selectedRowIds, setSelectedRowIds] = React.useState([]);
  console.log(selectedRowIds?.length != 0, 'selectedRowIds');
  const [Device, setDevice] = useState([]);
  const [open, setOpen] = React.useState(false);
  const [opens, setOpens] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const handleCloses = () => setOpens(false);
  const [datas, setdatas] = useState(false);
  const [i_d, setid] = React.useState(null);

  const methods = useForm({
    resolver: yupResolver(NewSubSchema),
    defaultValues,
  });

  const {
    handleSubmit,
    control,
    formState: { isSubmitting, errors },
    reset,
  } = methods;

  const handleOpenModal1 = (id) => {
    setid(id);

    handleOpen();
  };
  const handleOpenModal2 = (params) => {
    setOpens(true);
    axiosInstance
      .get(`admin/standbyassociates/${params}`)
      .then((response) => {
        if (response.data.standByAssociate) {
          // console.log(response?.data);
          setdatas(response?.data?.standByAssociate);
        }
        console.log(response?.data.standByAssociate, 'resp');
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const handleCloseModal = () => {
    handleClose();
  };
  const [mess, setmess] = React.useState();
  const [loader, setloader] = useState(false);
  const handleSendMessage = async () => {
    // console.log('Selected IDs:', selectedRowIds);
    // selectedRowIds?.map((id) => {
    //     console.log('Selected IDs', id);
    // });
    try {
      setloader(true);
      const dorm = new FormData();
      dorm.append('message', mess);
      if (selectedRowIds.length == 0 && i_d == null) {
        enqueueSnackbar('Please select rows to send message', {
          variant: 'error',
        });
        setloader(false);
        return;
      } else if (selectedRowIds.length == 0) {
        dorm.append(`stand_by_associate_id[0]`, i_d);
      } else {
        selectedRowIds.map((e, i) => dorm.append(`stand_by_associate_id[${i}]`, e));
      }
      await axiosInstance.post(`/admin/standbyassociateMessage`, dorm).then((response) => {
        // console.log(response?.data, '=========');
        if (response.data?.message ) {
          enqueueSnackbar(response?.data?.message);
          // reset();
          setloader(false);
          setOpen(false);
        }
      });
    } catch (error) {
      enqueueSnackbar(error?.message, {
        variant: 'error',
      });
      setloader(false);
      console.error(error);
    }
  };

  const handleDownload = () => {
    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.json_to_sheet(Data);
    console.log(ws);
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    XLSX.writeFile(wb, 'Rostered_Associates.xlsx');
  };

  let array = [];

  const handleCheckboxChange = (e) => {
    const rowId = e.target.value;
    const isChecked = e.target.checked;
    if (isChecked) {
      array.push(rowId);
      console.log(array , 'array');
    } else {
      setSelectedRowIds((prevIds) => prevIds.filter((id) => id !== rowId));
    }
  };


  const navigate = useNavigate();

  const idone = localStorage.getItem('e_id');

  async function handleDelete(rowdata) {
    console.log(rowdata);
    try {
      await axiosInstance.delete(`admin/standbyassociates/${rowdata}`).then((response) => {
        if (response?.data?.message === 'Deleted successfully') {
          enqueueSnackbar(response?.data?.message);
          
        }
      });
    } catch (error) {
      enqueueSnackbar(error?.message, {
        variant: 'error',
      });
      console.error(error);
    }
  }
  const GetEmployee = () => {
    axiosInstance
      .get(`/admin/employees`)
      .then((response) => {
        if (response?.data) {
          // console.log(response?.data);
          setemployee(response?.data?.employee);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };
  const GetDevice = () => {
    axiosInstance
      .get(`/admin/devices`)
      .then((response) => {
        // console.log(response.data?.employee?.name)
        if (response?.data) {
          // console.log(response?.data);
          setDevice(response?.data?.device);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };
  const Vehicle = () => {
    axiosInstance
      .get(`/admin/vehicledetails`)
      .then((response) => {
        // console.log(response.data?.employee?.name)
        if (response?.data) {
          // console.log(response?.data);
          setvehicle(response?.data?.vehicleDetail);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };
  const handleApi = async (e, id) => {
    // console.log(data);
    try {
      const dorm = new FormData();

      dorm.append(`vehicle_detail_id[0]`, e);

      dorm.append(`stand_by_associate_id[0]`, id);

      await axiosInstance.post(`/admin/standbyassociateMessage`, dorm).then((response) => {
        // console.log(response?.data, '=========');
        
        if (response.data?.message) {
          enqueueSnackbar(response.data?.message);

          // reset();
          //   navigate('/dashboard/home');
        }
      });
    } catch (error) {
      // enqueueSnackbar(error?.message, {
      // variant: 'error',
      // });
      console.error(error);
    }
  };
  const handleApic = async (e, id) => {
    // console.log(data);
    try {
      const dorm = new FormData();

      array.forEach((item, index) => {
        dorm.append(`vehicle_detail_id[${index}]`, e);
        dorm.append(`stand_by_associate_id[${index}]`, item);
      });
      // selectedRowIds.map((es, i) => dorm.append(`vehicle_detail_id[${i}]`, e))


      await axiosInstance.post(`/admin/standbyassociateMessage`, dorm).then((response) => {
        // console.log(response?.data, '=========');
        if (response.data?.message) {
          enqueueSnackbar(response.data?.message);
          // reset();
          // navigate('/dashboard/home');
          window.location.reload()
        }
      });
    } catch (error) {
      // enqueueSnackbar(error?.message, {
      // variant: 'error',
      // });
      console.error(error);
    }
  };
  const handleApid = async (e, id) => {
    // console.log(data);
    try {
      const dorm = new FormData();

      dorm.append(`device_id1[0]`, e);

      dorm.append(`stand_by_associate_id[0]`, id);

      await axiosInstance.post(`/admin/standbyassociateMessage`, dorm).then((response) => {
        // console.log(response?.data, '=========');
        if (response.data?.message) {
          enqueueSnackbar(response.data?.message);
          // reset();
          navigate('/dashboard/home');
        }
      });
    } catch (error) {
      // enqueueSnackbar(error?.message, {
      // variant: 'error',
      // });
      console.error(error);
    }
  };
  const handleApidc = async (e, id) => {
    // console.log(data);
    try {
      const dorm = new FormData();
      array.forEach((item, index) => {
        dorm.append(`device_id1[${index}]`, e);
        dorm.append(`stand_by_associate_id[${index}]`, item);
      });


      await axiosInstance.post(`/admin/standbyassociateMessage`, dorm).then((response) => {
        // console.log(response?.data, '=========');
        if (response.data?.message) {
          enqueueSnackbar(response.data?.message);
          // reset();
          // navigate('/dashboard/home');
          window?.location?.reload()
        }
      });
    } catch (error) {
      // enqueueSnackbar(error?.message, {
      // variant: 'error',
      // });
      console.error(error);
    }
  };
  const handleApidd = async (e, id) => {
    // console.log(data);
    try {
      const dorm = new FormData();

      dorm.append(`device_id2[0]`, e);

      dorm.append(`stand_by_associate_id[0]`, id);

      await axiosInstance.post(`/admin/standbyassociateMessage`, dorm).then((response) => {
        // console.log(response?.data, '=========');
        if (response.data?.message) {
          enqueueSnackbar(response.data?.message);
          // reset();
          navigate('/dashboard/home');
        }
      });
    } catch (error) {
      // enqueueSnackbar(error?.message, {
      // variant: 'error',
      // });
      console.error(error);
    }
  };
  const handleApiddc = async (e, id) => {
    // console.log(data);
    try {
      const dorm = new FormData();

      array.forEach((item, index) => {
        dorm.append(`device_id2[${index}]`, e);
        dorm.append(`stand_by_associate_id[${index}]`, item);
      });


      await axiosInstance.post(`/admin/standbyassociateMessage`, dorm).then((response) => {
        // console.log(response?.data, '=========');
        if (response.data?.message) {
          enqueueSnackbar(response.data?.message);
          // reset();
          
          window.location?.reload()
          //   navigate('/dashboard/home');
        }
      });
    } catch (error) {
      // enqueueSnackbar(error?.message, {
      // variant: 'error',
      // });
      console.error(error);
    }
  };
  const handleApis = async (e, id) => {
    // console.log(data);
    try {
      const dorm = new FormData();

      dorm.append(`status[0]`, e);

      dorm.append(`stand_by_associate_id[0]`, id);

      await axiosInstance.post(`/admin/standbyassociateMessage`, dorm).then((response) => {
        // console.log(response?.data, '=========');
        if (response.data?.message) {
          enqueueSnackbar(response.data?.message);
          // reset();
          navigate('/dashboard/home');
        }
      });
    } catch (error) {
      // enqueueSnackbar(error?.message, {
      // variant: 'error',
      // });
      console.error(error);
    }
  };
  const handleApisc = async (e, id) => {
    // console.log(data);
    try {
      const dorm = new FormData();
      array.forEach((item, index) => {
        dorm.append(`status[${index}]`, e);
        dorm.append(`stand_by_associate_id[${index}]`, item);
      });


      await axiosInstance.post(`/admin/standbyassociateMessage`, dorm).then((response) => {
        // console.log(response?.data, '=========');
        if (response.data?.message) {
          enqueueSnackbar(response.data?.message);
          // reset();
          // navigate('/dashboard/home');
          window.location?.reload()
        }
      });
    } catch (error) {
      // enqueueSnackbar(error?.message, {
      // variant: 'error',
      // });
      console.error(error);
    }
  };
  const handleApir = async (e, id) => {
    // console.log(data);
    try {
      const dorm = new FormData();

      dorm.append(`route[0]`, e);

      dorm.append(`stand_by_associate_id[0]`, id);

      await axiosInstance.post(`/admin/standbyassociateMessage`, dorm).then((response) => {
        // console.log(response?.data, '=========');
        if (response.data?.message) {
          enqueueSnackbar(response.data?.message);
          // reset();
          navigate('/dashboard/home');
        }
      });
    } catch (error) {
      // enqueueSnackbar(error?.message, {
      // variant: 'error',
      // });
      console.error(error);
    }
  };
  const handleApirc = async (e, id) => {
    // console.log(data);
    try {
      const dorm = new FormData();

      array.forEach((item, index) => {
        dorm.append(`route[${index}]`, e);
        dorm.append(`stand_by_associate_id[${index}]`, item);
      });


      await axiosInstance.post(`/admin/standbyassociateMessage`, dorm).then((response) => {
        if (response.data?.message) {
          enqueueSnackbar(response.data?.message);
          // reset();
          
          window.location.reload()

          // navigate('/dashboard/home');
        }
      });
    } catch (error) {
      // enqueueSnackbar(error?.message, {
      // variant: 'error',
      // });
      console.error(error);
    }
  };
  const arr = [
    {
      name: 'OnTime',
    },
    {
      name: 'Late',
    },
  ];
  const arr1 = [
    {
      name: 'Route1',
    },
    {
      name: 'Route2',
    },
  ];
  const onSubmit = async (data) => {};
  const columns = React.useMemo(
    () => [
      {
        accessorKey: 'Checkbox',
        header: '',
        size: 20,
        Cell: ({ row }) => (
          <input
            type="checkbox"
            value={row.original.id}
            onChange={handleCheckboxChange}
            style={{ backgroundColor: 'rgba(46, 50, 54, 1)' }}
          />
        ),
      },
      // {
      //   accessorKey: 'date',
      //   header: 'Date',
      //   size: 20,
      // },
      {
        accessorKey: 'empty',
        header: 'Delivery Associates',
        size: 20,
        Cell: ({ row }) => <Box>{row.original.employee?.name}</Box>,
      },
      {
        accessorKey: 'vehicle',
        header: 'Vehicle',
        size: 10,
        Cell: ({ row }) => (
          <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
            <RHFSelect
              label="vehicle"
              size="large"
              InputLabelProps={{ shrink: true }}
              SelectProps={{ native: false, sx: { textTransform: 'capitalize' } }}
              name={`item[${row.index}].vehicle`}
              //   control={control}
              defaultValue={row.original.vehicle_detail?.id}
              onChange={(e) => {
                if (array?.length > 0) {
                  handleApic(e?.target.value, row.original.id);
                } else {
                  handleApi(e?.target.value, row.original.id);
                }
              }}
            >
              {vehcile?.map((e, i) => {
                return (
                  <MenuItem
                    value={e?.id}
                    sx={{
                      mx: 1,
                      my: 0.5,
                      borderRadius: 0.75,
                      typography: 'body2',
                      textTransform: 'capitalize',
                    }}
                  >
                    {e?.name}
                  </MenuItem>
                );
              })}
            </RHFSelect>
          </FormProvider>
        ),
      },
      {
        accessorKey: 'device_id1',
        header: 'Device#1',
        size: 10,
        Cell: ({ row }) => (
          <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
            <RHFSelect
              name={`item[${row.index}].device_id1`}
              label="Device#1"
              size="large"
              InputLabelProps={{ shrink: true }}
              SelectProps={{ native: false, sx: { textTransform: 'capitalize' } }}
              defaultValue={row.original.device1?.id}
              onChange={(e) => {
                if (array?.length > 0) {
                  handleApidc(e?.target.value, row.original.id);
                } else {
                  handleApid(e?.target.value, row.original.id);
                }
              }}
            >
              {Device.map((e, i) => {
                return (
                  <MenuItem
                    value={e?.id}
                    sx={{
                      mx: 1,
                      my: 0.5,
                      borderRadius: 0.75,
                      typography: 'body2',
                      textTransform: 'capitalize',
                    }}
                  >
                    {e?.device_id}
                  </MenuItem>
                );
              })}
            </RHFSelect>
          </FormProvider>
        ),
      },
      {
        accessorKey: 'device_id2',
        header: 'Device#2',
        size: 20,
        Cell: ({ row }) => (
          <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
            <RHFSelect
              name={`item[${row.original?.id}].device_id2`}
              label="Device#2"
              size="large"
              InputLabelProps={{ shrink: true }}
              SelectProps={{ native: false, sx: { textTransform: 'capitalize' } }}
              defaultValue={row.original.device2?.id}
              onChange={(e) => {
                if (array?.length > 0) {
                  handleApiddc(e?.target.value, row.original.id);
                } else {
                  handleApidd(e?.target.value, row.original.id);
                }
              }}
            >
              {Device.map((e, i) => {
                return (
                  <MenuItem
                    value={e?.id}
                    sx={{
                      mx: 1,
                      my: 0.5,
                      borderRadius: 0.75,
                      typography: 'body2',
                      textTransform: 'capitalize',
                    }}
                  >
                    {e?.device_id}
                  </MenuItem>
                );
              })}
            </RHFSelect>
          </FormProvider>
        ),
      },
      {
        accessorKey: 'route',
        header: 'Route',
        size: 300,
        Cell: ({ row }) => (
          <TextField
          label="Route"
          type="text"
          defaultValue={row?.original?.route}
          id="hello"
          value={row?.original?.route}
          onClick={() => handleOpenModalone(row?.original)}
        />
        ),
      },
      {
        accessorKey: 'status',
        header: 'Status',
        size: 20,
        Cell: ({ row }) => (
          <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
            <RHFSelect
              name={`item[${row.original.id}].status`}
              label="Status"
              size="large"
              InputLabelProps={{ shrink: true }}
              SelectProps={{ native: false, sx: { textTransform: 'capitalize' } }}
              defaultValue={row.original.status}
              onChange={(e) => {
                if (array?.length > 0) {
                  handleApisc(e?.target.value, row.original.id);
                } else {
                  handleApis(e?.target.value, row.original.id);
                }
              }}
            >
              {arr?.map((e) => (
                <MenuItem
                  value={e?.name}
                  sx={{
                    mx: 1,
                    my: 0.5,
                    borderRadius: 0.75,
                    typography: 'body2',
                    textTransform: 'capitalize',
                  }}
                >
                  {e?.name}
                </MenuItem>
              ))}
            </RHFSelect>
          </FormProvider>
        ),
      },

      {
        accessorKey: 'actions',
        header: 'Actions',
        size: 100,
        Cell: ({ row }) => (
          <Box
            sx={{
              display: 'flex',
              flexWrap: 'nowrap',
              gap: '5px',
              justifyContent: 'flex-center',
            }}
          >
            {/* <IconButton
              color="primary"
              sx={{
                border: '1px solid',
                borderColor: 'primary.main',
                marginRight: '5px', // Add margin to create a gap
              }}
              onClick={() => {
                navigate(`/dashboard/editstandy/${row.original.id}`, { state: row.original });
              }}
            >
              <EditIcon />
            </IconButton> */}
            <IconButton
              color="error"
              sx={{
                border: '1px solid',
                borderColor: 'error.main',
              }}
              onClick={() => {
                handleDelete(row.original.id);
              }}
            >
              <DeleteIcon />
            </IconButton>
            <IconButton>
              <SettingsIcon />
            </IconButton>
            <IconButton>
              <TextsmsIcon onClick={() => handleOpenModal1(row.original.id)} />
            </IconButton>
            <Tooltip arrow title="View Message">
              <IconButton style={{ marginLeft: '5px' }}>
                <RemoveRedEye onClick={() => handleOpenModal2(row.original.id)} />
              </IconButton>
            </Tooltip>
          </Box>
        ),
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [Data, vehcile]
  );

  const [tableData, setTableData] = React.useState([]);

  React.useEffect(() => {
    setTableData(Data);
    if (Data) {
      reset(defaultValues);
    }
  }, [Data]);
  //   useEffect(() => {
  //   }, [Data, reset, defaultValues]);
  return (
    <Page title="Dashboard">
      {/* <Container maxWidth='lg'> */}
      <HeaderBreadcrumbs
        heading="Standby Associates"
        links={[{ name: '', href: '' }]}
        action={
          <>
            <Button
              variant="contained"
              startIcon={<Iconify icon="eva:plus-fill" />}
              component={RouterLink}
              to={'/dashboard/addstandy'}
              // onClick={() => { navigate(`/dashboard/maintenance`) }}
            >
              Standby Associates
            </Button>

            <Tooltip arrow title="Download File">
              <IconButton style={{ marginLeft: '5px' }}>
                <FileDownloadIcon onClick={handleDownload} />
              </IconButton>
            </Tooltip>
          </>
        }
      />
      <MaterialReactTable columns={columns} checkboxSelection data={Data} />
      {/* </Container> */}
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Send Message
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            <p id="modal-description">Are you sure you want to send a message to the selected users?</p>
            <TextField
              id="message"
              onChange={(e) => setmess(e.target.value)}
              label="Message"
              multiline
              rows={4}
              variant="outlined"
              fullWidth
              sx={{ mb: 2 }}
            />
            <div className="d-flex justify-content-end">
              <Button variant="contained" className="mx-2" onClick={handleSendMessage}>
                {loader === false ? (
                  ' Send Message '
                ) : (
                  <CircularProgress color="inherit" style={{ height: '10px', width: '20px' }} />
                )}
              </Button>

              <Button variant="contained" color="error" onClick={handleCloseModal}>
                Cancel
              </Button>
            </div>
          </Typography>
        </Box>
      </Modal>
      <Modal
        open={opens}
        onClose={handleCloses}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
      >
        <Box sx={{ ...style, width: 400 }}>
          <h2 id="parent-modal-title">Message</h2>
          <p id="parent-modal-description">
            {datas?.message}
            {/* {Data?.map((e,i)=>e?.message)} */}
          </p>
        </Box>
      </Modal>
      <Dia handleCloseModal1={handleCloseModal1} isModalOpen1={isModalOpen1} GetAllAdmin={GetRostered} propsdata={propsdata} />
    </Page>
  );
}

