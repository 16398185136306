/* eslint-disable */
import * as Yup from 'yup';
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router-dom';
import { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { LoadingButton } from '@mui/lab';
import { Grid, Card, Stack, DialogActions, Dialog, TextField, Button } from '@mui/material';
import { FormProvider, RHFTextField } from '../../../../components/hook-form';
import axios from '../../../../utils/axios';
import { PATH_DASHBOARD } from '../../../../routes/paths';
// import Button from '../../../theme/overrides/Button';

function Dia({ isModalOpen1, handleCloseModal1, GetAllAdmin, propsdata }) {
  // console.log(propsdata,'propsdata');
  const navigate = useNavigate();
  const [valuedata, setvaluedata] = useState('');
  useEffect(() => {
    setvaluedata(propsdata?.route);
  }, [propsdata]);
  const { enqueueSnackbar } = useSnackbar();

  const NewRaritySchema = Yup.object().shape({
    route: Yup.string().required('route is required'),
  });

  const defaultValues = useMemo(
    () => ({
      route: propsdata?.route ||'',
    }),
    [propsdata]
  );


  const methods = useForm({
    resolver: yupResolver(NewRaritySchema),
    defaultValues,
  });

  const {
    handleSubmit,
    reset,

    formState: { isSubmitting, errors },
  } = methods;
  useEffect(() => {
    if (propsdata) {
      reset(defaultValues);
    }
  }, [propsdata, reset, defaultValues]);
//   console.log(errors, 'errors');
  const onSubmit = async (data) => {
    try {
        const dorm = new FormData();
  
        dorm.append(`route[0]`, data?.route);
  
        dorm.append(`stand_by_associate_id[0]`, propsdata?.id);
  
        await axios.post(`/admin/standbyassociateMessage`, dorm).then((response) => {
          // console.log(response?.data, '=========');
          if (response.data?.message) {
            enqueueSnackbar(response.data?.message);
            handleCloseModal1();
            GetAllAdmin();
            // reset();
            // navigate('/dashboard/home');
          }
        });
      } catch (error) {
        // enqueueSnackbar(error?.message, {
        // variant: 'error',
        // });
        console.error(error);
      }
  };
  return (
    <Dialog
      open={isModalOpen1}
      onClose={handleCloseModal1}
      maxWidth="md"
      fullWidth={false}
      PaperProps={{ sx: { width: '30%', maxWidth: 'none', maxHeight: '90%' } }}
    >
      <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Card sx={{ p: 3 }}>
              <Stack spacing={3}>
                <Grid container spacing={3}>
                  <Grid item xs={12} md={12}>
                    <RHFTextField name="route" label="Enter Route" fullWidth InputLabelProps={{ shrink: true }} />
                  </Grid>
                  {/* Add more Grid items for other fields */}
                </Grid>
                <Grid item xs={12} md={12}>
                  <LoadingButton type="submit" variant="contained" size="large" loading={isSubmitting} fullWidth>
                    Update Route
                  </LoadingButton>
                </Grid>
              </Stack>
            </Card>
          </Grid>
        </Grid>
      </FormProvider>
      <DialogActions>{/* Add any additional actions or buttons here */}</DialogActions>
    </Dialog>
  );
}

export default Dia;
