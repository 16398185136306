/* eslint-disable */
import * as Yup from 'yup';
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router-dom';
import { useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { LoadingButton } from '@mui/lab';
import { Grid, Card, Stack, DialogActions, Dialog } from '@mui/material';
import { FormProvider } from '../../components/hook-form';
import axios from '../../utils/axios';
import { PATH_DASHBOARD } from '../../routes/paths';
// import Button from '../../../theme/overrides/Button';

function Dia({ isModalOpen1, handleCloseModal1,GetAllAdmin }) {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const NewRaritySchema = Yup.object().shape({
    file: Yup.mixed().required('File is required'),
  });

  const defaultValues = useMemo(
    () => ({
      file: null,
    }),
    []
  );

  const methods = useForm({
    resolver: yupResolver(NewRaritySchema),
    defaultValues,
  });

  const {
    handleSubmit,
    setValue,
    formState: { isSubmitting },
  } = methods;

  const onSubmit = async (data) => {
    try {
      const formData = new FormData();
      formData.append('file', data.file);

      const response = await axios.post('/admin/employees', formData);

      enqueueSnackbar('Created Successfully');
      handleCloseModal1();
      navigate(PATH_DASHBOARD.slot.slot);
      GetAllAdmin()
    } catch (error) {
      enqueueSnackbar(error?.message, {
        variant: 'error',
      });
      console.error(error);
    }
  };

  return (
    <Dialog open={isModalOpen1} onClose={handleCloseModal1} maxWidth="md">
      <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={1}>
          <Grid item xs={12} md={12}>
            <Card sx={{ p: 3 }}>
              <Stack spacing={3}>
                <input type="file" onChange={(e) => setValue('file', e.target.files[0])} />
                <Grid item xs={4} md={4}>
                  <LoadingButton type="submit" variant="contained" size="large" loading={isSubmitting}>
                    Add File
                  </LoadingButton>
                </Grid>
              </Stack>
            </Card>
          </Grid>
        </Grid>
      </FormProvider>
      <DialogActions>
        
        {/* <Button onClick={handleCloseModal1} color="primary">
          Close
        </Button> */}
      </DialogActions>
    </Dialog>
  );
}

export default Dia;
