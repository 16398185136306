/* eslint-disable */
import * as React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import MaterialReactTable from "material-react-table";
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import EditIcon from "@mui/icons-material/Edit";
import { Delete as DeleteIcon } from "@mui/icons-material";
import { useNavigate, Link as RouterLink } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { Box, IconButton, Container,Button } from '@mui/material';
import Page from '../../../components/Page';
import { PATH_DASHBOARD } from '../../../routes/paths';
// import HeaderBreadcrumbs from 'src/components/HeaderBreadcrumbs';
import HeaderBreadcrumbs from '../../../components/HeaderBreadcrumbs';
// import Button from 'src/theme/overrides/Button';
// import Button from '../../../theme/overrides/Button';
import Iconify from '../../../components/Iconify';
import axiosInstance from '../../../utils/axios';

export default function FilterTableEmployeethree({dataone}) {

  const columns = React.useMemo(
    () => [
        {
            accessorKey: "id",
            header: "ID",
            size: 20,
        },
        {
            accessorKey: "success",
            header: "Photo-on-Delivery Compliance",
            size: 20,
        },
        {
            accessorKey: "rejects",
            header: "Photo-on-Delivery Rejected",
            size: 20,
        },
        {
            accessorKey: "blurry_photo",
            header: "Blurry Photo",
            size: 20,
        },
        {
            accessorKey: "human_in_the_picture",
            header: "Human in the Picture",
            size: 20,
        },
        {
            accessorKey: "no_package_detected",
            header: "No Package Detected",
            size: 20,
        },
        {
            accessorKey: "package_in_car",
            header: "Package in Car",
            size: 20,
        },
        {
            accessorKey: "package_in_hand",
            header: "Package in Hand",
            size: 20,
        },
        {
            accessorKey: "package_not_clearly_visible",
            header: "Package Not Clearly Visible",
            size: 20,
        },
        {
            accessorKey: "package_too_close",
            header: "Package Too Close",
            size: 20,
        },
        {
            accessorKey: "photo_too_dark",
            header: "Photo Too Dark",
            size: 20,
        },
        {
            accessorKey: "other",
            header: "Other",
            size: 20,
        }
   
    //   {
    //     accessorKey: "actions",
    //     header: "Actions",
    //     size: 10,
    //     Cell: ({ row }) => (
    //       <Box>
    //         <IconButton
    //           color="primary"
    //           sx={{
    //             border: "1px solid",
    //             borderColor: "primary.main",
    //             marginRight: "5px" // Add margin to create a gap
    //           }}
    //           onClick={() => { navigate(`/dashboard/maintenance/${row.original.id}`) }}
    //         >
    //           <EditIcon />
    //         </IconButton>
    //         <IconButton
    //           color="error"
    //           sx={{
    //             border: "1px solid",
    //             borderColor: "error.main",
    //           }}
    //           onClick={() => {    handleDelete(row.original.id);
    //           }}
    //         >
    //           <DeleteIcon />
    //         </IconButton>
    //       </Box>
    //     ),
    //   },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const [tableData, setTableData] = React.useState([]);

  const data = [
    {
      id: 1,
      photoOnDeliveryCompliance: "Yes",
      photoOnDeliveryRejected: "No",
      blurryPhoto: "No",
      humanInPicture: "No",
      noPackageDetected: "No",
      packageInCar: "No",
      packageInHand: "Yes",
      packageNotClearlyVisible: "No",
      packageTooClose: "No",
      photoTooDark: "No",
      other: ""
    },
    {
      id: 2,
      photoOnDeliveryCompliance: "Yes",
      photoOnDeliveryRejected: "No",
      blurryPhoto: "Yes",
      humanInPicture: "Yes",
      noPackageDetected: "No",
      packageInCar: "No",
      packageInHand: "No",
      packageNotClearlyVisible: "Yes",
      packageTooClose: "No",
      photoTooDark: "No",
      other: ""
    },
    // Add more data as needed
];


  React.useEffect(() => {
    setTableData(data);
  }, []);

  return (
    <Page title="Pod Detail">
      <Container maxWidth='lg'>
      <HeaderBreadcrumbs
          heading="Pod Detail"
          links={[
            { name: '', href: '' },]}
          action={
            <>
            </>
            // <Button
            //   variant="contained"
            //   startIcon={<Iconify icon="eva:plus-fill" />}
            //   component={RouterLink}
            //   to={'/dashboard/maintenance'}
            //   // onClick={() => { navigate(`/dashboard/maintenance`) }}


            // >
            //   Repair And Maintenance
            // </Button>
          }
        />
        <MaterialReactTable
          columns={columns}
          data={dataone[0]?.pod_reports|| []}
          enableRowActions={false} // Disable default row actions
        />
      </Container>
    </Page>
  );
}

