/* eslint-disable */
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { LoadingButton } from '@mui/lab';
import { Card, Grid, Stack, Container, Select, MenuItem, InputLabel } from '@mui/material';
import { useLocation, useNavigate, useParams } from 'react-router';
import { useSnackbar } from 'notistack';
import { FormProvider, RHFTextField, RHFSelect } from '../../../components/hook-form';
import axiosInstance from '../../../utils/axios';
import HeaderBreadcrumbs from '../../../components/HeaderBreadcrumbs';

export default function EditDamage() {
  const NewSubSchema = Yup.object().shape({
    date: Yup.string().required('Date is required'),
    Incident: Yup.string().required('Incident is required'),
    
    Location: Yup.object().shape({
      street: Yup.string().required('Street is required'),
      city: Yup.string().required('City is required'),
      state: Yup.string().required('State is required'),
      zip: Yup.string().required('Zip Code is required'),
    }),

    Associate: Yup.string().required('Associate is required'),
    week: Yup.string().required('Week is required'),
    year: Yup.string().required('Year is required'),
    investigated_by: Yup.string().required('investigated_by is required'),
    est_cost: Yup.number().required('est_cost is required'),
    displinary_action: Yup.string().required('displinary_action Name is required'),
    Vehicle: Yup.string().required('Vehicle is required'),
    Verified: Yup.string().required('Verified is required'),
    severity: Yup.string().required('severity is required'),
    atFault: Yup.string().required('At Fault is required'),
    drugTest: Yup.string().required('Drug Test is required'),
    results: Yup.string().required('Results is required'),
    claim_num: Yup.string().required('Claim Status is required'),
    police_department: Yup.string().required('Police Department is required'),
    associate_statement: Yup.string().required('Associate Statement by is required'),
    witness_name: Yup.string().required('Witness Name is required'),
    witness_statement: Yup.string().required('Witness Statement is required'),
    file: Yup.mixed().required('File is required'),
  });

  const { id } = useParams();
  const {state} = useLocation()
  console.log(state,'state');
  const navigate = useNavigate();
  const [Data, setdata] = useState([]);
  const { enqueueSnackbar } = useSnackbar();
  const data = [];
  const year = [];
  for (var i = 1; i <= 60; i++) {
      data.push({
          id: i,
          name: `week ${i}`,
      });
  }
  for (var i = Data?.startYear; i <= Data?.currentYear; i++) {
      year.push({
          // id: i,
          name: `${i}`,
      });
  }
  const [stateone, setState] = useState([]);
  const GetAllAdmin = () => {
    axiosInstance
      .get('/admin/employees')
      .then((response) => {
        console.log(response);
        if (response.data.employee) {
          console.log(response?.data?.employee);
          setState(response?.data?.employee);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };
  useEffect(() => {
    GetAllAdmin();
  }, []);
  const defaultValues = {
    date: state?.name?.incident_date || '',
    Incident: state?.name?.incident_type || '',
    
    Location: {
      street: state?.name?.street || '',
      city: state?.name?.city || '',
      state: state?.name?.state || '',
      zip: state?.name?.zipcode || '',
    },
    Vehicle: state?.name?.vehicle ||'',
    Verified: state?.name?.verified || '',
    atFault: state?.name?.at_fault || '',
    severity: state?.name?.severity || '',
    drugTest: state?.name?.drug_test_date || '',
    results: state?.name?.test_result || '',
    Associate: state?.name?.associate_statement ||'',
    est_cost: state?.name?.est_cost ||'',
    investigated_by:state?.name?.investigated_by ||'',
    displinary_action:state?.name?.displinary_action ||'',
    week: state?.name?.week ||'',
    year: state?.name?.year ||'',
    claim_num: state?.name?.claim_number || '',
    police_department: state?.name?.police_department || '',
    associate_statement: state?.name?.associate_statement || '',
    witness_name: state?.name?.witness_name||'',
    witness_statement: state?.name?.witness_statement ||'',
    file: null,
  };

  const methods = useForm({
    resolver: yupResolver(NewSubSchema),
    defaultValues,
  });

  const {
    handleSubmit,
    formState: { isSubmitting, errors },
    register, // Add the register function from useForm
  } = methods;
  const [selectedFile, setSelectedFile] = useState(null);
  const [emID, setemID] = useState();

  const handleFileChange = (e) => {
    setSelectedFile(e.target.files[0]);
  };
  const onSubmit = async (data) => {
    try {
      const dorm = new FormData();
      dorm.append('employee_id', emID);
      dorm.append('vehicle_detail_id', localStorage.getItem('Parent_ID'));
      dorm.append('incident_type', data?.Incident);
      dorm.append('incident_date', data?.date);
      dorm.append('at_fault', data?.atFault);
      dorm.append('street', data?.Location?.street);
      dorm.append('city', data?.Location?.city);
      dorm.append('est_cost', data?.est_cost);
      dorm.append('week', data?.week);
      dorm.append('year', data?.year);
      dorm.append('displinary_action', data?.displinary_action);
      dorm.append('investigated_by', data?.investigated_by);
      dorm.append('state', data?.Location?.state);
      dorm.append('zipcode', data?.Location?.zip);
      dorm.append('severity', data?.severity);
      dorm.append('drug_test_date', data?.drugTest);
      dorm.append('test_result', data?.results);
      dorm.append('claim_number', data?.claim_num);
      dorm.append('verified', data?.Verified);
      dorm.append('police_department', data?.police_department);
      dorm.append('associate_statement', data?.Associate);
      dorm.append('witness_name', data?.witness_name);
      dorm.append('witness_statement', data?.witness_statement);
      dorm.append('file[0]', data?.file);
      dorm.append('_method', 'PUT');

      await axiosInstance.post(`admin/vehicledemages/${id}`, dorm).then((response) => {
        console.log(response?.data);
        if (response?.data?.message === 'Vehicle Demage Updated Successfully') {
          enqueueSnackbar(response?.data?.message);

          navigate('/dashboard/repair_damage');
        }
      });
    } catch (error) {
      enqueueSnackbar(error?.message, {
        variant: 'error',
      });
      console.error(error);
    }
  };
  const GetYear = () => {
    axiosInstance
        .get('/admin/years')
        .then((response) => {
            // console.log(response.data)
            if (response?.data) {
                // console.log(response?.data);
                setdata(response?.data);
            }
        })
        .catch((error) => {
            console.error(error);
        });
};
useEffect(() => {
    GetYear();
}, []);
  return (
    <Container maxWidth="sm">
      <HeaderBreadcrumbs heading="Edit Damage" links={[{ name: '', href: '' }]} />
      <Card>
        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={1}>
            <Grid item xs={12} md={12}>
              <Card sx={{ p: 3 }}>
                <Stack spacing={3}>
                <RHFSelect
                    name="week"
                    label="Select Week"
                    size="large"
                    InputLabelProps={{ shrink: true }}
                    SelectProps={{ native: false, sx: { textTransform: 'capitalize' } }}
                  >
                    {data?.map((e) => (
                      <MenuItem
                        value={e?.name}
                        sx={{
                          mx: 1,
                          my: 0.5,
                          borderRadius: 0.75,
                          typography: 'body2',
                          textTransform: 'capitalize',
                        }}
                      // onClick={()=>setemID(e?.id)}
                      >
                        {e?.name}
                        {/* hi */}
                      </MenuItem>
                    ))}
                  </RHFSelect>

                  <RHFSelect
                    name="year"
                    label="Select year"
                    size="large"
                    InputLabelProps={{ shrink: true }}
                    SelectProps={{ native: false, sx: { textTransform: 'capitalize' } }}
                  >
                    {year?.map((e) => (
                      <MenuItem
                        value={e?.name}
                        sx={{
                          mx: 1,
                          my: 0.5,
                          borderRadius: 0.75,
                          typography: 'body2',
                          textTransform: 'capitalize',
                        }}
                      // onClick={()=>setemID(e?.id)}
                      >
                        {e?.name}
                        {/* hi */}
                      </MenuItem>
                    ))}
                  </RHFSelect>
                  <RHFTextField
                    name="date"
                    label="Date And Time"
                    type="datetime-local"
                    InputLabelProps={{ shrink: true }}
                    placeholder="Select date and time"
                  />

                  <RHFSelect
                    name="Incident"
                    label="Select Incident Type"
                    size="large"
                    InputLabelProps={{ shrink: true }}
                    SelectProps={{ native: false, sx: { textTransform: 'capitalize' } }}
                  >
                    <MenuItem value="Accident">Accident</MenuItem>
                    <MenuItem value="Breakdown">Breakdown</MenuItem>
                    <MenuItem value="Vandalism">Vandalism</MenuItem>
                  </RHFSelect>

                  <RHFSelect
                    name="Associate"
                    label="Select Associate"
                    size="large"
                    InputLabelProps={{ shrink: true }}
                    SelectProps={{ native: false, sx: { textTransform: 'capitalize' } }}
                  >
                    {stateone?.map((e) => (
                      <MenuItem
                        value={e?.name}
                        sx={{
                          mx: 1,
                          my: 0.5,
                          borderRadius: 0.75,
                          typography: 'body2',
                          textTransform: 'capitalize',
                        }}
                        onClick={() => setemID(e?.id)}
                      >
                        {e?.name}
                      </MenuItem>
                    ))}
                  </RHFSelect>
                  <InputLabel htmlFor="grouped-select" style={{ marginBottom: '0px' }}>
                    Incident Location
                  </InputLabel>
                  <RHFTextField
                    name="Location.street"
                    label="Street"
                    InputLabelProps={{ shrink: true }}
                    placeholder="Enter street"
                  />
                  <RHFTextField
                    name="Location.city"
                    label="City"
                    InputLabelProps={{ shrink: true }}
                    placeholder="Enter city"
                  />
                  <RHFTextField
                    name="Location.state"
                    label="State"
                    InputLabelProps={{ shrink: true }}
                    placeholder="Enter state"
                  />
                  <RHFTextField
                    name="Location.zip"
                    label="Zip Code"
                    InputLabelProps={{ shrink: true }}
                    placeholder="Enter zip code"
                  />
                  {/* <InputLabel htmlFor="grouped-select" style={{ marginBottom: '-15px' }}>
                    * Vehicle
                  </InputLabel> */}
                  <RHFSelect
                    name="Vehicle"
                    label="Select Vehicle"
                    size="large"
                    InputLabelProps={{ shrink: true }}
                    SelectProps={{ native: false, sx: { textTransform: 'capitalize' } }}
                  >
                    <MenuItem value="Car">Car</MenuItem>
                    <MenuItem value="Truck">Truck</MenuItem>
                    <MenuItem value="Motorcycle">Motorcycle</MenuItem>
                  </RHFSelect>

                  <RHFSelect
                    name="Verified"
                    label="Select Verified By"
                    size="large"
                    InputLabelProps={{ shrink: true }}
                    SelectProps={{ native: false, sx: { textTransform: 'capitalize' } }}
                  >
                    <MenuItem value="Yes">Yes</MenuItem>
                    <MenuItem value="No">No</MenuItem>
                    <MenuItem value="Unknown">Unknown</MenuItem>
                  </RHFSelect>

                  <RHFSelect
                    name="atFault"
                    label="Select AT Fault"
                    size="large"
                    InputLabelProps={{ shrink: true }}
                    SelectProps={{ native: false, sx: { textTransform: 'capitalize' } }}
                  >
                    <MenuItem value="Yes">Yes</MenuItem>
                    <MenuItem value="No">No</MenuItem>
                    <MenuItem value="Unknown">Unknown</MenuItem>
                  </RHFSelect>
                  <RHFTextField
                    name="drugTest"
                    label="Drug Test Date"
                    type="date"
                    InputLabelProps={{ shrink: true }}
                    placeholder="Select drug test date"
                  />

                  <RHFSelect
                    name="results"
                    label="Select Test Results"
                    size="large"
                    InputLabelProps={{ shrink: true }}
                    SelectProps={{ native: false, sx: { textTransform: 'capitalize' } }}
                  >
                    <MenuItem value="Positive">Positive</MenuItem>
                    <MenuItem value="Negative">Negative</MenuItem>
                    <MenuItem value="Pending">Pending</MenuItem>
                  </RHFSelect>
                  <RHFTextField
                    name="claim_num"
                    label="Claim Number"
                    InputLabelProps={{ shrink: true }}
                    placeholder="Enter claim number"
                  />
                  <RHFTextField
                    name="police_department"
                    label="Police Department"
                    InputLabelProps={{ shrink: true }}
                    placeholder="Enter police department"
                  />
                  <InputLabel htmlFor="grouped-select" style={{ marginBottom: '0px' }}>
                    STATEMENTS
                  </InputLabel>
                  <RHFTextField
                    name="associate_statement"
                    label="Associate Statement"
                    InputLabelProps={{ shrink: true }}
                    placeholder="Enter associate statement"
                  />
                  <RHFTextField
                    name="witness_name"
                    label="Witness Name"
                    InputLabelProps={{ shrink: true }}
                    placeholder="Enter witness name"
                  />
                  <RHFTextField
                    name="witness_statement"
                    label="Witness Statement"
                    InputLabelProps={{ shrink: true }}
                    placeholder="Enter witness statement"
                  />
                  <RHFTextField
                    name="severity"
                    label="Severity"
                    InputLabelProps={{ shrink: true }}
                    placeholder="Enter Severity"
                  />
                     <RHFTextField
                    name="est_cost"
                    label="Est Cost"
                    InputLabelProps={{ shrink: true }}
                    placeholder="Enter Est Cost"
                  />
                  <RHFTextField
                    name="investigated_by"
                    label="Investigated By"
                    InputLabelProps={{ shrink: true }}
                    placeholder="Enter Investigated By"
                  />
                  <RHFTextField
                    name="displinary_action"
                    label="Displinary Action"
                    InputLabelProps={{ shrink: true }}
                    placeholder="Enter Displinary Action"
                  />
                  <input type="file" onChange={handleFileChange} {...register('file')} />
                  {errors.file && <p style={{ color: 'red' }}>{errors.file.message}</p>}
                  <Grid item xs={6} md={6}>
                    <LoadingButton type="submit" variant="contained" size="large" loading={isSubmitting}>
                      Edit Damage
                    </LoadingButton>
                  </Grid>
                </Stack>
              </Card>
            </Grid>
          </Grid>
        </FormProvider>
      </Card>
    </Container>
  );
}
