/* eslint-disable */
import * as React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import MaterialReactTable from 'material-react-table';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import EditIcon from '@mui/icons-material/Edit';
import { Delete as DeleteIcon } from '@mui/icons-material';
import { useNavigate, Link as RouterLink } from 'react-router-dom';
// import {Container} from '@mui/material'
// import { PATH_DASHBOARD } from '../../../routes/paths';

import { useSnackbar } from 'notistack';
import { Box, IconButton, Typography, Button, Paper, Container } from '@mui/material';
import Page from '../../../components/Page';
import axiosInstance from '../../../utils/axios';
// import { PATH_DASHBOARD } from '../../routes/paths';

import Iconify from '../../../components/Iconify';
import HeaderBreadcrumbs from '../../../components/HeaderBreadcrumbs';

import Dia from './Dia';

export default function Device() {
  const { enqueueSnackbar } = useSnackbar();

  const navigate = useNavigate();
  const handleCloseModal1 = () => {
    setIsModalOpen1(false)
};
  const [isModalOpen1, setIsModalOpen1] = React.useState(false);

  const handleOpenModal1 = () => {
    console.log('');
    // setCommentView(report?.report);
    setIsModalOpen1(true);
  };
  const [Data, setdata] = React.useState([]);
  // const [loader, setloader] = useState(false);
  const [loader, setloader] = React.useState(false);
  
  console.log(Data, 'Data');
  const GetAllAdmin = () => {
    setloader(true)
    axiosInstance
      .get('admin/devices')
      .then((response) => {
        if (response.data.device) {
          // console.log(response?.data);
          setdata(response?.data?.device);
          setloader(false)
        }
        console.log(response, 'resp');
      })
      .catch((error) => { 
        setloader(false)

        console.error(error);

      });
  };
  React.useEffect(() => {
    GetAllAdmin();
  }, []);
  async function handleDelete(rowdata) {
    console.log(rowdata);
    try {
      await axiosInstance.delete(`admin/devices/${rowdata}`).then((response) => {
        if (response?.data?.message === 'Deleted successfully') {
          enqueueSnackbar(response?.data?.message);
          GetAllAdmin();
        }
      });
    } catch (error) {
      enqueueSnackbar(error?.message, {
        variant: 'error',
      });
      console.error(error);
    }
  }
  const columns = React.useMemo(
    () => [
      {
        accessorKey: 'id',
        header: 'ID',
        size: 10,
      },
      {
        accessorKey: 'device_id',
        header: 'Device ID',
        size: 20,
      },
      {
        accessorKey: 'carrier',
        header: 'Carrier',
        size: 20,
      },
      {
        accessorKey: 'telephone',
        header: 'Telephone',
        size: 20,
      },
     
      {
        accessorKey: 'actions',
        header: 'Actions',
        size: 10,
        Cell: ({ row }) => (
          <Box>
            {/* <IconButton
              color="primary"
              sx={{
                border: '1px solid',
                borderColor: 'primary.main',
                marginRight: '5px',
              }}
              onClick={() => {
                navigate(`/dashboard/editelement/${row.original.id}`);
              }}
            >
              <EditIcon />
            </IconButton> */}
            <IconButton
              color="error"
              sx={{
                border: '1px solid',
                borderColor: 'error.main',
              }}
              onClick={() => {
                handleDelete(row.original.id);

              }}
            >
              <DeleteIcon />
            </IconButton>
          </Box>
        ),
      },
    ],
    []
  );
  
  const data = [
    {
      id: 1,
      
      Device_ID: '67890',
      Carrier: 'Toyota Camry',
      Telephone: '123-456-7890',
   
 
    },
    {
      id: 2,
      Device_ID: '67890',
      Carrier: 'Toyota Camry',
      Telephone: '123-456-7890',
  
    },
    // Add more data as needed
  ];
  
  const [tableData, setTableData] = React.useState([]);


  React.useEffect(() => {
    setTableData(data);
  }, []);

  return (
<Page title="Device">
  <Container maxWidth="lg">
    <HeaderBreadcrumbs
      heading="Device"
      links={[{ name: '', href: '' }]}
      action={
        <>
          <Button
            variant="contained"
            startIcon={<Iconify icon="eva:plus-fill" />}
            component={RouterLink}
            to={''}
            onClick={handleOpenModal1}
            style={{ marginRight: '8px' }} // Add margin to the right for spacing
          >
            Import
          </Button>
{/*           
          <Button
            variant="contained"
            startIcon={<Iconify icon="eva:plus-fill" />}
            component={RouterLink}
            to={PATH_DASHBOARD.element.addelement}
          >
            Vehicle
          </Button> */}
        </>
      }
    />
    <MaterialReactTable
    state={{showSkeletons:loader}}
      columns={columns}
      data={Data}
      enableRowActions={false} // Disable default row actions
    />
  </Container>
<Dia handleCloseModal1={handleCloseModal1} isModalOpen1={isModalOpen1} GetAllAdmin={GetAllAdmin}/>

</Page>

  );
}
