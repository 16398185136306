/* eslint-disable */
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Container, Box, Typography, MenuItem, Select, Divider, Radio, Grid, Card, CardContent, Stack } from '@mui/material'; // Import Typography for text
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import Page from '../../../components/Page';
import HeaderBreadcrumbs from '../../../components/HeaderBreadcrumbs';
import FilterTable from './FilterTable';
import FiltertwoTable from './FilterTableTwo';
import FilterTableEmployee from './FilterTableEmployee';
import FilterTableEmployeeOne from './FilterTableEmployeeOne';
import FilterTableEmployeeTwo from './FilterTableEmployeeTwo';
import FilterTableEmployeethree from './FilterTableEmployeethree';
import FilterTableEmployeeFour from './FilterTableEmployeeFour';
import FilterTableEmployeefive from './FilterTableEmployeefive';
import FilterTableEmployeesix from './FilterTableEmployeesix';
import FilterTableEmployeeseven from './FilterTableEmployeeseven';
import FilterTableEmployeeEight from './FilterTableEmployeeEight';
import FilterTableEmployeeNine from './FilterTableEmployeeNine';
import FilterTableEmployeeTen from './FilterTableEmployeeTen';
import FilterTableEmployeeDemage from './FilterTableEmployeeDemage';
import { FormProvider, RHFSelect } from '../../../components/hook-form';
import axiosInstance from '../../../utils/axios';

function FilterEmployee() {
  const NewSubSchema = Yup.object().shape({
    employee: Yup.string().required('employee is required'),
  });

  const defaultValues = {
    employee: '',

  };
  const methods = useForm({
    resolver: yupResolver(NewSubSchema),
    defaultValues,
  });

  const {
    handleSubmit,
    formState: { isSubmitting, errors },
    register, // Add the register function from useForm
  } = methods;
  const [selectedOption, setSelectedOption] = useState(null);

  const handleOptionChang = (event) => {
    // console.log(event?.target.value ,'target');
    setSelectedOption(event.target.value);
  };
  const handleOptionChange = (event) => {
    setSelectedItem(event.target.value);
  };
  const onSubmit = async (data) => {
    console.log(data, 'faiz');
  }
  const [data, setData] = useState([]);
  const [dataone, setDataone] = useState([]);
  const [selectedItem, setSelectedItem] = useState(null);
  // console.log(data, dataone, 'faiz');
  const GetAllAdmin = () => {
    axiosInstance
      .get(selectedItem == null ? `/admin/employees` : `/admin/employees?name=${selectedItem}`)
      .then((response) => {
        console.log(response);
        if (response.data.employee) {
          if (selectedItem == null) {
            setData(response.data.employee);
          } else {
            setDataone(response.data.employee);
          }
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };
  useEffect(() => {
    GetAllAdmin();
  }, [selectedItem]);

  return (
    <Page title="Vehicle">
      <Container maxWidth="lg">
        <HeaderBreadcrumbs heading="" links={[{ name: '', href: '' }]} action={<> </>} />
        <Typography variant="h5" style={{ marginTop: '-40px', marginBottom: '10px' }}>
          Internal Employee Information
        </Typography>
        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
          <Select {...methods.register('employee')} label="Select Employee" defaultValue=""
            onChange={handleOptionChange}
            fullWidth>
            {data.map((item) => (
              <MenuItem key={item.name} value={item.name}>
                {item.name}
              </MenuItem>
            ))}
          </Select>
          {selectedItem && (

            <>
              <Grid container spacing={3}>
                <Grid item xs={12} md={6}>
                  <Card sx={{ my: 3 }}>
                    <CardContent>
                      <Stack spacing={2}>
                        <Stack direction="row" justifyContent="space-between">
                          <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                            Driver licence Number
                          </Typography>
                          <Typography variant="body1">   {dataone[0]?.driver_license_number ? dataone[0]?.driver_license_number : 'Empty'}</Typography>
                        </Stack>
                        <Divider />
                        <Stack direction="row" justifyContent="space-between">
                          <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                            Issuing State
                          </Typography>
                          <Typography variant="body1">{dataone[0]?.issuing_state ? dataone[0]?.issuing_state : 'Empty'}</Typography>
                        </Stack>
                        <Divider />

                        <Stack direction="row" justifyContent="space-between">
                          <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                            Expiration Date
                          </Typography>
                          <Typography variant="body1"> {dataone[0]?.expiration_date ? dataone[0]?.expiration_date : 'Empty'}</Typography>
                        </Stack>
                        <Divider />
                        <Stack direction="row" justifyContent="space-between">
                          <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                            Vehicle Certification
                          </Typography>
                          <Typography variant="body1">{dataone[0]?.vehicle_type}</Typography>
                        </Stack>
                        <Divider />
                        <Stack direction="row" justifyContent="space-between">
                          <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                            Cargo Van
                          </Typography>
                          <Typography variant="body1">  {dataone[0]?.vc_cargo_van ? dataone[0]?.vc_cargo_van : 'Empty'}</Typography>
                        </Stack>
                        <Divider />
                        <Stack direction="row" justifyContent="space-between">
                          <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                            Step Van
                          </Typography>
                          <Typography variant="body1"> 
                           <Radio
                      checked={selectedOption === 'promaster3500'}
                      onChange={handleOptionChang}
                      value="promaster3500"
                      name="promasterRadio"
                      inputProps={{ 'aria-label': 'Promaster 3500' }}
                    /></Typography>

                        </Stack>
                        <Divider />
                        <Stack direction="row" justifyContent="space-between">
                          <Typography variant="body2" sx={{ color: 'text.secondary', }}>
                            Electric Vehicle
                          </Typography>
                          <Typography variant="body1">
                            <Radio
                              checked={selectedOption === 'Electric Vehicle'}
                              onChange={handleOptionChang}
                              value="Electric Vehicle"
                              name="promasterRadio"
                              inputProps={{ 'aria-label': 'Promaster 3500' }}
                            /></Typography>

                        </Stack>
                        <Divider />
                        <Stack direction="row" justifyContent="space-between">
                          <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                            Custom Delivery Vehicle
                          </Typography>
                          <Typography variant="body1">
                            <Radio
                              checked={selectedOption === 'Custom Delivery Vehicle'}
                              onChange={handleOptionChang}
                              value="Custom Delivery Vehicle"
                              name="promasterRadio"
                              inputProps={{ 'aria-label': 'Promaster 3500' }}
                            />
                          </Typography>
                        </Stack>
                      </Stack>
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item xs={12} md={6} >

                  <Card sx={{ my: 3 }} >
                    <CardContent >
                      <Stack spacing={2}>
                        <Stack direction="row" justifyContent="space-between">
                          <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                            Name:

                          </Typography>
                          <Typography variant="body1">{dataone[0]?.name ? dataone[0]?.name : 'Empty'}</Typography>
                        </Stack>
                        <Divider />
                        <Stack direction="row" justifyContent="space-between">
                          <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                            Gender:

                          </Typography>
                          <Typography variant="body1">{dataone[0]?.gender ? dataone[0]?.gender : 'Empty'}</Typography>
                        </Stack>
                        <Divider />
                        <Stack direction="row" justifyContent="space-between">
                          <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                            D.O.B
                          </Typography>
                          <Typography variant="body1">{dataone[0]?.dob ? dataone[0]?.dob : 'Empty'}</Typography>
                        </Stack>
                        <Divider />
                        <Stack direction="row" justifyContent="space-between">
                          <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                            Hire Date:
                          </Typography>
                          <Typography variant="body1">{dataone[0]?.hire_date ? dataone[0]?.hire_date : 'Empty'}</Typography>
                        </Stack>
                        <Divider />
                        <Stack direction="row" justifyContent="space-between">
                          <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                            Status
                          </Typography>
                          <Typography variant="body1">{dataone[0]?.status ? dataone[0]?.status : 'Empty'}</Typography>
                        </Stack>
                        <Divider />
                        <Stack direction="row" justifyContent="space-between">
                          <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                            Transporter ID:
                          </Typography>
                          <Typography variant="body1">{dataone[0]?.transporter_id ? dataone[0]?.transporter_id : 'Empty'}</Typography>
                        </Stack>
                        <Divider />
                        <Stack direction="row" justifyContent="space-between">
                          <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                            Medical Card Expiration
                          </Typography>
                          <Typography variant="body1">01/01/2022</Typography>
                        </Stack>
                      </Stack>
                    </CardContent>
                  </Card>
                </Grid>

              </Grid>
              <div style={{ marginTop: '50px' }}>
                <FilterTableEmployee dataone={dataone} />
                <Divider sx={{ my: 5 }} />
                <FilterTableEmployeeOne dataone={dataone} />
                <Divider sx={{ my: 5 }} />
                <FilterTableEmployeeTwo dataone={dataone} />
                <Divider sx={{ my: 5 }} />
                <FilterTableEmployeeFour dataone={dataone} />
                <Divider sx={{ my: 5 }} />
                <FilterTableEmployeethree dataone={dataone} />
                <Divider sx={{ my: 5 }} />
                <FilterTableEmployeefive dataone={dataone} />
                <Divider sx={{ my: 5 }} />
                <FilterTableEmployeesix dataone={dataone} />
                <Divider sx={{ my: 5 }} />
                <FilterTableEmployeeseven dataone={dataone} />
                <Divider sx={{ my: 5 }} />
                <FilterTableEmployeeEight dataone={dataone} />
                <Divider sx={{ my: 5 }} />
                <FilterTableEmployeeNine dataone={dataone} />
                <Divider sx={{ my: 5 }} />
                <FilterTableEmployeeTen dataone={dataone} />
                <Divider sx={{ my: 5 }} />
                <FilterTableEmployeeDemage dataone={dataone} />
              </div>
            </>
          )
          }
        </FormProvider>
      </Container>
    </Page>
  );
}

export default FilterEmployee;
