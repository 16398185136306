/* eslint-disable */
import * as React from 'react';

import MaterialReactTable from 'material-react-table';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { Box, Container, Divider, MenuItem } from '@mui/material';
import { RHFSelect, FormProvider } from '../../../components/hook-form';
import Page from '../../../components/Page';

import HeaderBreadcrumbs from '../../../components/HeaderBreadcrumbs';
import { useEffect } from 'react';
import axiosInstance from '../../../utils/axios';
import { useMemo } from 'react';
import { useState } from 'react';

export default function AlertNewDriver({ allData }) {
  const [Data, setdata] = useState([]);
  const NewSubSchema = Yup.object().shape({});
  const defaultValues = useMemo(
    () => ({
      vehicle: '',
    }),
    []
  );

  const methods = useForm({
    resolver: yupResolver(NewSubSchema),
    defaultValues,
  });

  const {
    handleSubmit,
    control,
    formState: { isSubmitting, errors },
  } = methods;
  const data = [];
  const year = [];
  const currentYear = 2024;
  const startYear = 2017;
  for (var i = 1; i <= 60; i++) {
    data.push({
      name: `week ${i}`,
    });
  }
  for (var i = startYear; i <= currentYear; i++) {
    year.push({
      // id: i,
      name: `${i}`,
    });
  }

  const [selectedItem, setSelectedItem] = useState(null);
  const [selectedItemone, setSelectedItemone] = useState(null);
  const handleOptionChangeone = (event) => {
    setSelectedItemone(event.target.value);
  };
  const handleOptionChange = (event) => {
    setSelectedItem(event.target.value);
  };
  const Getperformancealertnewdriver = () => {
    const url =
      selectedItem === null || selectedItemone === null
        ? 'admin/performancealertnewdriver'
        : `admin/performancealertnewdriver?year=${selectedItemone}&week=${selectedItem}`;

    axiosInstance
      .get(url)
      .then((response) => {
        if (response.data && response.data.employee) {
          setdata(response.data.employee);
        } else {
          console.log('Employee data not found in API response');
        }
      })
      .catch((error) => {
        console.error('Error fetching admin data:', error);
      });
  };

  React.useEffect(() => {
    Getperformancealertnewdriver();
  }, [selectedItem, selectedItemone]);

  const onSubmit = async (data) => {};

  const columns = React.useMemo(
    () => [
      {
        accessorKey: 'employee_name',
        header: 'Delivery Associate',
        size: 20,
      },
      {
        accessorKey: 'seatbelt',
        header: 'Seat Belt',
        size: 20,
        // Cell: ({ row }) => <Box>{row.original?.score_cards?.map((e) => e?.seatbelt_off_rate)}</Box>,
      },
      {
        accessorKey: 'speeding',
        header: 'Speeding',
        size: 20,
        // Cell: ({ row }) => <Box>{row.original?.score_cards?.map((e) => e?.speeding_event_rate)}</Box>,
      },
      {
        accessorKey: 'distractions',
        header: 'Distraction',
        size: 20,
        // Cell: ({ row }) => <Box>{row.original?.score_cards?.map((e) => e?.distractions_rate)}</Box>,
      },
      {
        accessorKey: 'sign',
        header: 'Sign',
        size: 20,
        // Cell: ({ row }) => <Box>{row.original?.score_cards?.map((e) => e?.sign_signal_violations_rate)}</Box>,
      },
      {
        accessorKey: 'pod',
        header: 'POD',
        size: 20,
        // Cell: ({ row }) => <Box>{row.original?.score_cards?.map((e) => e?.swcpod)}</Box>,
      },
      {
        accessorKey: 'cc',
        header: 'CC',
        size: 20,
        // Cell: ({ row }) => <Box>{row.original?.score_cards?.map((e) => e?.swccc)}</Box>,
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const [tableData, setTableData] = React.useState([]);

  const rows = [
    {
      id: 1,
      description: 'Snow',
    },
    {
      id: 2,
      description: 'Snow',
    },
  ];

  React.useEffect(() => {
    setTableData(rows);
  }, []);
  return (
    <Page title="Dashboard">
      <Container maxWidth="lg">
        <HeaderBreadcrumbs
          heading="Performance Alert - New Driver (less than 21 days)		"
          links={[{ name: '', href: '' }]}
          action={<></>}
        />
        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
          <div style={{ display: 'flex', width: '100%' }}>
            <div style={{ flex: '20%', marginRight: '2%' }}>
              <RHFSelect
                name={`week1`}
                label="Week"
                size="large"
                InputLabelProps={{ shrink: true }}
                SelectProps={{ native: false, sx: { textTransform: 'capitalize' } }}
                onChange={handleOptionChange}
              >
                {data?.map((e, i) => (
                  <MenuItem
                    key={e?.id}
                    value={e?.name}
                    sx={{
                      mx: 1,
                      my: 0.5,
                      borderRadius: 0.75,
                      typography: 'body2',
                      textTransform: 'capitalize',
                    }}
                  >
                    {e?.name}
                  </MenuItem>
                ))}
              </RHFSelect>
            </div>
            <div style={{ flex: '20%' }}>
              <RHFSelect
                name={`year`}
                label="Year"
                size="large"
                InputLabelProps={{ shrink: true }}
                SelectProps={{ native: false, sx: { textTransform: 'capitalize' } }}
                onChange={handleOptionChangeone}
              >
                {year?.map((e, i) => (
                  <MenuItem
                    key={e?.name}
                    value={e?.name}
                    sx={{
                      mx: 1,
                      my: 0.5,
                      borderRadius: 0.75,
                      typography: 'body2',
                      textTransform: 'capitalize',
                    }}
                  >
                    {e?.name}
                  </MenuItem>
                ))}
              </RHFSelect>
            </div>
          </div>
        </FormProvider>
        <MaterialReactTable columns={columns} checkboxSelection data={Data || []} />
      </Container>
    </Page>
  );
}
