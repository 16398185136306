
/* eslint-disable */
import * as React from 'react';

import MaterialReactTable from 'material-react-table';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { Box, Button, Container, Divider, MenuItem } from '@mui/material';
import { RHFSelect, FormProvider } from '../../../../components/hook-form';
import Page from '../../../../components/Page';

import HeaderBreadcrumbs from '../../../../components/HeaderBreadcrumbs';
import { useEffect } from 'react';
import axiosInstance from '../../../../utils/axios';
import { useMemo } from 'react';
import { useState } from 'react';
import Iconify from '../../../../components/Iconify';

export default function Fleetstatus({ allData }) {
  

  const [Data, setdata] = useState([]);
  const NewSubSchema = Yup.object().shape({});
  const defaultValues = useMemo(
    () => ({
      vehicle: '',
    }),
    []
  );

  const methods = useForm({
    resolver: yupResolver(NewSubSchema),
    defaultValues,
  });

  const {
    handleSubmit,
    control,
    formState: { isSubmitting, errors },
  } = methods;


 
  const [selectedItem, setSelectedItem] = useState(null);
  const [selectedItemone, setSelectedItemone] = useState(null);
  const [selectedItemtwo, setSelectedItemtwo] = useState(null);
  const handleOptionChangeone = (event) => {
    setSelectedItemone(event.target.value);
    setSelectedItem(null)
    setSelectedItemtwo(null)
  };
  console.log(selectedItemone,'selectedItemone')
  const handleOptionChange = (event) => {
    setSelectedItem(event.target.value);
    setSelectedItemone(null)
    setSelectedItemtwo(null)
  };
  const handleOptionChangetwo = (event) => {
    setSelectedItemtwo(event.target.value);
    setSelectedItemone(null)
    setSelectedItem(null)
  };
  const GetAllAdmin = () => {
    const url = selectedItem   !==null ?
     `admin/fleetdashboard/fleetstatus?status=${selectedItem}` : selectedItemone !==null ?
     `admin/fleetdashboard/fleetstatus?vehicle_type=${selectedItemone}`: selectedItemtwo !==null ? `admin/fleetdashboard/fleetstatus?location=${selectedItemtwo}` : 'admin/fleetdashboard/fleetstatus'
  
  
    axiosInstance
      .get(url)
      .then((response) => {
        if (response.data && response.data.vehicleDetail) {
          setdata(response.data.vehicleDetail);
        } else {
          console.log('Employee data not found in API response');
        }
      })
      .catch((error) => {
        console.error('Error fetching admin data:', error);
      });
  };
  
  React.useEffect(() => {
    GetAllAdmin();
  }, [selectedItem, selectedItemone]);
  const columns = React.useMemo(
    () => [
  

    
      {
        accessorKey: 'vehicle_type',
        header: 'Type',
        size: 20,
      },
      {
        accessorKey: 'location',
        header: 'Location',
        size: 20,
      },
      {
        accessorKey: 'status',
        header: 'Vehicle Status',
        size: 10,
        Cell: ({ row }) =>
            row?.original?.status == 'GROUNDED' ? (
                <Button
                    // fullWidth
                    variant="contained"
                    color="success"
                    endIcon={<Iconify icon={'eva:checkmark-circle-2-fill'} />}
                    // onClick={() => {
                    //     handleApproved(row?.original?.id);
                    // }}
                >
                    GROUNDED
                </Button>
            ) : (
                <Button
                    // fullWidth
                    variant="contained"
                    color="error"
                    endIcon={<Iconify icon={'eva:close-circle-fill'} />}
                    // onClick={() => {
                    //     handleApproved(row?.original?.id);
                    // }}
                >
                    InActive
                </Button>
            ),
    }
     
      
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const onSubmit = async (data) => {};
  const [tableData, setTableData] = React.useState([]);

  const rows = [
    {
      id: 1,
      description: 'Snow',
    },
    {
      id: 2,
      description: 'Snow',
    },
  ];

  React.useEffect(() => {
    setTableData(rows);
  }, []);
  return (
    <Page title="Dashboard">
      <Container maxWidth="lg">
        <HeaderBreadcrumbs heading="Fleet Status" links={[{ name: '', href: '' }]} action={<></>} />
        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
          <div style={{ display: 'flex', width: '100%' }}>
            <div style={{ flex: '20%', marginRight: '2%' }}>
              <RHFSelect
                name={`status`}
                label="Status"
                size="large"
                InputLabelProps={{ shrink: true }}
                SelectProps={{ native: false, sx: { textTransform: 'capitalize' } }}
                onChange={handleOptionChange}
              >
                 <MenuItem
                    value={true}
                    sx={{
                      mx: 1,
                      my: 0.5,
                      borderRadius: 0.75,
                      typography: 'body2',
                      textTransform: 'capitalize',
                    }}
                  >
                   Yes
                  </MenuItem>
                  <MenuItem
                    value={false}
                    sx={{
                      mx: 1,
                      my: 0.5,
                      borderRadius: 0.75,
                      typography: 'body2',
                      textTransform: 'capitalize',
                    }}
                  >
                   No
                  </MenuItem>
              </RHFSelect>
            </div>
            <div style={{ flex: '20%', marginRight: '2%' }}>
              <RHFSelect
                name={`vehicle_type`}
                label="Vehicle Type"
                size="large"
                InputLabelProps={{ shrink: true }}
                SelectProps={{ native: false, sx: { textTransform: 'capitalize' } }}
                onChange={handleOptionChangeone}
              >
               
                  <MenuItem
                    value={true}
                    sx={{
                      mx: 1,
                      my: 0.5,
                      borderRadius: 0.75,
                      typography: 'body2',
                      textTransform: 'capitalize',
                    }}
                  >
                   Yes
                  </MenuItem>
                  <MenuItem
                    value={false}
                    sx={{
                      mx: 1,
                      my: 0.5,
                      borderRadius: 0.75,
                      typography: 'body2',
                      textTransform: 'capitalize',
                    }}
                  >
                   No
                  </MenuItem>
                
              </RHFSelect>
            </div>
            <div style={{ flex: '20%' }}>
              <RHFSelect
                name={`location`}
                label="Location"
                size="large"
                InputLabelProps={{ shrink: true }}
                SelectProps={{ native: false, sx: { textTransform: 'capitalize' } }}
                onChange={handleOptionChangetwo}
              >
               
                  <MenuItem
                    value={true}
                    sx={{
                      mx: 1,
                      my: 0.5,
                      borderRadius: 0.75,
                      typography: 'body2',
                      textTransform: 'capitalize',
                    }}
                  >
                   Yes
                  </MenuItem>
                  <MenuItem
                    value={false}
                    sx={{
                      mx: 1,
                      my: 0.5,
                      borderRadius: 0.75,
                      typography: 'body2',
                      textTransform: 'capitalize',
                    }}
                  >
                   No
                  </MenuItem>
                
              </RHFSelect>
            </div>
          </div>
        </FormProvider>

        <Divider sx={{ mt: 3 }} />
        <MaterialReactTable columns={columns} checkboxSelection data={Data || []} />
      </Container>
    </Page>
  );
}
