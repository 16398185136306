/* eslint-disable */
import * as React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import MaterialReactTable from 'material-react-table';
// import AccordionDetails from '@mui/material/AccordionDetails';
// import TextsmsIcon from '@mui/icons-material/Textsms';
// import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import EditIcon from '@mui/icons-material/Edit';
// import FileDownloadIcon from '@mui/icons-material/FileDownload';
// import SettingsIcon from '@mui/icons-material/Settings';
import * as XLSX from 'xlsx';
import { Delete as DeleteIcon, RemoveRedEye } from '@mui/icons-material';
import { useNavigate, Link as RouterLink } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { Box, IconButton, Container, Button, Modal, Typography, Tooltip } from '@mui/material';
import Page from '../../../components/Page';
// import { PATH_DASHBOARD } from '../../../routes/paths';
// import HeaderBreadcrumbs from 'src/components/HeaderBreadcrumbs';
import HeaderBreadcrumbs from '../../../components/HeaderBreadcrumbs';
// import Button from 'src/theme/overrides/Button';
// import Button from '../../../../theme/overrides/Button';
import Iconify from '../../../components/Iconify';
import axiosInstance from '../../../utils/axios';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  height: '400px',
  overflowY: 'scroll',
  backgroundColor: 'background.paper',
  borderRadius: '10px',
  boxShadow: 24,
  padding: 4,
};
export default function StandupAnnoucment({ standup, data }) {
  const [selectedRowIds, setSelectedRowIds] = React.useState([]);
  console.log(selectedRowIds, 'selectedRowIds');
  const [open, setOpen] = React.useState(false);
  const [datas, setdatas] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleOpenModal2 = (params) => {
    handleOpen();
    axiosInstance
      .get(`admin/standupannouncements/${params}`)
      .then((response) => {
        if (response.data.standupAnnouncement) {
          // console.log(response?.data);
          setdatas(response?.data?.standupAnnouncement);
        }
        // console.log(response?.data.dispatcherAlert, 'resp');
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const handleCloseModal = () => {
    handleClose();
  };

  const handleSendMessage = () => {
    console.log('Selected IDs:', selectedRowIds);
    selectedRowIds?.map((id) => {
      console.log('Selected IDs', id);
    });
  };

  const handleDownload = () => {
    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.json_to_sheet(rows);
    console.log(ws);
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    XLSX.writeFile(wb, 'table-data.xlsx');
  };

  const handleCheckboxChange = (e) => {
    const rowId = e.target.value;
    const isChecked = e.target.checked;
    handleRowSelected(rowId, isChecked);
  };

  const handleRowSelected = (rowId, isChecked) => {
    if (isChecked) {
      setSelectedRowIds((prevIds) => [...prevIds, rowId]);
    } else {
      setSelectedRowIds((prevIds) => prevIds.filter((id) => id !== rowId));
    }
  };
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  // console.log(id);
  const [Data, setdata] = React.useState([]);
  // console.log(Data, 'Data');
//   const idone = localStorage.getItem('e_id');
//   const GetStandup = () => {
//     axiosInstance
//       .get(`admin/standupannouncements`)
//       .then((response) => {
//         if (response.data.standupAnnouncement) {
//           setdata(response?.data?.standupAnnouncement);
//         }
//       })
//       .catch((error) => {
//         console.error(error);
//       });
//   };
//   React.useEffect(() => {
//     GetStandup();
//   }, []);
  const handleOpenModal1 = () => {
    if (selectedRowIds?.length === 0) {
      enqueueSnackbar('Please select rows to send message', {
        variant: 'error',
      });
    } else {
    handleOpen();
    }
  };
  async function handleDelete(rowdata) {
    console.log(rowdata);
    try {
      await axiosInstance.delete(`admin/dispatcherAlerts/${rowdata}`).then((response) => {
        if (response?.data?.message === 'Deleted successfully') {
          enqueueSnackbar(response?.data?.message);
        //   GetStandup();
        }
      });
    } catch (error) {
      enqueueSnackbar(error?.message, {
        variant: 'error',
      });
      console.error(error);
    }
  }
  const columns = React.useMemo(
    () => [
      // {
      //   accessorKey: 'date',
      //   header: 'Date',
      //   size: 20,
      // },
      {
        accessorKey: 'description',
        header: 'Description',
        size: 20,
        Cell: ({ row }) => <Box>{row?.original?.description.slice(0, 50)}</Box>,
      },

      {
        accessorKey: 'actions',
        header: 'Actions',
        size: 100,
        Cell: ({ row }) => (
          <Box
            sx={{
              display: 'flex',
              flexWrap: 'nowrap',
              gap: '5px',
              justifyContent: 'flex-center',
            }}
          >
            <IconButton
              color="primary"
              sx={{
                border: '1px solid',
                borderColor: 'primary.main',
                marginRight: '5px', // Add margin to create a gap
              }}
              onClick={() => {
                navigate(`/dashboard/editstandupannoucment/${row.original.id}`, { state: row.original });
              }}
            >
              <EditIcon />
            </IconButton>
            <IconButton
              color="error"
              sx={{
                border: '1px solid',
                borderColor: 'error.main',
              }}
              onClick={() => {
                handleDelete(row.original.id);
              }}
            >
              <DeleteIcon />
            </IconButton>
            <Tooltip arrow title="View Message">
              <IconButton style={{ marginLeft: '5px' }}>
                <RemoveRedEye onClick={() => handleOpenModal2(row.original.id)} />
              </IconButton>
            </Tooltip>
          </Box>
        ),
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const [tableData, setTableData] = React.useState([]);

  const rows = [
    {
      id: 1,
      description: 'Snow',
    },
    {
      id: 2,
      description: 'Snow',
    },
  ];

  React.useEffect(() => {
    setTableData(Data);
  }, []);
  return (
    <Page title="Dashboard">
      <Container maxWidth="lg">
        <HeaderBreadcrumbs
          heading="StandupAnnoucment"
          links={[{ name: '', href: '' }]}
          action={
            <>
              <Button
                variant="contained"
                startIcon={<Iconify icon="eva:plus-fill" />}
                component={RouterLink}
                to={'/dashboard/addstandupannoucment'}
                // onClick={() => { navigate(`/dashboard/maintenance`) }}
              >
                StandupAnnoucment
              </Button>
            </>
          }
        />
        <MaterialReactTable columns={columns} checkboxSelection data={standup} />
      </Container>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            StandupAnnoucment
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            <p id="modal-description">{datas?.description}</p>
          </Typography>
        </Box>
      </Modal>
    </Page>
  );
}
