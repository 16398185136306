/* eslint-disable */
import React, { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { LoadingButton } from '@mui/lab';
import { useSnackbar } from 'notistack';
import { Card, Grid, Stack, Container ,MenuItem} from '@mui/material';
import { useLocation, useNavigate } from 'react-router';
import { FormProvider, RHFTextField ,RHFSelect} from '../../../components/hook-form';
import axiosInstance from '../../../utils/axios';
import HeaderBreadcrumbs from '../../../components/HeaderBreadcrumbs';

export default function Addmain() {
  const {state} = useLocation()
  const NewSubSchema = Yup.object().shape({
    date: Yup.string().required('Date is required'),
    warranty: Yup.string().required('Warranty is required'),
    body: Yup.string().required('Body is required'),
    week: Yup.number().required('Week is required'),
    year: Yup.number().required('Year is required'),
    engine: Yup.string().required('Engine is required'),
    shop: Yup.string().required('Shop is required'),
    description: Yup.string().required('Description is required'),
    estCost: Yup.number().required('Est. Cost is required'),
  });

  // const { id } = useParams();

  const navigate = useNavigate();
  const [Data, setdata] = useState([]);
  // const { enqueueSnackbar } = useSnackbar();
  const data = [];
  const year = [];
  for (var i = 1; i <= 60; i++) {
      data.push({
          id: i,
          name: `week ${i}`,
      });
  }
  for (var i = Data?.startYear; i <= Data?.currentYear; i++) {
      year.push({
          // id: i,
          name: `${i}`,
      });
  }
  const { enqueueSnackbar } = useSnackbar();
const id = localStorage.getItem("Parent_ID")
  const defaultValues = useMemo(
    () => ({
      date: state?.date || '',
      warranty: state?.warranty || '',
      body: state?.body || '',
      week: state?.week ||'',
      year: state?.year ||'',
      engine: state?.engine || '',
      shop: state?.shop || '',
      description: state?.description || '',
      estCost: state?.estCost || '',
    }),
    []
  );

  const methods = useForm({
    resolver: yupResolver(NewSubSchema),
    defaultValues,
  });

  const {
    handleSubmit,
    formState: { isSubmitting, errors },
  } = methods;

  const onSubmit = async (data) => {
    try {
      const dorm = new FormData();
      dorm.append('date', data?.date);
      dorm.append('vehicle_detail_id', id);
      dorm.append('warranty', data?.warranty);
      dorm.append('body', data?.body);
      dorm.append('week', data?.week);
      dorm.append('year', data?.year);
      dorm.append('engine', data?.engine);
      dorm.append('shop', data?.shop);
      dorm.append('decription', data?.description);
      dorm.append('est_cost', data?.estCost);
    

      //  console.log(user?.id);

      await axiosInstance.post(`admin/repairmaintenances`, dorm).then((response) => {
        
        if (response.data?.message === 'Vehicle Repair Maintenance Added Successfully') {
           enqueueSnackbar('Post Add SuccessFully');
           navigate('/dashboard/repair_damage');
          // reset();
        }
      });
    } catch (error) {
      // enqueueSnackbar(error?.message, {
      // variant: 'error',
      // });
      console.error(error);
    }
  };
  const GetYear = () => {
    axiosInstance
        .get('/admin/years')
        .then((response) => {
            // console.log(response.data)
            if (response?.data) {
                // console.log(response?.data);
                setdata(response?.data);
            }
        })
        .catch((error) => {
            console.error(error);
        });
};
useEffect(() => {
    GetYear();
}, []);
  return (
    <Container maxWidth="sm">
      <HeaderBreadcrumbs heading="Add Repair And Maintenance" links={[{ name: '', href: '' }]} />
      <Card>
        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={1}>
            <Grid item xs={12} md={12}>
              <Card sx={{ p: 3 }}>
                <Stack spacing={3}>
                <RHFSelect
                    name="week"
                    label="Select Week"
                    size="large"
                    InputLabelProps={{ shrink: true }}
                    SelectProps={{ native: false, sx: { textTransform: 'capitalize' } }}
                  >
                    {data?.map((e) => (
                      <MenuItem
                        value={e?.name}
                        sx={{
                          mx: 1,
                          my: 0.5,
                          borderRadius: 0.75,
                          typography: 'body2',
                          textTransform: 'capitalize',
                        }}
                      // onClick={()=>setemID(e?.id)}
                      >
                        {e?.name}
                        {/* hi */}
                      </MenuItem>
                    ))}
                  </RHFSelect>

                  <RHFSelect
                    name="year"
                    label="Select year"
                    size="large"
                    InputLabelProps={{ shrink: true }}
                    SelectProps={{ native: false, sx: { textTransform: 'capitalize' } }}
                  >
                    {year?.map((e) => (
                      <MenuItem
                        value={e?.name}
                        sx={{
                          mx: 1,
                          my: 0.5,
                          borderRadius: 0.75,
                          typography: 'body2',
                          textTransform: 'capitalize',
                        }}
                      // onClick={()=>setemID(e?.id)}
                      >
                        {e?.name}
                        {/* hi */}
                      </MenuItem>
                    ))}
                  </RHFSelect>
                  <RHFTextField name="date" label="Date" type="date" InputLabelProps={{ shrink: true }} />
                  <RHFTextField name="warranty" label="Warranty" InputLabelProps={{ shrink: true }} />
                  <RHFTextField name="body" label="Body" InputLabelProps={{ shrink: true }} />
                  <RHFTextField name="engine" label="Engine" InputLabelProps={{ shrink: true }} />
                  <RHFTextField name="shop" label="Shop" InputLabelProps={{ shrink: true }} />
                  <RHFTextField name="description" label="Description" InputLabelProps={{ shrink: true }} />
                  <RHFTextField name="estCost" label="Est. Cost" InputLabelProps={{ shrink: true }} />
                  <Grid item xs={6} md={6}>
                    <LoadingButton type="submit" variant="contained" size="large" loading={isSubmitting}>
                      Add Repair And Maintenance
                    </LoadingButton>
                  </Grid>
                </Stack>
              </Card>
            </Grid>
          </Grid>
        </FormProvider>
      </Card>
    </Container>
  );
}
