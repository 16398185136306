/* eslint-disable */
import React, { useMemo, useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';
import { useLocation, useNavigate, useParams } from 'react-router';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { LoadingButton } from '@mui/lab';
import { Card, Grid, Stack, Container, MenuItem } from '@mui/material';
import { FormProvider, RHFTextField, RHFSelect } from '../../components/hook-form';
import axiosInstance from '../../utils/axios';
import HeaderBreadcrumbs from '../../components/HeaderBreadcrumbs';

export default function EditAttendance() {
  const navigate = useNavigate();
  const [Data, setdata] = useState([]);
  const [emplyoee, setemployee] = useState([])
  const { enqueueSnackbar } = useSnackbar();
  const data = [];
  const year = [];
  for (var i = 1; i <= 60; i++) {
    data.push({
      id: i,
      name: `week ${i}`,
    });
  }
  for (var i = Data?.startYear; i <= Data?.currentYear; i++) {
    year.push({
      // id: i,
      name: `${i}`,
    });
  }
  const {id} = useParams()
  const NewSubSchema = Yup.object().shape({
    date: Yup.string().required('Date is required'),
    delivery_associate: Yup.string().required('delivery_associate is required'),
    week: Yup.string().required('Week is required'),
    year: Yup.string().required('year is required'),
    time: Yup.string().required('time is required'),

    Issue_type: Yup.string().required('Issue_type is required'),

  });
  const {state} = useLocation()

  const defaultValues = useMemo(
    () => ({
      date: state?.date ||'',
      delivery_associate: state?.delivery_associate ||'',
      week: state?.week ||'',
      year: state?.year ||'',
      time: state?.time ||'',
      Issue_type: state?.Issue_type ||'',

    }),
    []
  );

  const methods = useForm({
    resolver: yupResolver(NewSubSchema),
    defaultValues,
  });

  const {
    handleSubmit,
    formState: { isSubmitting, errors },
  } = methods;

  const idone = localStorage.getItem('e_id');
  const onSubmit = async (data) => {
    console.log(data);
    try {
      const dorm = new FormData();
      dorm.append('date', data?.date);
      dorm.append('employee_id', idone);
      dorm.append('week', data?.week);
      dorm.append('year', data?.year);
      dorm.append('delivery_associate', data?.delivery_associate);
      dorm.append('Issue_type', data?.Issue_type);
      dorm.append('time', data?.time);
      dorm.append('_method', 'PUT');

      //  console.log(user?.id);

      await axiosInstance.post(`/admin/attendances/${id}`, dorm).then((response) => {
        // console.log(response?.data, '=========');
        if (response.data?.attendance) {
          enqueueSnackbar('Edit SuccessFully');
          // reset();
          navigate('/dashboard/consuling');
        }
      });
    } catch (error) {
      // enqueueSnackbar(error?.message, {
      // variant: 'error',
      // });
      console.error(error);
    }
  };
  const GetYear = () => {
    axiosInstance
      .get('/admin/years')
      .then((response) => {
        // console.log(response.data)
        if (response?.data) {
          // console.log(response?.data);
          setdata(response?.data);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const GetEmployee = () => {
    axiosInstance
      .get(`/admin/employees/${idone}`)
      .then((response) => {
        // console.log(response.data?.employee?.name)
        if (response?.data) {
          // console.log(response?.data);
          setemployee(response?.data?.employee);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };
  useEffect(() => {
    GetYear()
    GetEmployee()
  }, []);

  const arr = [
    {
      name: 'Call Outs',
    },
    {
      name: 'No Show',
    },
    {
      name: 'Late',
    },
    {
      name: 'Early Departure',
    },

  ]
  return (
    <Container maxWidth="sm">
      <HeaderBreadcrumbs heading="Edit Attendance" links={[{ name: '', href: '' }]} />
      <Card>
        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={1}>
            <Grid item xs={12} md={12}>
              <Card sx={{ p: 3 }}>
                <Stack spacing={3}>
                  <RHFSelect
                    name="week"
                    label="Select Week"
                    size="large"
                    InputLabelProps={{ shrink: true }}
                    SelectProps={{ native: false, sx: { textTransform: 'capitalize' } }}
                  >
                    {data?.map((e) => (
                      <MenuItem
                        value={e?.name}
                        sx={{
                          mx: 1,
                          my: 0.5,
                          borderRadius: 0.75,
                          typography: 'body2',
                          textTransform: 'capitalize',
                        }}
                      // onClick={()=>setemID(e?.id)}
                      >
                        {e?.name}
                        {/* hi */}
                      </MenuItem>
                    ))}
                  </RHFSelect>

                  <RHFSelect
                    name="year"
                    label="Select year"
                    size="large"
                    InputLabelProps={{ shrink: true }}
                    SelectProps={{ native: false, sx: { textTransform: 'capitalize' } }}
                  >
                    {year?.map((e) => (
                      <MenuItem
                        value={e?.name}
                        sx={{
                          mx: 1,
                          my: 0.5,
                          borderRadius: 0.75,
                          typography: 'body2',
                          textTransform: 'capitalize',
                        }}
                      // onClick={()=>setemID(e?.id)}
                      >
                        {e?.name}
                        {/* hi */}
                      </MenuItem>
                    ))}
                  </RHFSelect>
                  <RHFSelect
                    name="delivery_associate"
                    label="Delivery Associate"
                    size="large"
                    InputLabelProps={{ shrink: true }}
                    SelectProps={{ native: false, sx: { textTransform: 'capitalize' } }}
                  >
               
                      <MenuItem
                        value={emplyoee?.name}
                        sx={{
                          mx: 1,
                          my: 0.5,
                          borderRadius: 0.75,
                          typography: 'body2',
                          textTransform: 'capitalize',
                        }}
                      // onClick={()=>setemID(e?.id)}
                      >
                        {emplyoee?.name}
                        {/* hi */}
                      </MenuItem>
            
                  </RHFSelect>
                  <RHFTextField name="date" label="Date" type="date" InputLabelProps={{ shrink: true }} />
                  <RHFTextField name="time" label="Time" type='time' InputLabelProps={{ shrink: true }} />



                  <RHFSelect
                    name="Issue_type"
                    label="Issue Type"
                    size="large"
                    InputLabelProps={{ shrink: true }}
                    SelectProps={{ native: false, sx: { textTransform: 'capitalize' } }}
                  >
                    {arr?.map((e) => (
                      <MenuItem
                        value={e?.name}
                        sx={{
                          mx: 1,
                          my: 0.5,
                          borderRadius: 0.75,
                          typography: 'body2',
                          textTransform: 'capitalize',
                        }}
                      // onClick={()=>setemID(e?.id)}
                      >
                        {e?.name}
                        {/* hi */}
                      </MenuItem>
                    ))}
                  </RHFSelect>
                  <Grid item xs={6} md={6}>
                    <LoadingButton type="submit" variant="contained" size="large" loading={isSubmitting}>
                      Edit Attendance
                    </LoadingButton>
                  </Grid>
                </Stack>
              </Card>
            </Grid>
          </Grid>
        </FormProvider>
      </Card>
    </Container>
  );
}
