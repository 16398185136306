/* eslint-disable */
import * as React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import MaterialReactTable from 'material-react-table';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useSnackbar } from 'notistack';
import EditIcon from '@mui/icons-material/Edit';
import { Delete as DeleteIcon } from '@mui/icons-material';
import { useNavigate, Link as RouterLink } from 'react-router-dom';
// import {Container} from '@mui/material'
// import { PATH_DASHBOARD } from '../../../routes/paths';

import { Box, IconButton, Typography, Button, Paper, Container } from '@mui/material';
import axiosInstance from '../../../utils/axios';
import Page from '../../../components/Page';
// import { PATH_DASHBOARD } from '../../routes/paths';

import Iconify from '../../../components/Iconify';
import HeaderBreadcrumbs from '../../../components/HeaderBreadcrumbs';

import Dia from './Dia';

export default function GasCard() {
  const { enqueueSnackbar } = useSnackbar();
  const [loader, setloader] = React.useState(false);

  const navigate = useNavigate();
  const handleCloseModal1 = () => {
    setIsModalOpen1(false)
};
  const [isModalOpen1, setIsModalOpen1] = React.useState(false);

  const handleOpenModal1 = () => {
    console.log('');
    // setCommentView(report?.report);
    setIsModalOpen1(true);
  };
  const [Data, setdata] = React.useState([]);
  // console.log(Data, 'Data');
  const GetAllAdmin = () => {
    setloader(true)
    axiosInstance
      .get('admin/gascards')
      .then((response) => {
        if (response.data.gasCard) {
          // console.log(response?.data);
          setdata(response?.data?.gasCard);
          setloader(false)
        }
        console.log(response, 'resp');
      })
      .catch((error) => {
        console.error(error);
      });
  };
  React.useEffect(() => {
    GetAllAdmin();
  }, []);
  async function handleDelete(rowdata) {
    console.log(rowdata);
    try {
      await axiosInstance.delete(`admin/gascards/${rowdata}`).then((response) => {
        if (response?.data?.message === 'Deleted successfully') {
          enqueueSnackbar(response?.data?.message);
          GetAllAdmin();
        }
      });
    } catch (error) {
      enqueueSnackbar(error?.message, {
        variant: 'error',
      });
      console.error(error);
    }
  }
  const columns = React.useMemo(
    () => [
      {
        accessorKey: 'id',
        header: 'ID',
        size: 10,
      },
      {
        accessorKey: 'gas_card_id',
        header: 'Gas Card Id',
        size: 20,
      },
      {
        accessorKey: 'card_tied_to',
        header: 'Card Tied To',
        size: 20,
      },
      {
        accessorKey: 'expiration',
        header: 'Expiration',
        size: 20,
      },
  
      {
        accessorKey: 'Status',
        header: 'Status',
        size: 10,
        Cell: ({ row }) => (
            row.original.status==='Active' ?
          <Button
            fullWidth
            variant="contained"
            color="success"
            endIcon={<Iconify icon={'eva:checkmark-circle-2-fill'} />}
          >
            Active
          </Button>
          :<Button
          fullWidth
          variant="contained"
          color="error"
          endIcon={<Iconify icon={'eva:close-circle-fill'} />}
          onClick={() => {
            // handleApproved(row?.original?.id,1);
          }}
        >
          Inactive
        </Button>
        ),
      },
  
      {
        accessorKey: 'actions',
        header: 'Actions',
        size: 10,
        Cell: ({ row }) => (
          <Box>
         
            <IconButton
              color="error"
              sx={{
                border: '1px solid',
                borderColor: 'error.main',
              }}
              onClick={() => {
                handleDelete(row.original.id);
              }}
            >
              <DeleteIcon />
            </IconButton>
          </Box>
        ),
      },
    ],
    []
  );
  
  const data = [
    {
      id: 1,
      associateName: 'John Doe',
      transporterID: '12345',
      driverID: '67890',
      phoneNumber: '123-456-7890',
      email: 'john@example.com',
      startDate: '2022-01-01',
      status: 'Active',
      CDV: 'Yes',
      stepVan: 'No',
      electricVehicle: 'Yes',
      preferredVehicle1: 'Toyota Camry',
      preferredVehicle2: 'Honda Accord',
      preferredVehicle3: 'Tesla Model S',
    },
    {
      id: 2,
      associateName: 'Jane Smith',
      transporterID: '54321',
      driverID: '09876',
      phoneNumber: '987-654-3210',
      email: 'jane@example.com',
      startDate: '2022-02-01',
      status: 'Inactive',
      CDV: 'No',
      stepVan: 'Yes',
      electricVehicle: 'No',
      preferredVehicle1: 'Ford Fusion',
      preferredVehicle2: 'Chevrolet Malibu',
      preferredVehicle3: 'N/A',
    },
    // Add more data as needed
  ];
  
  const [tableData, setTableData] = React.useState([]);


  React.useEffect(() => {
    setTableData(data);
  }, []);

  return (
<Page title="Gas Card">
  <Container maxWidth="lg">
    <HeaderBreadcrumbs
      heading="Gas Card"
      links={[{ name: '', href: '' }]}
      action={
        <>
          <Button
            variant="contained"
            startIcon={<Iconify icon="eva:plus-fill" />}
            component={RouterLink}
            to={''}
            onClick={handleOpenModal1}
            style={{ marginRight: '8px' }} // Add margin to the right for spacing
          >
            Import
          </Button>
{/*           
          <Button
            variant="contained"
            startIcon={<Iconify icon="eva:plus-fill" />}
            component={RouterLink}
            to={PATH_DASHBOARD.element.addelement}
          >
            Vehicle
          </Button> */}
        </>
      }
    />
    <MaterialReactTable
      columns={columns}
      state={{showSkeletons:loader}}
      data={Data}
      enableRowActions={false} // Disable default row actions
    />
  </Container>
<Dia handleCloseModal1={handleCloseModal1} isModalOpen1={isModalOpen1} GetAllAdmin={GetAllAdmin}/>

</Page>

  );
}
