/* eslint-disable */
import React, { useMemo, useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { styled } from '@mui/material/styles';
import * as Yup from 'yup';
import makeAnimates from 'react-select/animated';
import { LoadingButton } from '@mui/lab';
import { Card, Grid, Stack, Container, MenuItem, Button, Typography, Box, TextField } from '@mui/material';
import { FormProvider, RHFTextField, RHFSelect } from '../../../../components/hook-form';
import axiosInstance from '../../../../utils/axios';
import Select from 'react-select';
import HeaderBreadcrumbs from '../../../../components/HeaderBreadcrumbs';

const LabelStyle = styled(Typography)(({ theme }) => ({
  ...theme.typography.subtitle2,
  color: theme.palette.text.secondary,
  marginBottom: theme.spacing(1),
}));
export default function EditStandy() {
  const { state } = useLocation();
  const { id } = useParams();
  const navigate = useNavigate();
  const [Data, setdata] = useState([]);
  const [Device, setDevice] = useState([]);
  const [emplyoee, setemployee] = useState([]);
  const [vehcile, setvehicle] = useState([]);
  const { enqueueSnackbar } = useSnackbar();

  // console.log(Data, 'state');
  const NewSubSchema = Yup.object().shape({
    delivery_associate: Yup.array().min(1, 'delivery_associate is required').required('delivery_associate is required'),
    date: Yup.string().required('date is required'),
  });

  const defaultValues = useMemo(
    () => ({
      delivery_associate: '',
      date: '',
    }),
    []
  );

  const methods = useForm({
    resolver: yupResolver(NewSubSchema),
    defaultValues,
  });

  const {
    handleSubmit,
    watch,
    reset,
    control,
    setValue,
    formState: { isSubmitting, errors },
  } = methods;

  const value = watch();


  useEffect(() => {
    if (Data) {
      reset(defaultValues);
    }
  }, [Data, reset, defaultValues]);
  // const idone = localStorage.getItem('e_id');
  const handleAdd = () => {
    console.log(value, 'values');
    if (value.roster.length < 4) {
      setValue('roster', [...value.roster, '']);
    }
  };

  const handleRemove = (index) => {
    // eslint-disable-next-line prefer-const
    let la = value.roster;
    la.splice(index, 1);
    setValue('roster', la);
  };

  const onSubmit = async (data) => {
    console.log(data);
    try {
      const dorm = new FormData();
      data.roster.forEach((item, index) => {
        // dorm.append(`device_id1[${index}]`, item?.device_id1);
        dorm.append(`employee_id[${index}]`, item.delivery_associate);
        // dorm.append(`vehicle_detail_id[${index}]`, item?.vehicle);
        // dorm.append(`device_id2[${index}]`, item?.device_id2);
        // dorm.append(`route[${index}]`, item?.route);
        // dorm.append(`status[${index}]`, item?.status);
        dorm.append(`date[${index}]`, item?.date);
        // dorm.append(`message[${index}]`, item?.message);
      });
      dorm.append(`_method`, 'PUT');
      dorm.append(`stand_by_associate_id[0]`, id);

      //  console.log(user?.id);

      await axiosInstance.post(`/admin/standbyassociates/${id}`, dorm).then((response) => {
        // console.log(response?.data, '=========');
        if (response.data?.message) {
          enqueueSnackbar(response.data?.message);
          // reset();
          navigate('/dashboard/home');
        }
      });
    } catch (error) {
      // enqueueSnackbar(error?.message, {
      // variant: 'error',
      // });
      console.error(error);
    }
  };

  const GetEmployee = () => {
    axiosInstance
      .get(`/admin/employees`)
      .then((response) => {
        // console.log(response.data?.employee?.name)
        if (response?.data) {
          // console.log(response?.data);
          setemployee(response?.data?.employee);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };
  const GetDevice = () => {
    axiosInstance
      .get(`/admin/devices`)
      .then((response) => {
        // console.log(response.data?.employee?.name)
        if (response?.data) {
          // console.log(response?.data);
          setDevice(response?.data?.device);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const Vehicle = () => {
    axiosInstance
      .get(`/admin/vehicledetails`)
      .then((response) => {
        // console.log(response.data?.employee?.name)
        if (response?.data) {
          // console.log(response?.data);
          setvehicle(response?.data?.vehicleDetail);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };
  const HandleDate = (e) => {
    axiosInstance
      .get(`/admin/standbyassociates?date=${e}`)
      .then((response) => {
        // console.log(response.data?.employee?.name)
        if (response?.data?.standByAssociate) {
          // console.log(response?.data);
          setdata(response?.data?.standByAssociate);
          console.log(response?.data?.standByAssociate, 'response?.data?.standByAssociate');
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };
  useEffect(() => {
    GetEmployee();
    Vehicle();
    GetDevice();
  }, []);

  const arr = [
    {
      name: 'OnTime',
    },
    {
      name: 'Late',
    },
  ];
  return (
    <Container maxWidth="sm">
      <HeaderBreadcrumbs heading="Edit StandBy" links={[{ name: '', href: '' }]} />
      <Card>
        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={1}>
            <Grid item xs={12} md={12}>
              <Card sx={{ p: 3, height: '300px' }}>
                <Stack spacing={1.5}>
                  <Box sx={{ marginBottom: 2 }}>
                    {/* <Stack spacing={3} sx={{ margin: '5px' }}> */}
                    <Controller
                      name="delivery_associate"
                      control={control}
                      render={({ field }) => (
                        <Select
                          {...field}
                          components={animatedComponents}
                          isMulti
                          placeholder="delivery_associate"
                          options={options}
                          onChange={(e) => {
                            field.onChange(e);
                          }}
                        />
                      )}
                    />
                    {errors.delivery_associate && (
                      <LabelStyle style={{ fontSize: '12px', color: 'red', fontWeight: 300, marginTop: '5px' }}>
                        {errors.delivery_associate.message}
                      </LabelStyle>
                    )}
                    {/* </Stack> */}
                  </Box>

                  <Box sx={{ marginBottom: 2 }}>
                    <RHFTextField name="date" label="Date" type="date" InputLabelProps={{ shrink: true }} />
                  </Box>

                  <Grid item xs={12} md={12}>
                    <LoadingButton type="submit" variant="contained" size="large" loading={isSubmitting}>
                      Edit Stand By
                    </LoadingButton>
                  </Grid>
                </Stack>
              </Card>
            </Grid>
          </Grid>
        </FormProvider>
      </Card>
    </Container>
  );
}
