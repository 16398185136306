/* eslint-disable */
import * as React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import MaterialReactTable from 'material-react-table';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useSnackbar } from 'notistack';
import EditIcon from '@mui/icons-material/Edit';
import { Delete as DeleteIcon } from '@mui/icons-material';
import { useNavigate, Link as RouterLink } from 'react-router-dom';
// import {Container} from '@mui/material'
// import { PATH_DASHBOARD } from '../../../routes/paths';

import { Box, IconButton, Typography, Button, Paper, Container } from '@mui/material';
import axiosInstance from '../../../utils/axios';
import Page from '../../../components/Page';
// import { PATH_DASHBOARD } from '../../routes/paths';

import Iconify from '../../../components/Iconify';
import HeaderBreadcrumbs from '../../../components/HeaderBreadcrumbs';

import Dia from './Dia';

export default function Pdf() {
  const { enqueueSnackbar } = useSnackbar();

  const navigate = useNavigate();
  const handleCloseModal1 = () => {
    setIsModalOpen1(false)
};
  const [isModalOpen1, setIsModalOpen1] = React.useState(false);

  const handleOpenModal1 = () => {
    console.log('');
    // setCommentView(report?.report);
    setIsModalOpen1(true);
  };
  const [Data, setdata] = React.useState([]);
  const [loader, setloader] = React.useState(false);
  // console.log(Data, 'Data');
  const GetAllAdmin = () => {
    setloader(true)
    axiosInstance
      .get('admin/customerfeedbackextracts')
      .then((response) => {
        if (response.data.customerFeedbackExtracts) {
          // console.log(response?.data);
          setdata(response?.data?.customerFeedbackExtracts);
          setloader(false)
        }
        console.log(response, 'resp');
      })
      .catch((error) => {
        console.error(error);
        setloader(false)
      });
  };
  React.useEffect(() => {
    GetAllAdmin();
  }, []);
  async function handleDelete(rowdata) {
    // console.log(rowdata);
    try {
      await axiosInstance.delete(`admin/customerfeedbackextracts/${rowdata}`).then((response) => {
        if (response?.data?.message === 'Deleted successfully') {
          enqueueSnackbar(response?.data?.message);
          GetAllAdmin();
        }
      });
    } catch (error) {
      enqueueSnackbar(error?.message, {
        variant: 'error',
      });
      console.error(error);
    }
  }
  const columns = React.useMemo(
    () =>[
        {
          accessorKey: 'id',
          header: 'ID',
          size: 10,
        },
        {
          accessorKey: 'transporterId',
          header: 'Transporter ID',
          size: 20,
        },
        {
          accessorKey: 'rank',
          header: 'Rank',
          size: 20,
        },
        {
          accessorKey: 'transporter_id',
          header: 'Transporter ID',
          size: 20,
        },
        {
          accessorKey: 'da_name',
          header: 'DA Name',
          size: 20,
        },
        {
          accessorKey: 'da_tier',
          header: 'DA Tier',
          size: 20,
        },
        {
          accessorKey: 'cdf_score',
          header: 'CDF Score',
          size: 20,
        },
        {
          accessorKey: 'no_Feedack',
          header: 'No Feedback',
          size: 20,
        },
        {
          accessorKey: 'delivery_was_great',
          header: 'Delivery Was Great',
          size: 20,
        },
        {
          accessorKey: 'respectful_of_property',
          header: 'Respectful of Property',
          size: 20,
        },
        {
          accessorKey: 'followed_instructions',
          header: 'Followed Instructions',
          size: 20,
        },
        {
          accessorKey: 'friendly',
          header: 'Friendly',
          size: 20,
        },
        {
          accessorKey: 'above_and_beyond',
          header: 'Above and Beyond',
          size: 20,
        },
        {
          accessorKey: 'delivered_with_care',
          header: 'Delivered With Care',
          size: 20,
        },
        {
          accessorKey: 'thank_my_driver',
          header: 'Thank My Driver',
          size: 20,
        },
        {
          accessorKey: 'delivery_was_not_so_great',
          header: 'Delivery Was Not So Great',
          size: 20,
        },
        {
          accessorKey: 'da_mishandled_package',
          header: 'DA Mishandled Package',
          size: 20,
        },
        {
          accessorKey: 'da_was_unprofessional',
          header: 'DA Was Unprofessional',
          size: 20,
        },
        {
          accessorKey: 'da_did_not_follow_my_delivery_instructions',
          header: 'DA Did Not Follow My Delivery Instructions',
          size: 20,
        },
        {
          accessorKey: 'delivered_to_wrong_address',
          header: 'Delivered to Wrong Address',
          size: 20,
        },
        {
          accessorKey: 'never_received_delivery',
          header: 'Never Received Delivery',
          size: 20,
        },
        {
          accessorKey: 'on_time',
          header: 'On Time',
          size: 20,
        },
        {
          accessorKey: 'late_delivery',
          header: 'Late Delivery',
          size: 20,
        },
        {
          accessorKey: 'item_damaged',
          header: 'Item Damaged',
          size: 20,
        },
        {
            accessorKey: 'actions',
            header: 'Actions',
            size: 10,
            Cell: ({ row }) => (
              <Box>
             
                <IconButton
                  color="error"
                  sx={{
                    border: '1px solid',
                    borderColor: 'error.main',
                  }}
                  onClick={() => {
                    handleDelete(row.original.id);
                  }}
                >
                  <DeleteIcon />
                </IconButton>
              </Box>
            ),
          },
      ]
,      
    []
  );
  
  const data = [
    {
      id: 1,
      associateName: 'John Doe',
      transporterID: '12345',
      driverID: '67890',
      phoneNumber: '123-456-7890',
      email: 'john@example.com',
      startDate: '2022-01-01',
      status: 'Active',
      CDV: 'Yes',
      stepVan: 'No',
      electricVehicle: 'Yes',
      preferredVehicle1: 'Toyota Camry',
      preferredVehicle2: 'Honda Accord',
      preferredVehicle3: 'Tesla Model S',
    },
    {
      id: 2,
      associateName: 'Jane Smith',
      transporterID: '54321',
      driverID: '09876',
      phoneNumber: '987-654-3210',
      email: 'jane@example.com',
      startDate: '2022-02-01',
      status: 'Inactive',
      CDV: 'No',
      stepVan: 'Yes',
      electricVehicle: 'No',
      preferredVehicle1: 'Ford Fusion',
      preferredVehicle2: 'Chevrolet Malibu',
      preferredVehicle3: 'N/A',
    },
    // Add more data as needed
  ];
  
  const [tableData, setTableData] = React.useState([]);


  React.useEffect(() => {
    setTableData(data);
  }, []);

  return (
<Page title="Customer FeedBack">
  <Container maxWidth="lg">
    <HeaderBreadcrumbs
      heading="Customer FeedBack"
      links={[{ name: '', href: '' }]}
      action={
        <>
          <Button
            variant="contained"
            startIcon={<Iconify icon="eva:plus-fill" />}
            component={RouterLink}
            to={''}
            onClick={handleOpenModal1}
            style={{ marginRight: '8px' }} // Add margin to the right for spacing
          >
            Import Pdf
          </Button>
{/*           
          <Button
            variant="contained"
            startIcon={<Iconify icon="eva:plus-fill" />}
            component={RouterLink}
            to={PATH_DASHBOARD.element.addelement}
          >
            Vehicle
          </Button> */}
        </>
      }
    />
    <MaterialReactTable
    state={{loader ,showSkeletons:loader}}
      columns={columns}
      data={Data}
      enableRowActions={false}
    />
  </Container>
<Dia handleCloseModal1={handleCloseModal1} isModalOpen1={isModalOpen1} GetAllAdmin={GetAllAdmin}/>

</Page>

  );
}
