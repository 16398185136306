/* eslint-disable */
import * as React from 'react';

import MaterialReactTable from 'material-react-table';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { Box, Container, Divider, MenuItem } from '@mui/material';
import { RHFSelect, FormProvider } from '../../../components/hook-form';
import Page from '../../../components/Page';

import HeaderBreadcrumbs from '../../../components/HeaderBreadcrumbs';
import { useEffect } from 'react';
import axiosInstance from '../../../utils/axios';
import { useMemo } from 'react';
import { useState } from 'react';

export default function Companylevel({ allData }) {
  const [Data, setdata] = useState([]);
  const NewSubSchema = Yup.object().shape({});
  const defaultValues = useMemo(
    () => ({
      vehicle: '',
    }),
    []
  );

  const methods = useForm({
    resolver: yupResolver(NewSubSchema),
    defaultValues,
  });

  const {
    handleSubmit,
    control,
    formState: { isSubmitting, errors },
  } = methods;
  // const GetYear = () => {
  //   axiosInstance
  //     .get('/admin/years')
  //     .then((response) => {
  //       // console.log(response.data)
  //       if (response?.data) {
  //         // console.log(response?.data);
  //         setdata(response?.data);
  //       }
  //     })
  //     .catch((error) => {
  //       console.error(error);
  //     });
  // };
  // const data = [];
  // const year = [];
  // for (var i = 1; i <= 60; i++) {
  //   data.push({
  //     name: `week ${i}`,
  //   });
  // }
  // for (var i = Data?.startYear; i <= Data?.currentYear; i++) {
  //   year.push({
  //     // id: i,
  //     name: `${i}`,
  //   });
  // }
  const GetRatting = () => {
 
  
    axiosInstance
      .get('admin/ratings')
      .then((response) => {
        if (response.data ) {
          setdata(response.data.ratings);
        } else {
          console.log('Employee data not found in API response');
        }
      })
      .catch((error) => {
        console.error('Error fetching admin data:', error);
      });
  };
  useEffect(() => {

    GetRatting();
  }, []);
  const columns = React.useMemo(
    () => [
  

      {
        accessorKey: 'week',
        header: 'Week',
        size: 20,
        // Cell: ({ row }) => <Box>{row.original?.score_cards.map((e) => e?.week)}</Box>,
      },
      {
        accessorKey: 'overall_standing',
        header: 'OverAll',
        size: 20,
        // Cell: ({ row }) => <Box>{row.original?.score_cards?.map((e) => e?.overall_tiers)}</Box>,
      },
      {
        accessorKey: 'safety_and_compliance',
        header: 'Safety',
        size: 20,
      },
      {
        accessorKey: 'team',
        header: 'Team',
        size: 20,
      },
      {
        accessorKey: 'quality',
        header: 'Quality',
        size: 20,
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const [tableData, setTableData] = React.useState([]);
  // const onSubmit = async (data) => {};

  const rows = [
    {
      id: 1,
      description: 'Snow',
    },
    {
      id: 2,
      description: 'Snow',
    },
  ];

  React.useEffect(() => {
    setTableData(rows);
  }, []);
  return (
    <Page title="Dashboard">
      <Container maxWidth="lg">
        <Divider sx={ {mt: 2 }} />
        <HeaderBreadcrumbs heading="Company Level Metrics" links={[{ name: '', href: '' }]} action={<></>} />
        {/* <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}> */}
          {/* <div style={{ display: 'flex', width: '100%' }}>
            <div style={{ flex: '20%', marginRight: '2%' }}>
              <RHFSelect
                name={`week1`}
                label="Week"
                size="large"
                InputLabelProps={{ shrink: true }}
                SelectProps={{ native: false, sx: { textTransform: 'capitalize' } }}
              >
                {data?.map((e, i) => (
                  <MenuItem
                    key={e?.id}
                    value={e?.name}
                    sx={{
                      mx: 1,
                      my: 0.5,
                      borderRadius: 0.75,
                      typography: 'body2',
                      textTransform: 'capitalize',
                    }}
                  >
                    {e?.name}
                  </MenuItem>
                ))}
              </RHFSelect>
            </div>
            <div style={{ flex: '20%' }}>
              <RHFSelect
                name={`year`}
                label="Year"
                size="large"
                InputLabelProps={{ shrink: true }}
                SelectProps={{ native: false, sx: { textTransform: 'capitalize' } }}
              >
                {year?.map((e, i) => (
                  <MenuItem
                    key={e?.name}
                    value={e?.name}
                    sx={{
                      mx: 1,
                      my: 0.5,
                      borderRadius: 0.75,
                      typography: 'body2',
                      textTransform: 'capitalize',
                    }}
                  >
                    {e?.name}
                  </MenuItem>
                ))}
              </RHFSelect>
            </div>
          </div> */}
        {/* </FormProvider> */}

        <Divider sx={{ mt: 3 }} />
        <MaterialReactTable columns={columns} checkboxSelection data={Data || []} />
      </Container>
    </Page>
  );
}
