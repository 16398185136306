/* eslint-disable no-unused-vars */
import { Suspense, lazy } from 'react';
import { Navigate, useRoutes, useLocation } from 'react-router-dom';
import DashboardLayout from '../layouts/dashboard';
import { PATH_AFTER_LOGIN } from '../config';
import { RegisterForm } from '../sections/auth/register';
import LoadingScreen from '../components/LoadingScreen';
import Addmain from '../pages/dashboard/Company/Addmaintenance';
import EditDamage from '../pages/dashboard/Company/EditDemage';
import Employee from '../pages/Employee/Employee';
import Device from '../pages/dashboard/Device/Device';
import GasCard from '../pages/dashboard/GasCard/GasCard';
import EmployeeCon from '../pages/Employee/EmployeeCon';
import Filter from '../pages/dashboard/Filter';
import Pdf from '../pages/dashboard/Pdf/Pdf';
import PdfPod from '../pages/dashboard/Pdf/Pod Report/Pdf';
import PdfScore from '../pages/dashboard/Pdf/Score Card/Pdf';
import Addinjury from '../pages/Employee/Addinjury';
import Editinjury from '../pages/Employee/Editinjury';
import AddEslaction from '../pages/Employee/AddEslaction';
import EditEslaction from '../pages/Employee/EditEslaction';
import AddAttendance from '../pages/Employee/AddAttendance';
import EditAttendance from '../pages/Employee/EditAttendance';
import Rostered from '../pages/dashboard/CustomTable/TableThree/Rostered_Associate';
import Standy from '../pages/dashboard/CustomTable/TableFour/Standy';
import AddStandy from '../pages/dashboard/CustomTable/TableFour/AddStandy';
import EditStandy from '../pages/dashboard/CustomTable/TableFour/EditStandy';
import AddRoster from '../pages/dashboard/CustomTable/TableThree/AddRoster';
import EditRoster from '../pages/dashboard/CustomTable/TableThree/EditRoster';
import StandupAnnoucment from '../pages/dashboard/DailyUpdate/StandupAnnoucment';
import Message from '../pages/dashboard/DailyUpdate/Message';
import ManagerDailyAlert from '../pages/dashboard/DailyUpdate/ManagerDailyAlert';
import DispatcherAlert from '../pages/dashboard/DailyUpdate/DispatcherAlert';
import DailyPerfomance from '../pages/dashboard/DailyUpdate/DailyPerfomance';
import AddManager from '../pages/dashboard/DailyUpdate/AddManger';
import EditManager from '../pages/dashboard/DailyUpdate/EditManager';
import AddDispatch from '../pages/dashboard/DailyUpdate/AddDispatch';
import EditDispatch from '../pages/dashboard/DailyUpdate/EditDispatch';
import AddStandup from '../pages/dashboard/DailyUpdate/AddStandup';
import EditStandup from '../pages/dashboard/DailyUpdate/EditStandup';
import AddDailyPerfomance from '../pages/dashboard/DailyUpdate/AddDaily';
import EditDailyPerfomance from '../pages/dashboard/DailyUpdate/EditDaily';
import EndOfDay from '../pages/dashboard/DailyUpdate/End_Of_Day';
import AddEndOfDay from '../pages/dashboard/DailyUpdate/AddEnd_Of_Day';
import EditEndOfDay from '../pages/dashboard/DailyUpdate/EditEnd_Of_Day';
import Perfomance from '../pages/dashboard/Perfomance_Dashboard';
import Fleet from '../pages/dashboard/Fleet_Dashboard';
import { PATH_DASHBOARD } from './paths';

// import AddDamage from 'src/pages/dashboard/Company/AddDemage';
// import Repair_And_Demage from 'src/pages/dashboard/Company/Repair_And_Demage';


const Loadable = (Component) => (props) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { pathname } = useLocation();
  return (
    <Suspense fallback={<LoadingScreen isDashboard={pathname.includes('/dashboard')} />}>
      <Component {...props} />
      {/* <Dorm/> */}
    </Suspense>
  );
};

export default function Router() {
  return useRoutes([
    // { path: '/', element: <Home/> },
    // { path: '/login/admin', element: <Login/> },
    { path: '/', element: <Login/> },
    { path: '/codemail', element: <CodeMail /> },
    { path: '/login/company', element: <CompanyLogin/> },
    { path: '/login/clinic', element: <ClinicLogin/> },
    { path: '/forget/:id', element: <Register/> },
    { path: '/forgeth', element: <RegisterForm/> },
    { path: '/reset-passsword', element: <ResetPassword usertype={'company'}/> },
    { path: '/clinic-reset-passsword', element: <ResetPassword usertype={'clinic'}/> },
    {
          path: 'dashboard',
          element: (
              <DashboardLayout />
          ),
          children: [

            { element: <Navigate to={PATH_AFTER_LOGIN} replace />, index: true },
            { path: 'home', element: <GeneralAnalytics /> },
            { path: 'perfomance', element: <Perfomance /> },
            { path: 'home/:id', element: <GeneralEdit /> },
            { path: 'sub-admin', element: <Dorm /> },
            { path: 'edit_sub_admin/:id', element: <EditDorm /> },
            { path: 'add_sub_admin', element: <AddDorm /> },
            { path: 'element', element: <Element /> },
            { path: 'device', element: <Device /> },
            { path: 'gascard', element: <GasCard /> },
            { path: 'employee', element: <Employee /> },
            { path: 'rostered', element: <Rostered /> },
            { path: 'endofday', element: <EndOfDay /> },
            { path: 'addendofday', element: <AddEndOfDay /> },
            { path: 'editendofday/:id', element: <EditEndOfDay /> },
            { path: 'dailyperfomance', element: <DailyPerfomance /> },
            { path: 'fleet', element: <Fleet /> },
            { path: 'adddailyperfomance', element: <AddDailyPerfomance /> },
            { path: 'editper/:id', element: <EditDailyPerfomance /> },
            { path: 'dispatcheralert', element: <DispatcherAlert /> },
            { path: 'adddispatcheralert', element: <AddDispatch /> },
            { path: 'editdispatcheralert/:id', element: <EditDispatch /> },
            { path: 'managerdailyalert', element: <ManagerDailyAlert /> },
            { path: 'addmanager', element: <AddManager /> },
            { path: 'editmanager/:id', element: <EditManager /> },
            { path: 'message', element: <Message /> },
            { path: 'standupannoucment', element: <StandupAnnoucment /> },
            { path: 'addstandupannoucment', element: <AddStandup /> },
            { path: 'editstandupannoucment/:id', element: <EditStandup /> },
            { path: 'editemployee/:id', element: <EditEmployee /> },
            { path: 'standy', element: <Standy /> },
            { path: 'addstandy', element: <AddStandy /> },
            { path: 'editstandy/:id', element: <EditStandy /> },
            { path: 'addroster', element: <AddRoster /> },
            { path: 'editroster/:id', element: <EditRoster /> },
            { path: 'filter', element: <Filter /> },
            { path: 'repair_damage', element: <RepairAndDemage /> },
            { path: 'consuling', element: <EmployeeCon /> },
            { path: 'addattendance', element: <AddAttendance /> },
            { path: 'editattendance/:id', element: <EditAttendance /> },
            { path: 'editelement/:id', element: <EditElement /> },
            { path: 'maintenance/:id', element: <Main /> },
            { path: 'maintenance', element: <Addmain /> },
            { path: 'pdf', element: <Pdf /> },
            { path: 'pod_report', element: <PdfPod /> },
            { path: 'score_card', element: <PdfScore /> },
            { path: 'demage/:id', element: <AddDamage /> },
            { path: 'vdemage/:id', element: <EditDamage /> },
            { path: 'comment/:id', element: <Comment /> },
            { path: 'companyProfile', element: <CompanyProfile /> },
            { path: 'addelement', element: <AddElement /> },
            { path: 'addemployee', element: <AddEmployee /> },
            { path: 'addinjury', element: <Addinjury /> },
            { path: 'addeslaction', element: <AddEslaction /> },
            { path: 'editeslaction/:id', element: <EditEslaction /> },
            { path: 'editinjury/:id', element: <Editinjury /> },
            { path: 'safety', element: <Rarity /> },
            { path: 'editrarity/:id', element: <EditRarity /> },
            { path: 'addrarity', element: <AddRarity /> },
             { path: 'quality', element: <Spell /> },
             { path: 'addspell', element: <AddSpell /> },
             { path: 'editspell/:id', element: <EditSpell /> },
             { path: 'editadmin', element: <EditAdmin /> },
             { path: 'slot', element: <Slot /> },
             { path: 'editslot/:id', element: <EditSlot /> },
             { path: 'addslot', element: <AddSlot /> },
             { path: 'appointment', element: <Appointment /> },
             { path: 'editappointment/:id', element: <EditAppointment /> },
             { path: 'addappointment', element: <AddAppointment /> },
            {
              path: 'e-commerce',
              children: [
                { element: <Navigate to="/dashboard/e-commerce/shop" replace />, index: true },
              ],
            },
          ],
        },
  ]);
}

// AUTHENTICATION
const Login = Loadable(lazy(() => import('../pages/auth/Login')));
const CompanyLogin = Loadable(lazy(() => import('../pages/auth/CompanyLogin')));
const ClinicLogin = Loadable(lazy(() => import('../pages/auth/ClinicLogin')));
const Register = Loadable(lazy(() => import('../pages/auth/Register')));
const ResetPassword = Loadable(lazy(() => import('../pages/auth/ResetPassword')));
const CodeMail = Loadable(lazy(() => import('../pages/auth/CodeMail')));


const Home = Loadable(lazy(() => import('../pages/dashboard/Home/Home')));
const Dorm = Loadable(lazy(() => import('../pages/dashboard/Subscription/Dorm')));
const EditDorm = Loadable(lazy(() => import('../pages/dashboard/Subscription/EditDorm')));
const AddDorm = Loadable(lazy(() => import('../pages/dashboard/Subscription/AddDorm')));

const Element = Loadable(lazy(() => import('../pages/dashboard/Company/Element')));
const EditElement = Loadable(lazy(() => import('../pages/dashboard/Company/EditElement')));
const Comment = Loadable(lazy(() => import('../pages/dashboard/Company/Comment')));
const CompanyProfile = Loadable(lazy(() => import('../pages/dashboard/Company/CompanyProfile')));
const AddElement = Loadable(lazy(() => import('../pages/dashboard/Company/AddElement')));
const AddEmployee = Loadable(lazy(() => import('../pages/Employee/AddEmployee')));
const EditEmployee = Loadable(lazy(() => import('../pages/Employee/EditEmployee')));
const RepairAndDemage = Loadable(lazy(() => import('../pages/dashboard/Company/RepairAndDemage')));
const Main = Loadable(lazy(() => import('../pages/dashboard/Company/Editmaintenance')));
const AddDamage = Loadable(lazy(() => import('../pages/dashboard/Company/AddDemage')));



const Rarity = Loadable(lazy(() => import('../pages/dashboard/TermCondition.js/Rarity')));
const EditRarity = Loadable(lazy(() => import('../pages/dashboard/TermCondition.js/EditRarity')));
const AddRarity = Loadable(lazy(() => import('../pages/dashboard/TermCondition.js/AddRarity')));
const EditAdmin = Loadable(lazy(() => import('../pages/dashboard/EditAdmin/EditAdmin')));

const Spell = Loadable(lazy(() => import('../pages/dashboard/Provider/Spell')));
const AddSpell = Loadable(lazy(() => import('../pages/dashboard/Provider/AddSpell')));
const EditSpell = Loadable(lazy(() => import('../pages/dashboard/Provider/EditSpell')));
const GeneralAnalytics = Loadable(lazy(() => import('../pages/dashboard/GeneralAnalytics')));
const GeneralEdit = Loadable(lazy(() => import('../pages/dashboard/GeneralEdit')));

const Slot = Loadable(lazy(() => import('../pages/dashboard/slotpage/Slot')));
const EditSlot = Loadable(lazy(() => import('../pages/dashboard/slotpage/EditSlot')));
const AddSlot = Loadable(lazy(() => import('../pages/dashboard/slotpage/AddSlot')));

const Appointment = Loadable(lazy(() => import('../pages/dashboard/appointmentpage/Appointment')));
const EditAppointment = Loadable(lazy(() => import('../pages/dashboard/appointmentpage/EditAppointment')));
const AddAppointment = Loadable(lazy(() => import('../pages/dashboard/appointmentpage/AddAppointment')));
