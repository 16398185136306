/* eslint-disable */
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import {
  Container,
  Box,
  Typography,
  MenuItem,
  Select,
  Divider,
  // Radio,
  //   CardHeader,
  Grid,
  Card,
  CardContent,
  Stack,
  Tooltip,
  IconButton,
} from '@mui/material'; // Import Typography for text
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import Page from '../../../components/Page';
import HeaderBreadcrumbs from '../../../components/HeaderBreadcrumbs';
import SafetyExternal from './SafetyExternal';
import QualityExternal from './QualityExternal';
import PodExternal from './PodExternal';
import FeedBackExternal from './FeedBackExternal';
import PositiveExternal from './PositiveExternal';
import NegitiveExternal from './NegitiveExternal';
import CustomerExternal from './CustomerExternal';
import Couseling from './Couseling';
import axiosInstance from '../../../utils/axios';

function FilterExternal() {
  const NewSubSchema = Yup.object().shape({
    employee: Yup.string().required('Employee is required'),
  });

  const defaultValues = {
    employee: '',
  };
  const methods = useForm({
    resolver: yupResolver(NewSubSchema),
    defaultValues,
  });

  const {
    handleSubmit,
    formState: { isSubmitting, errors },
    register,
  } = methods;

  const [dataone, setDataone] = useState([]);
  const [data, setData] = useState([]);
  const [selectedItem, setSelectedItem] = useState(null);
  const handleDownload = () => {
    window.print();
  };
  const handleOptionChange = (event) => {
    setSelectedItem(event.target.value);
  };
  const GetAllAdmin = () => {
    axiosInstance
      .get(selectedItem == null ? `/admin/employees` : `/admin/employees?name=${selectedItem}`)
      .then((response) => {
        console.log(response);
        if (response.data.employee) {
          if (selectedItem == null) {
            setData(response.data.employee);
          } else {
            setDataone(response.data.employee);
          }
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };
  useEffect(() => {
    GetAllAdmin();
  }, [selectedItem]);
  console.log(dataone, 'safetydata');
  const handlePasswordKeyPress = (event) => {
    if (event.key === 'Enter') {
      OnSubmit(methods.getValues()); // Call the onSubmit function
    }
  };

  return (
    <Page title="Vehicle">
      <Container maxWidth="lg">
        <HeaderBreadcrumbs
          heading=""
          links={[{ name: '', href: '' }]}
          action={
            selectedItem && (
              <Tooltip arrow title="Download Pdf">
                <IconButton style={{ marginLeft: '5px' }}>
                  <FileDownloadIcon onClick={handleDownload} />
                </IconButton>
              </Tooltip>
            )
          }
        />
        <Typography variant="h5" style={{ marginTop: '-40px', marginBottom: '10px' }}>
          External Employee Information
        </Typography>
        <Select
          {...methods.register('employee')}
          label="Select Employee"
          defaultValue=""
          onChange={handleOptionChange}
          fullWidth
        >
          {data.map((item) => (
            <MenuItem key={item.name} value={item.name}>
              {item.name}
            </MenuItem>
          ))}
        </Select>

        {/* <CardHeader title="Student Info" /> */}

        {selectedItem && (
          <>
            <Grid container spacing={3}>
              <Grid item xs={12} md={4}>
                <Card sx={{ my: 3 }}>
                  <CardContent>
                    <Stack spacing={2}>
                      <Stack direction="row" justifyContent="space-between">
                        <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                          First Name
                        </Typography>
                        <Typography variant="body1">{dataone[0]?.name}</Typography>
                      </Stack>
                      <Divider />
                      <Stack direction="row" justifyContent="space-between">
                        <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                          Week
                        </Typography>
                        <Typography variant="body1">Week 1</Typography>
                      </Stack>
                      <Divider />
                      <Stack direction="row" justifyContent="space-between">
                        <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                          Total Delivery Packages :
                        </Typography>
                        <Typography variant="body1">200</Typography>
                      </Stack>
                    </Stack>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={12} md={4}>
                <Card sx={{ my: 3 }}>
                  <CardContent>
                    <Stack spacing={2}>
                      <Stack direction="row" justifyContent="space-between">
                        <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                          Overall Scroll
                        </Typography>
                        <Typography variant="body1">20</Typography>
                      </Stack>
                      <Divider />
                      <Stack direction="row" justifyContent="space-between">
                        <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                          Fantastic
                        </Typography>
                        <Typography variant="body1">
                          {dataone[0]?.transport_id?.customer_feedback_extracts[0]?.da_tier}
                        </Typography>
                      </Stack>
                      <Divider />
                      {/* <Stack direction="row" justifyContent="space-between">
                    <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                      Total Delivery Packages :
                    </Typography>
                    <Typography variant="body1">200</Typography>
                  </Stack> */}
                    </Stack>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={12} md={4}>
                <Card sx={{ my: 3 }}>
                  <CardContent>
                    <Stack spacing={2}>
                      <Stack direction="row" justifyContent="space-between">
                        <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                          Ranked
                        </Typography>
                        <Typography variant="body1">
                          {dataone[0]?.transport_id?.customer_feedback_extracts[0]?.rank}
                        </Typography>
                      </Stack>
                      <Divider />
                      <Stack direction="row" justifyContent="space-between">
                        <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                          Drivers
                        </Typography>
                        <Typography variant="body1">Out Of 40 Drivers</Typography>
                      </Stack>
                      <Divider />
                      {/* <Stack direction="row" justifyContent="space-between">
                    <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                      Total Delivery Packages :
                    </Typography>
                    <Typography variant="body1">200</Typography>
                  </Stack> */}
                    </Stack>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
            <div>
              <textarea disabled style={{ width: '100%', padding: '20px' }} value={'Leave Message'} />
            </div>

            <div style={{ marginTop: '50px' }} id="hello">
              <SafetyExternal dataone={dataone} />
              <Divider sx={{ my: 5 }} />
              <QualityExternal dataone={dataone} />
              <Divider sx={{ my: 5 }} />
              <PodExternal dataone={dataone} />
              <Divider sx={{ my: 5 }} />
              <FeedBackExternal dataone={dataone} />
              <Divider sx={{ my: 5 }} />
              <PositiveExternal dataone={dataone} />
              <Divider sx={{ my: 5 }} />
              <NegitiveExternal dataone={dataone} />
              <Divider sx={{ my: 5 }} />
              <CustomerExternal dataone={dataone} />
              <Divider sx={{ my: 5 }} />
              <Couseling dataone={dataone} />
              {/* <Divider sx={{ my: 5 }} />
          <FilterTableEmployeeTen />
          <Divider sx={{ my: 5 }} />
          <FilterTableEmployeeDemage /> */}
            </div>
          </>
        )}
      </Container>
    </Page>
  );
}

export default FilterExternal;
