

/* eslint-disable */
import * as React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import MaterialReactTable from "material-react-table";
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import EditIcon from "@mui/icons-material/Edit";
import { Delete as DeleteIcon } from "@mui/icons-material";
import { useNavigate, Link as RouterLink } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { Box, IconButton, Container, Button } from '@mui/material';
import Page from '../../components/Page';
import HeaderBreadcrumbs from '../../components/HeaderBreadcrumbs';
import Iconify from '../../components/Iconify';
import axiosInstance from '../../utils/axios';

export default function Attendance() {
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const idone = localStorage.getItem('e_id');
  const [Data, setdata] = React.useState([]);
  const GetAllAdmin = () => {
    axiosInstance
      .get(`admin/attendances?employee_id=${idone}`)
      .then((response) => {
        if (response.data.attendance) {
          // console.log(response?.data);
          setdata(response?.data?.attendance);
        }
        console.log(response, 'resp');
      })
      .catch((error) => {
        console.error(error);
      });
  };
  React.useEffect(() => {
    GetAllAdmin();
  }, []);
  async function handleDelete(rowdata) {
    console.log(rowdata);
    try {
      await axiosInstance.delete(`admin/attendances/${rowdata}`).then((response) => {
        if (response?.data?.message === 'Deleted successfully') {
          enqueueSnackbar(response?.data?.message);
          GetAllAdmin();
        }
      });
    } catch (error) {
      enqueueSnackbar(error?.message, {
        variant: 'error',
      });
      console.error(error);
    }
  }
  const columns = React.useMemo(
    () => [
      {
        accessorKey: "id",
        header: "ID",
        size: 20,
      },
      {
        accessorKey: "date",
        header: "Date",
        size: 20,
      },
      {
        accessorKey: "delivery_associate",
        header: "Delivery delivery_associate",
        size: 20,
      },
      {
        accessorKey: "week",
        header: "Week",
        size: 10,
      },
      {
        accessorKey: "year",
        header: "Year",
        size: 10,
      },
      {
        accessorKey: "time",
        header: "Time",
        size: 20,
      },
      {
        accessorKey: "Issue_type",
        header: "Issue Type",
        size: 20,
      },
      //   {
      //     accessorKey: "engine",
      //     header: "Followd Instruction",
      //     size: 20,
      //   },
      //   {
      //     accessorKey: "shop",
      //     header: "Friendly",
      //     size: 20,
      //   },
      //   {
      //     accessorKey: "shop",
      //     header: "Above And Beyond",
      //     size: 20,
      //   },
      //   {
      //     accessorKey: "shop",
      //     header: "Deliverd With care",
      //     size: 20,
      //   },
      //   {
      //     accessorKey: "shop",
      //     header: "Thank My Driver",
      //     size: 20,
      //   },

      {
        accessorKey: "actions",
        header: "Actions",
        size: 10,
        Cell: ({ row }) => (
          <Box>
            <IconButton
              color="primary"
              sx={{
                border: "1px solid",
                borderColor: "primary.main",
                marginRight: "5px" // Add margin to create a gap
              }}
              onClick={() => { navigate(`/dashboard/editattendance/${row.original.id}`, { state: row.original }) }}
            >
              <EditIcon />
            </IconButton>
            <IconButton
              color="error"
              sx={{
                border: "1px solid",
                borderColor: "error.main",
              }}
              onClick={() => {
                handleDelete(row.original.id);
              }}
            >
              <DeleteIcon />
            </IconButton>
          </Box>
        ),
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const [tableData, setTableData] = React.useState([]);

  const data = [
    {
      id: 1,
      date: "2024-04-09",
      warranty: "Yes",
      body: "Sedan",
      engine: "V6",
      shop: "ABC Auto Repair",
      description: "Oil change and filter replacement",
      estCost: "$50",
    },
    {
      id: 2,
      date: "2024-04-10",
      warranty: "No",
      body: "Truck",
      engine: "V8",
      shop: "XYZ Auto Service",
      description: "Brake pad replacement",
      estCost: "$100",
    },
    // Add more data as needed
  ];

  React.useEffect(() => {
    setTableData(data);
  }, []);

  return (
    <Page title="Attendance">
      <Container maxWidth='lg'>
        <HeaderBreadcrumbs
          heading="Attendance"
          links={[
            { name: '', href: '' },]}
          action={

            <Button
              variant="contained"
              startIcon={<Iconify icon="eva:plus-fill" />}
              component={RouterLink}
              to={'/dashboard/addattendance'}
            // onClick={() => { navigate(`/dashboard/maintenance`) }}


            >
              Attendance
            </Button>
          }
        />
        <MaterialReactTable
          columns={columns}
          data={Data}
          enableRowActions={false} // Disable default row actions
        />
      </Container>
    </Page>
  );
}
