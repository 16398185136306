import { useEffect } from 'react';
import { Grid, Container, Box } from '@mui/material';
import { useNavigate } from 'react-router';
import useSettings from '../../hooks/useSettings';
import Page from '../../components/Page';
import Fleetstatus from './Filter/Fleet/Fleetstatus';
import Registration from './Filter/Fleet/Registration';
import OpenRepair from './Filter/Fleet/Openrepair';
import Inspection from './Filter/Fleet/Inspection';

export default function Fleet() {
  const { themeStretch } = useSettings();
  const currentUser = JSON.parse(localStorage.getItem('currentuser'));
  const navigate = useNavigate();

  useEffect(() => {
    if (currentUser?.role === 'company') {
      if (!currentUser?.is_subscribed) {
        localStorage.clear();
        navigate('/subscription');
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Page title="Dashboard">
      <Container maxWidth={themeStretch ? false : 'xl'}>
        <Grid item xs={12} md={12} lg={12}>
          {/* Table Code Start */}
          <Box className="my-5">
            <div className="container">
              <div className="row">
                <div className="col-lg-12">
                  <Fleetstatus />
                </div>
                <div className="col-lg-12 mt-5">
                  <Registration />
                </div>
                <div className="col-lg-12 mt-5">
                  <OpenRepair />
                </div>
                <div className="col-lg-12 mt-5">
                  <Inspection />
                </div>
              </div>
            </div>
          </Box>
          {/* Table Code End */}
        </Grid>
      </Container>
    </Page>
  );
}
