
/* eslint-disable */
import * as React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import MaterialReactTable from 'material-react-table';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import EditIcon from '@mui/icons-material/Edit';
import { Delete as DeleteIcon } from '@mui/icons-material';
import { useNavigate, Link as RouterLink } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { Box, IconButton, Container, Button } from '@mui/material';
import Page from '../../../components/Page';
// import { PATH_DASHBOARD } from '../../../routes/paths';
// import HeaderBreadcrumbs from 'src/components/HeaderBreadcrumbs';
import HeaderBreadcrumbs from '../../../components/HeaderBreadcrumbs';
// import Button from 'src/theme/overrides/Button';
// import Button from '../../../theme/overrides/Button';
import Iconify from '../../../components/Iconify';
import axiosInstance from '../../../utils/axios';

export default function FilterTableEmployeeTen({ dataone }) {

  const columns = React.useMemo(
    () => [
  
      {
        accessorKey: 'date',
        header: 'Date',
        size: 20,
      },
      {
        accessorKey: 'type',
        header: 'Type',
        size: 20,
      },
      {
        accessorKey: 'category',
        header: 'Category',
        size: 20,
      },
      {
        accessorKey: 'lmet_reported',
        header: 'LMET Reported',
        size: 20,
      },
      {
        accessorKey: 'case_no',
        header: 'Case #',
        size: 20,
      },
      {
        accessorKey: 'medical_treatment',
        header: 'Medical Treatment',
        size: 20,
      },
      {
        accessorKey: 'police',
        header: 'Police',
        size: 20,
      },
      {
        accessorKey: 'dept',
        header: 'Dept.',
        size: 20,
      },
      {
        accessorKey: 'investigated_by',
        header: 'Investigated by',
        size: 20,
      },
      {
        accessorKey: 'disposition',
        header: 'Disposition',
        size: 20,
      },
      {
        accessorKey: 'reported_by',
        header: 'Reported by',
        size: 20,
      },

       
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return (
    <Page title="Incident and Injury">
      <Container maxWidth="lg">
        <HeaderBreadcrumbs
          heading="Incident and Injury"
          links={[{ name: '', href: '' }]}
          action={
            <>
            </>
        
            // <Button
            //   variant="contained"
            //   startIcon={<Iconify icon="eva:plus-fill" />}
            //   component={RouterLink}
            //   to={'/dashboard/addinjury'}
            //   // onClick={() => { navigate(`/dashboard/maintenance`) }}

            // >
            //   Incident and Injury
            // </Button>
          }
        />
        <MaterialReactTable
          columns={columns}
          data={dataone[0]?.incident_and_injuries || []}
          enableRowActions={false} // Disable default row actions
        />
      </Container>
    </Page>
  );
}
