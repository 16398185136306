
/* eslint-disable */
import React, { useMemo, useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { LoadingButton } from '@mui/lab';
import { Card, Grid, Stack, Container, MenuItem } from '@mui/material';
import { FormProvider, RHFTextField, RHFSelect } from '../../../components/hook-form';
import axiosInstance from '../../../utils/axios';
import HeaderBreadcrumbs from '../../../components/HeaderBreadcrumbs';

export default function AddDispatch() {
  const navigate = useNavigate();

  const { enqueueSnackbar } = useSnackbar();

  const NewSubSchema = Yup.object().shape({
    description: Yup.string().required('description is required'),
    date: Yup.string().required('date is required'),


  });

  const defaultValues = useMemo(
    () => ({
        description: '',
        date: '',
      
    }),
    []
  );

  const methods = useForm({
    resolver: yupResolver(NewSubSchema),
    defaultValues,
  });

  const {
    handleSubmit,
    formState: { isSubmitting, errors },
  } = methods;


  const onSubmit = async (data) => {
    console.log(data);
    try {
      const dorm = new FormData();
      dorm.append('description', data?.description);
      dorm.append('date', data?.date);



      await axiosInstance.post(`/admin/dispatcherAlerts`, dorm).then((response) => {
        // console.log(response?.data, '=========');
        if (response.data?.dispatcherAlert) {
          enqueueSnackbar(response?.data?.message);
          // reset();
          navigate('/dashboard/home');
        }
      });
    } catch (error) {
      // enqueueSnackbar(error?.message, {
      // variant: 'error',
      // });
      console.error(error);
    }
  };




  return (
    <Container maxWidth="sm">
      <HeaderBreadcrumbs heading="Add Dispatcher Alerts" links={[{ name: '', href: '' }]} />
      <Card>
        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={1}>
            <Grid item xs={12} md={12}>
              <Card sx={{ p: 3 }}>
                <Stack spacing={3}>
               
               
                  <RHFTextField name="description" label="Description" InputLabelProps={{ shrink: true }} />
    
                  <RHFTextField name="date" label="Date" type='date' InputLabelProps={{ shrink: true }} />


               
                  <Grid item xs={6} md={6}>
                    <LoadingButton type="submit" variant="contained" size="large" loading={isSubmitting}>
                      Add Dispatcher Alerts
                    </LoadingButton>
                  </Grid>
                </Stack>
              </Card>
            </Grid>
          </Grid>
        </FormProvider>
      </Card>
    </Container>
  );
}
