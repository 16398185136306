/* eslint-disable */
import React, { useEffect, useMemo, useState } from 'react';
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { LoadingButton } from '@mui/lab';
import { Card, Grid, Stack, Container, MenuItem, Box } from '@mui/material';
import { FormProvider, RHFTextField, RHFSelect } from '../../components/hook-form';
import axiosInstance from '../../utils/axios';
import HeaderBreadcrumbs from '../../components/HeaderBreadcrumbs';

export default function AddInjury() {
  const navigate = useNavigate();
  const [Data, setdata] = useState([]);
  const { enqueueSnackbar } = useSnackbar();
  const data = [];
  const year = [];
  for (var i = 1; i <= 60; i++) {
    data.push({
      id: i,
      name: `week ${i}`,
    });
  }
  for (var i = Data?.startYear; i <= Data?.currentYear; i++) {
    year.push({
   
      name: `${i}`,
    });
  }
  const NewSubSchema = Yup.object().shape({
    date: Yup.string().required('Date is required'),
    week: Yup.string().required('Week is required'),
    year: Yup.string().required('year is required'),

    type: Yup.string().required('Type is required'),
    category: Yup.string().required('Category is required'),
    lmet_reported: Yup.string().required('LMET Reported is required'),
    case_: Yup.string().required('Case # is required'),
    medical_treatment: Yup.string().required('Medical Treatment is required'),
    police: Yup.string().required('Police is required'),
    dept: Yup.string().required('Department is required'),
    investigated_by: Yup.string().required('Investigated By is required'),
    disposition: Yup.string().required('Disposition is required'),
    reported_by: Yup.string().required('Reported By is required'),
    file: Yup.mixed().required('File  is required'),
  });

  const defaultValues = useMemo(
    () => ({
      date: '',
      week: '',
      year: '',
      type: '',
      category: '',
      lmet_reported: '',
      case_: '',
      medical_treatment: '',
      police: '',
      dept: '',
      investigated_by: '',
      disposition: '',
      reported_by: '',
      file: '',
    }),
    []
  );

  const methods = useForm({
    resolver: yupResolver(NewSubSchema),
    defaultValues,
  });

  const {
    handleSubmit,
    setValue,
    formState: { isSubmitting, errors },
  } = methods;

  console.log(errors, 'errors');
  const idone = localStorage.getItem('e_id');
  const onSubmit = async (data) => {
    console.log(data.file);
    // Handle submission logic here
    try {
      const dorm = new FormData();
      dorm.append('date', data?.date);
      dorm.append('employee_id', idone);
      dorm.append('type', data?.type);
      dorm.append('week', data?.week);
      dorm.append('year', data?.year);
      dorm.append('category', data?.category);
      dorm.append('lmet_reported', data?.lmet_reported);
      dorm.append('case_no', data?.case_);
      dorm.append('medical_treatment', data?.medical_treatment);
      dorm.append('police', data?.police);
      dorm.append('dept', data?.dept);
      dorm.append('investigated_by', data?.investigated_by);
      dorm.append('disposition', data?.disposition);
      dorm.append('reported_by', data?.reported_by);
      dorm.append('file[0]', data?.file);
      //  console.log(user?.id);

      await axiosInstance.post(`/admin/incidentandinjuries`, dorm).then((response) => {
        console.log(response?.data, '=========');
        if (response.data?.incidentAndInjury) {
          enqueueSnackbar('Add SuccessFully');
          // reset();
          navigate('/dashboard/consuling');
        }
      });
    } catch (error) {
      // enqueueSnackbar(error?.message, {
      // variant: 'error',
      // });
      console.error(error);
    }
  };
  const GetYear = () => {
    axiosInstance
      .get('/admin/years')
      .then((response) => {
        // console.log(response.data)
        if (response?.data) {
          // console.log(response?.data);
          setdata(response?.data);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };
  useEffect(() => {
    GetYear();
  }, []);
  return (
    <Container maxWidth="sm">
      <HeaderBreadcrumbs heading="Add Injury" links={[{ name: '', href: '' }]} />
      <Card>
        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={1}>
            <Grid item xs={12} md={12}>
              <Card sx={{ p: 3 }}>
                <Stack spacing={3}>
                  <RHFSelect
                    name="week"
                    label="Select Week"
                    size="large"
                    InputLabelProps={{ shrink: true }}
                    SelectProps={{ native: false, sx: { textTransform: 'capitalize' } }}
                  >
                    {data?.map((e) => (
                      <MenuItem
                        value={e?.name}
                        sx={{
                          mx: 1,
                          my: 0.5,
                          borderRadius: 0.75,
                          typography: 'body2',
                          textTransform: 'capitalize',
                        }}
                      // onClick={()=>setemID(e?.id)}
                      >
                        {e?.name}
                        {/* hi */}
                      </MenuItem>
                    ))}
                  </RHFSelect>
                  <Box sx={{ mt: 1 }} />
                  <RHFSelect
                    name="year"
                    label="Select year"
                    size="large"
                    InputLabelProps={{ shrink: true }}
                    SelectProps={{ native: false, sx: { textTransform: 'capitalize' } }}
                  >
                    {year?.map((e) => (
                      <MenuItem
                        value={e?.name}
                        sx={{
                          mx: 1,
                          my: 0.5,
                          borderRadius: 0.75,
                          typography: 'body2',
                          textTransform: 'capitalize',
                        }}
                      // onClick={()=>setemID(e?.id)}
                      >
                        {e?.name}
                        {/* hi */}
                      </MenuItem>
                    ))}
                  </RHFSelect>

                  <RHFSelect
                    name="category"
                    label="Select Category"
                    size="large"
                    InputLabelProps={{ shrink: true }}
                    SelectProps={{ native: false, sx: { textTransform: 'capitalize' } }}
                  >

                    <MenuItem value="Incident">Incident</MenuItem>
                    <MenuItem value="Injury">Injury</MenuItem>

                  </RHFSelect>
                  <RHFTextField name="date" label="Date" type="date" InputLabelProps={{ shrink: true }} />
                  <RHFTextField name="type" label="Type" InputLabelProps={{ shrink: true }} />
                  <RHFTextField name="lmet_reported" label="LMET Reported" InputLabelProps={{ shrink: true }} />
                  <RHFTextField name="case_" label="Case #" InputLabelProps={{ shrink: true }} />
                  <RHFTextField name="medical_treatment" label="Medical Treatment" InputLabelProps={{ shrink: true }} />
                  <RHFTextField name="police" label="Police" InputLabelProps={{ shrink: true }} />
                  <RHFTextField name="dept" label="Department" InputLabelProps={{ shrink: true }} />
                  <RHFTextField name="investigated_by" label="Investigated By" InputLabelProps={{ shrink: true }} />
                  <RHFTextField name="disposition" label="Disposition" InputLabelProps={{ shrink: true }} />
                  <RHFTextField name="reported_by" label="Reported By" InputLabelProps={{ shrink: true }} />
                  <input type="file" onChange={(e) => setValue('file', e.target.files[0])} />
                  <p style={{ color: 'red' }}>
                    {errors?.file?.message}
                  </p>
                  <Grid item xs={6} md={6}>
                    <LoadingButton type="submit" variant="contained" size="large" loading={isSubmitting}>
                      Add Injury
                    </LoadingButton>
                  </Grid>
                </Stack>
              </Card>
            </Grid>
          </Grid>
        </FormProvider>
      </Card>
    </Container>
  );
}
