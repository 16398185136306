/* eslint-disable */
import * as React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import MaterialReactTable from 'material-react-table';
import AccordionDetails from '@mui/material/AccordionDetails';
import { useSnackbar } from 'notistack';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import EditIcon from '@mui/icons-material/Edit';
import { Delete as DeleteIcon } from '@mui/icons-material';
import { useNavigate, Link as RouterLink } from 'react-router-dom';

import { Box, IconButton, Typography, Button, Paper, Container } from '@mui/material';
import Page from '../../components/Page';
import { PATH_DASHBOARD } from '../../routes/paths';

import Iconify from '../../components/Iconify';
import HeaderBreadcrumbs from '../../components/HeaderBreadcrumbs';
import Dia from './Dia';
import axiosInstance from '../../utils/axios';
import Acident from './Acident';
import Eslaction from './Eslaction';
import Attendance from './Attendence';

export default function EmployeeCon({ id }) {
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const handleCloseModal1 = () => {
    setIsModalOpen1(false);
  };
  const [isModalOpen1, setIsModalOpen1] = React.useState(false);
  const [loader, setloader] = React.useState(false);

  const handleOpenModal1 = () => {
    console.log('');
    // setCommentView(report?.report);
    setIsModalOpen1(true);
  };
  const [Data, setdata] = React.useState([]);
  async function handleDelete(rowdata) {
    console.log(rowdata);
    try {
      await axiosInstance.delete(`admin/employeeCounseling/${rowdata}`).then((response) => {
        console.log(response);
        if (response?.data?.message === 'Deleted successfully') {
          enqueueSnackbar('Deleted Sucessfully');
          GetAllAdmin();
        }
      });
    } catch (error) {
      enqueueSnackbar(error?.message, {
        variant: 'error',
      });
      console.error(error);
    }
  }
  console.log(Data, 'Data');
  const GetAllAdmin = () => {
    setloader(true);
    const e_id = localStorage.getItem('e_id');
    axiosInstance
      .get(`admin/employeeCounseling?employee_id=${e_id}`)
      .then((response) => {
        setloader(true);
        if (response.data?.employeeCounseling) {
          setdata(response?.data?.employeeCounseling);
          setloader(false);
        }
        console.log(response, 'resp');
      })
      .catch((error) => {
        setloader(false);
        console.error(error);
      });
  };
  React.useEffect(() => {
    GetAllAdmin();
  }, []);
  const columns = React.useMemo(
    () => [
      {
        accessorKey: 'id',
        header: 'ID',
        size: 10,
      },
      {
        accessorKey: 'team_lead',
        header: 'Team Leader',
        size: 20,
      },
      {
        accessorKey: 'date',
        header: 'Date of Occurrence',
        size: 20,
      },

      {
        accessorKey: 'status',
        header: 'Filter Associates By Status',
        size: 10,
        Cell: ({ row }) =>
          row?.original?.status == 'Active' ? (
            <Button
              fullWidth
              variant="contained"
              color="success"
              endIcon={<Iconify icon={'eva:checkmark-circle-2-fill'} />}
            // onClick={() => {
            //   handleApproved(row?.original?.id,0);
            // }}
            >
              {/* {row.original} */}
              {/* {console.log(row)} */}
              Active
            </Button>
          ) : (
            <Button
              fullWidth
              variant="contained"
              color="error"
              endIcon={<Iconify icon={'eva:close-circle-fill'} />}
            // onClick={() => {
            //   handleApproved(row?.original?.id,1);
            // }}
            >
              Inactive
            </Button>
          ),
      },
      {
        accessorKey: 'associate_issues',
        header: 'Associate Name',
        size: 20,
      },
      {
        accessorKey: 'counseling_severity',
        header: 'Counseling Severity',
        size: 20,
      },
      {
        accessorKey: 'statement_problem',
        header: 'Statement of the problem',
        size: 20,
      },
      {
        accessorKey: 'prior_discussion_summary',
        header: 'Prior discussion summary',
        size: 20,
      },
      {
        accessorKey: 'summary_corrective_action_discussed',
        header: 'Summary of corrective action discussed',
        size: 20,
      },
      {
        accessorKey: 'corrective_behavior',
        header: 'Consequences of failure to improve performance or corrective behavior',
        size: 20,
      },
      {
        accessorKey: "week",
        header: "Week",
        size: 10,
      },
      {
        accessorKey: "year",
        header: "Year",
        size: 10,
      },
      //   {
      //     accessorKey: 'file',
      //     header: 'File',
      //     size: 20,
      //   },
      {
        accessorKey: 'actions',
        header: 'Actions',
        size: 10,
        Cell: ({ row }) => (
          <Box>
            <IconButton
              color="primary"
              sx={{
                border: '1px solid',
                borderColor: 'primary.main',
                marginRight: '5px',
              }}
              onClick={() => {
                navigate(`/dashboard/editelement/${row.original.id}`);
              }}
            >
              <EditIcon />
            </IconButton>
            <IconButton
              color="error"
              sx={{
                border: '1px solid',
                borderColor: 'error.main',
              }}
              onClick={() => {
                handleDelete(row.original.id);
              }}
            >
              <DeleteIcon />
            </IconButton>
          </Box>
        ),
      },
    ],
    []
  );

  const data = [
    {
      id: 1,
      teamLeader: 'John Doe',
      dateOfOccurrence: '2022-04-20',
      associateName: 'Associate A',
      counselingSeverity: 'High',
      Filter_Associates_By_Status: 'Active',
      problemStatement: 'Lorem ipsum dolor sit amet',
      priorDiscussionSummary: 'Lorem ipsum dolor sit amet',
      correctiveActionSummary: 'Lorem ipsum dolor sit amet',
      consequences: 'Lorem ipsum dolor sit amet',
      file: 'example.pdf',
    },
    {
      id: 2,
      teamLeader: 'Jane Smith',
      dateOfOccurrence: '2022-04-21',
      associateName: 'Associate B',
      counselingSeverity: 'Low',
      Filter_Associates_By_Status: 'Inactive',

      problemStatement: 'Lorem ipsum dolor sit amet',
      priorDiscussionSummary: 'Lorem ipsum dolor sit amet',
      correctiveActionSummary: 'Lorem ipsum dolor sit amet',
      consequences: 'Lorem ipsum dolor sit amet',
      file: 'example2.pdf',
    },
    // Add more data as needed
  ];

  const [tableData, setTableData] = React.useState([]);

  React.useEffect(() => {
    setTableData(data);
  }, []);

  return (
    <Page title="Employee">
      <Container maxWidth="lg">
        <HeaderBreadcrumbs
          heading="Counseling"
          links={[{ name: '', href: '' }]}
          action={
            <>
              {/* <Button
                variant="contained"
                startIcon={<Iconify icon="eva:plus-fill" />}
                component={RouterLink}
                to={''}
                onClick={handleOpenModal1}
                style={{ marginRight: '8px' }} // Add margin to the right for spacing
              >
                Import
              </Button> */}

              <Button
                variant="contained"
                startIcon={<Iconify icon="eva:plus-fill" />}
                component={RouterLink}
                to={PATH_DASHBOARD.element.addelement}
              >
                Counseling
              </Button>
            </>
          }
        />
        <MaterialReactTable
          state={{ isLoading: loader, showSkeletons: loader }}
          // state={{ isLoading: loading,
          //   showSkeletons: loading,
          // }}
          columns={columns}
          data={Data}
          enableRowActions={false}
        />
        <Box sx={{ mt: 5 }} />
        <Acident />
        <Box sx={{ mt: 5 }} />
        <Eslaction />
        <Box sx={{ mt: 5 }} />
        <Attendance />

      </Container>
      <Dia handleCloseModal1={handleCloseModal1} isModalOpen1={isModalOpen1} />
    </Page>
  );
}
