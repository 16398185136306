/* eslint-disable */
import * as React from 'react';

import MaterialReactTable from 'material-react-table';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { Box, Container, Divider, MenuItem } from '@mui/material';
import { RHFSelect, FormProvider } from '../../../components/hook-form';
import Page from '../../../components/Page';

import HeaderBreadcrumbs from '../../../components/HeaderBreadcrumbs';
import { useEffect } from 'react';
import axiosInstance from '../../../utils/axios';
import { useMemo } from 'react';
import { useState } from 'react';

export default function Tier() {
  const [Data, setdata] = useState([]);
  const [selectedItem, setSelectedItem] = useState(30);
  const NewSubSchema = Yup.object().shape({});
  const data = [
    {
      name: '30 days',
      value: 30,
    },
    {
      name: '45 days',
      value: 45,
    },
    {
      name: '90 days',
      value: 90,
    },
  ];
  const defaultValues = useMemo(
    () => ({
      week1: data?.value ||30 ,
    }),
    []
  );

  const methods = useForm({
    resolver: yupResolver(NewSubSchema),
    defaultValues,
  });

  const {
    handleSubmit,
    control,
    formState: { isSubmitting, errors },
  } = methods;

  
  // const [Data, setdata] = React.useState([]);
  const GetAllAdmin = () => {
    axiosInstance
      .get(`admin/tierinfraction?no_of_days=${selectedItem}`)
      .then((response) => {
        if (response.data.employee) {
          // console.log(response?.data);
          setdata(response?.data?.employee);
        }
        // console.log(response, 'resp');
      })
      .catch((error) => {
        console.error(error);
      });
  };
  React.useEffect(() => {
    GetAllAdmin();
  }, [selectedItem]);
  const handleOptionChange = (event) => {
    // console.log(event,'event');
    setSelectedItem(event.target.value);
  };
  const onSubmit = async (data) => {};
  const columns = React.useMemo(
    () => [
  

      {
        accessorKey: 'employee_name',
        header: 'Delivery Associate',
        size: 20,
      },
      {
        accessorKey: 'tier',
        header: 'Tier',
        size: 20,
        // Cell: ({ row }) => <Box>{row.original?.score_cards?.map((e) => e?.overall_tier)}</Box>,
      },
      {
        accessorKey: 'date',
        header: 'Date',
        size: 20,
        // Cell: ({ row }) => <Box>{row.original?.score_cards?.map((e) => e?.created_at)}</Box>,
      },
      {
        accessorKey: 'week',
        header: 'weekofOccurrence',
        size: 20,
        // Cell: ({ row }) => <Box>{row.original?.score_cards?.map((e) => e?.week)}</Box>,
      },
      {
        accessorKey: 'behavior',
        header: 'Behavior',
        size: 20,
        // Cell: ({ row }) => <Box>{row.original?.score_cards?.map((e) => e?.fico_score)}</Box>,
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const [tableData, setTableData] = React.useState([]);

  const rows = [
    {
      id: 1,
      description: 'Snow',
    },
    {
      id: 2,
      description: 'Snow',
    },
  ];

  React.useEffect(() => {
    setTableData(rows);
  }, []);
  return (
    <Page title="Dashboard">
      <Container maxWidth="lg">
        <HeaderBreadcrumbs heading="Tier Infractions" links={[{ name: '', href: '' }]} action={<></>} />
        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
          <div style={{ display: 'flex', width: '50%' }}>
            <div style={{ flex: '20%', marginRight: '2%' }}>
              <RHFSelect
                name={`week1`}
                label="Select Days"
                size="large"
                InputLabelProps={{ shrink: true }}
                SelectProps={{ native: false, sx: { textTransform: 'capitalize' } }}
                onChange={handleOptionChange}
              >
                {data?.map((e, i) => (
                  <MenuItem
                    key={e?.id}
                    value={e?.value}
                    sx={{
                      mx: 1,
                      my: 0.5,
                      borderRadius: 0.75,
                      typography: 'body2',
                      textTransform: 'capitalize',
                    }}
                  >
                    {e?.name}
                  </MenuItem>
                ))}
              </RHFSelect>
            </div>
            {/* <div style={{ flex: '20%' }}>
              <RHFSelect
                name={`year`}
                label="Year"
                size="large"
                InputLabelProps={{ shrink: true }}
                SelectProps={{ native: false, sx: { textTransform: 'capitalize' } }}
              >
                {year?.map((e, i) => (
                  <MenuItem
                    key={e?.name}
                    value={e?.name}
                    sx={{
                      mx: 1,
                      my: 0.5,
                      borderRadius: 0.75,
                      typography: 'body2',
                      textTransform: 'capitalize',
                    }}
                  >
                    {e?.name}
                  </MenuItem>
                ))}
              </RHFSelect>
            </div> */}
          </div>
        </FormProvider>
        <MaterialReactTable columns={columns} checkboxSelection data={Data || []} />
      </Container>
    </Page>
  );
}
