

/* eslint-disable */
import * as React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import MaterialReactTable from "material-react-table";
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import EditIcon from "@mui/icons-material/Edit";
import { Delete as DeleteIcon } from "@mui/icons-material";
import { useNavigate, Link as RouterLink } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { Box, IconButton, Container, Button } from '@mui/material';
import Page from '../../../components/Page';
import { PATH_DASHBOARD } from '../../../routes/paths';
// import HeaderBreadcrumbs from 'src/components/HeaderBreadcrumbs';
import HeaderBreadcrumbs from '../../../components/HeaderBreadcrumbs';
// import Button from 'src/theme/overrides/Button';
// import Button from '../../../theme/overrides/Button';
import Iconify from '../../../components/Iconify';
import axiosInstance from '../../../utils/axios';

export default function FilterTableEmployeeOne({ dataone }) {
  console.log(dataone,"filter");
  // console.log(dataone[0]?.transport_id?.eocs[0]?.week, 'eoc');
  // console.log(dataone[0]?.score_cards[0]?.week, 'score');


  // const weekeoc = dataone[0]?.transport_id?.eocs[0]?.week 
  // const yeareoc = dataone[0]?.transport_id?.eocs[0]?.year
  // const weeksafety = dataone[0]?.score_cards[0]?.week
  // const yearsafety = dataone[0]?.score_cards[0]?.year
  // const [average, Setaverage] = React.useState(0);
  // if (dataone[0]?.transport_id?.eocs[0]?.week === dataone[0]?.score_cards[0]?.week
  //   && dataone[0]?.transport_id?.eocs[0]?.year === dataone[0]?.score_cards[0]?.year) {

  // }
  // else {
  //   Setaverage(0)
  // }

  // const { enqueueSnackbar } = useSnackbar();
  // const navigate = useNavigate();
  // console.log(dataone,'safetydata');
  // const [Data, setdata] = React.useState([]);
  // console.log(Data, 'Data');
  // const GetAllAdmin = () => {
  //   axiosInstance
  //     .get('admin/repairmaintenances')
  //     .then((response) => {
  //       if (response.data.repairMaintenance) {
  //         // console.log(response?.data);
  //         setdata(response?.data?.repairMaintenance);
  //       }
  //       console.log(response, 'resp');
  //     })
  //     .catch((error) => {
  //       console.error(error);
  //     });
  // };
  // React.useEffect(() => {
  //   GetAllAdmin();
  // }, []);
  // async function handleDelete(rowdata) {
  //   console.log(rowdata);
  //   try {
  //     await axiosInstance.delete(`admin/repairmaintenances/${rowdata}`).then((response) => {
  //       if (response?.data?.message === 'Deleted successfully') {
  //         enqueueSnackbar(response?.data?.message);
  //         GetAllAdmin();
  //       }
  //     });
  //   } catch (error) {
  //     enqueueSnackbar(error?.message, {
  //       variant: 'error',
  //     });
  //     console.error(error);
  //   }
  // }
  const columns = React.useMemo(
    () => [
      {
        accessorKey: "id",
        header: "ID",
        size: 20,
      },
      {
        accessorKey: "seatbelt_off_rate",
        header: "Seatbelt",
        size: 20,
      },
      {
        accessorKey: "week",
        header: "Week",
        size: 20,
      },
      {
        accessorKey: "year",
        header: "Year",
        size: 20,
      },
      {
        accessorKey: "speeding_event_rate",
        header: "Speeding",
        size: 20,
      },
      {
        accessorKey: "distractions_rate",
        header: "Distraction",
        size: 20,
      },
      {
        accessorKey: "sign_signal_violations_rate",
        header: "Signs",
        size: 20,
      },
      // {
      //   accessorKey: "empty",
      //   header: "EOC",
      //   size: 20,
      // },
      {
        accessorKey: "eoc",
        header: "EOC",
        size: 20,
        Cell: ({ row }) => {
          if (
            (dataone[0]?.transport_id?.eocs[0]?.week === dataone[0]?.score_cards[0]?.week) &&
            (dataone[0]?.transport_id?.eocs[0]?.year === dataone[0]?.score_cards[0]?.year)
          ) {
            // console.log(dataone[0]?.transport_id?.eocs[0]?.current_avg)
            return (
              <Box>
                { Number(dataone[0]?.transport_id?.eocs[0]?.current_avg)*100+'%'}
              </Box>
            );
          } else {
            return (
              <Box>
                0
              </Box>
            );
          }
        },
      },

      //   {
      //     accessorKey: "actions",
      //     header: "Actions",
      //     size: 10,
      //     Cell: ({ row }) => (
      //       <Box>
      //         <IconButton
      //           color="primary"
      //           sx={{
      //             border: "1px solid",
      //             borderColor: "primary.main",
      //             marginRight: "5px" // Add margin to create a gap
      //           }}
      //           onClick={() => { navigate(`/dashboard/maintenance/${row.original.id}`) }}
      //         >
      //           <EditIcon />
      //         </IconButton>
      //         <IconButton
      //           color="error"
      //           sx={{
      //             border: "1px solid",
      //             borderColor: "error.main",
      //           }}
      //           onClick={() => {    handleDelete(row.original.id);
      //           }}
      //         >
      //           <DeleteIcon />
      //         </IconButton>
      //       </Box>
      //     ),
      //   },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dataone]
  );

  const [tableData, setTableData] = React.useState([]);

  const data = [
    {
      id: 1,
      date: "2024-04-09",
      warranty: "Yes",
      body: "Sedan",
      engine: "V6",
      shop: "ABC Auto Repair",
      description: "Oil change and filter replacement",
      estCost: "$50",
    },
    {
      id: 2,
      date: "2024-04-10",
      warranty: "No",
      body: "Truck",
      engine: "V8",
      shop: "XYZ Auto Service",
      description: "Brake pad replacement",
      estCost: "$100",
    },
    // Add more data as needed
  ];

  React.useEffect(() => {
    setTableData(data);
  }, []);

  return (
    <Page title="Repair And Maintenance">
      <Container maxWidth='lg'>
        <HeaderBreadcrumbs
          heading="Safety Profile"
          links={[
            { name: '', href: '' },]}
          action={
            <>
            </>
            // <Button
            //   variant="contained"
            //   startIcon={<Iconify icon="eva:plus-fill" />}
            //   component={RouterLink}
            //   to={'/dashboard/maintenance'}
            //   // onClick={() => { navigate(`/dashboard/maintenance`) }}


            // >
            //   Repair And Maintenance
            // </Button>
          }
        />
        <MaterialReactTable
          columns={columns}
          data={dataone[0]?.score_cards || []}
          enableRowActions={false} // Disable default row actions
        />
      </Container>
    </Page>
  );
}
