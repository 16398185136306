

/* eslint-disable */
import * as React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import MaterialReactTable from "material-react-table";
import AccordionDetails from '@mui/material/AccordionDetails';
import TextsmsIcon from '@mui/icons-material/Textsms';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import EditIcon from "@mui/icons-material/Edit";
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import SettingsIcon from '@mui/icons-material/Settings';
import * as XLSX from 'xlsx';
import { Delete as DeleteIcon } from "@mui/icons-material";
import { useNavigate, Link as RouterLink } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { Box, IconButton, Container, Button, Modal, Typography, TextField, Tooltip } from '@mui/material';
import Page from '../../../components/Page';
import { PATH_DASHBOARD } from '../../../routes/paths';
// import HeaderBreadcrumbs from 'src/components/HeaderBreadcrumbs';
import HeaderBreadcrumbs from '../../../components/HeaderBreadcrumbs';
// import Button from 'src/theme/overrides/Button';
// import Button from '../../../../theme/overrides/Button';
import Iconify from '../../../components/Iconify';
import axiosInstance from '../../../utils/axios';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    height: '400px',
    overflowY: 'scroll',
    backgroundColor: 'background.paper',
    borderRadius: '10px',
    boxShadow: 24,
    padding: 4,
};
export default function Message({ id }) {
    const [selectedRowIds, setSelectedRowIds] = React.useState([]);
console.log(selectedRowIds,'selectedRowIds');
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const handleOpenModal2 = (params) => {
        console.log(params);
        handleOpen();
    };

    const handleCloseModal = () => {
        handleClose();
    };

    const handleSendMessage = () => {
        console.log('Selected IDs:', selectedRowIds);
        selectedRowIds?.map((id) => {
            console.log('Selected IDs', id);
        });
    };

    const handleDownload = () => {
        const wb = XLSX.utils.book_new();
        const ws = XLSX.utils.json_to_sheet(rows);
        console.log(ws);
        XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
        XLSX.writeFile(wb, 'table-data.xlsx');
    };

    const handleCheckboxChange = (e) => {
        const rowId = e.target.value;
        const isChecked = e.target.checked;
        handleRowSelected(rowId, isChecked);
    };

const handleRowSelected = (rowId, isChecked) => {
    if (isChecked) {
        setSelectedRowIds(prevIds => [...prevIds, rowId]);
    } else {
        setSelectedRowIds(prevIds => prevIds.filter(id => id !== rowId));
    }
};
    const { enqueueSnackbar } = useSnackbar();
    const navigate = useNavigate();
    // console.log(id);
    const [Data, setdata] = React.useState([]);
    // console.log(Data, 'Data');
    const idone = localStorage.getItem('e_id');
    const GetMessage = () => {
        axiosInstance
            .get(`admin/standbyassociates`)
            .then((response) => {
                if (response.data.standByAssociate) {
                    // console.log(response?.data);
                    setdata(response?.data?.standByAssociate);
                }
                console.log(response?.data.standByAssociate, 'resp');
            })
            .catch((error) => {
                console.error(error);
            });
    };
    React.useEffect(() => {
        GetMessage();
    }, []);
    const handleOpenModal1 = () => {
        // if (selectedRowIds?.length === 0) {
        //   enqueueSnackbar('Please select rows to send message', {
        //     variant: 'error',
        //   });
        // } else {
        handleOpen();
        // }
    };
    async function handleDelete(rowdata) {
        console.log(rowdata);
        try {
            await axiosInstance.delete(`admin/standbyassociates/${rowdata}`).then((response) => {
                if (response?.data?.message === 'Deleted successfully') {
                    enqueueSnackbar(response?.data?.message);
                    GetMessage();
                }
            });
        } catch (error) {
            enqueueSnackbar(error?.message, {
                variant: 'error',
            });
            console.error(error);
        }
    }
    const columns = React.useMemo(
        () => [
            {
                accessorKey: "Checkbox",
                header: "",
                size: 20,
                Cell: ({ row }) => (
                    <input 
                        type='checkbox' 
                        value={row.original.id} 
                        onChange={handleCheckboxChange} 
                        style={{backgroundColor:"rgba(46, 50, 54, 1)"}}
                    />
                )
            },
            

            {
                accessorKey: "empty",
                header: "Delivery Associates",
                size: 20,
                Cell: ({ row }) =>(
                    <Box>
                        {row.original.employee?.name}
                    </Box>
                )
            },
            {
                accessorKey: "vehicle",
                header: "Vehicle",
                size: 10,
                Cell: ({ row }) =>(
                    <Box>
                        {row.original.vehicle_detail?.name}
                    </Box>
                )
            },
            {
                accessorKey: "device_id1",
                header: "Device#1",
                size: 10,
            },
            {
                accessorKey: "device_id2",
                header: "Device#2",
                size: 20,
            },
            {
                accessorKey: "route",
                header: "Route",
                size: 20,
            },
            {
                accessorKey: "status",
                header: "Status",
                size: 20,
                Cell: ({ row }) =>
                    row?.original?.status == 'OnTime' ? (
                        <Button
                            fullWidth
                            variant="contained"
                            color="success"
                            endIcon={<Iconify icon={'eva:checkmark-circle-2-fill'} />}
                        // onClick={() => {
                        //   handleApproved(row?.original?.id, 0);
                        // }}
                        >
                            {/* {row.original} */}
                            {/* {console.log(row)} */}
                            OnTime
                        </Button>
                    ) : (
                        <Button
                            fullWidth
                            variant="contained"
                            color="error"
                            endIcon={<Iconify icon={'eva:close-circle-fill'} />}
                        // onClick={() => {
                        //   handleApproved(row?.original?.id, 1);
                        // }}
                        >
                            Late
                        </Button>
                    ),
            },


            {
                accessorKey: "actions",
                header: "Actions",
                size: 100,
                Cell: ({ row }) => (
                    <Box
                        sx={{
                            display: 'flex',
                            flexWrap: 'nowrap',
                            gap: '5px',
                            justifyContent: 'flex-center',
                        }}
                    >
                        <IconButton
                            color="primary"
                            sx={{
                                border: "1px solid",
                                borderColor: "primary.main",
                                marginRight: "5px" // Add margin to create a gap
                            }}
                            onClick={() => { navigate(`/dashboard/editstandy/${row.original.id}`, { state: row.original }) }}
                        >
                            <EditIcon />
                        </IconButton>
                        <IconButton
                            color="error"
                            sx={{
                                border: "1px solid",
                                borderColor: "error.main",
                            }}
                            onClick={() => {
                                handleDelete(row.original.id);
                            }}
                        >
                            <DeleteIcon />
                        </IconButton>
                        <IconButton>
                            <SettingsIcon />
                        </IconButton>
                        <IconButton>
                            <TextsmsIcon onClick={() => handleOpenModal1(row.original.id)} />
                        </IconButton>

                    </Box>
                ),
            },
        ],
        // eslint-disable-next-line react-hooks/exhaustive-deps
        []
    );

    const [tableData, setTableData] = React.useState([]);

    const rows = [
        {
            id: 1,
            deliveryassociates: 'Snow',
            vehicle: 'Jon',
            device1: 'Snow',
            device2: 'Snow',
            route: 'Snow',
            actions: 'actions',
            hello: 1
        },
        {
            id: 2,
            deliveryassociates: 'Snow',
            vehicle: 'Jon',
            device1: 'Snow',
            device2: 'Snow',
            route: 'Snow',
            actions: 'actions',
            hello: 1
        },
        {
            id: 3,
            deliveryassociates: 'Snow',
            vehicle: 'Jon',
            device1: 'Snow',
            device2: 'Snow',
            route: 'Snow',
            actions: 'actions',
            hello: 1
        },
        {
            id: 4,
            deliveryassociates: 'Snow',
            vehicle: 'Jon',
            device1: 'Snow',
            device2: 'Snow',
            route: 'Snow',
            actions: 'actions',
            hello: 1
        },
        {
            id: 5,
            deliveryassociates: 'Snow',
            vehicle: 'Jon',
            device1: 'Snow',
            device2: 'Snow',
            route: 'Snow',
            actions: 'actions',
            hello: 0
        },
        {
            id: 6,
            deliveryassociates: 'Snow',
            vehicle: 'Jon',
            device1: 'Snow',
            device2: 'Snow',
            route: 'Snow',
            actions: 'actions',
            hello: 0
        },
        {
            id: 7,
            deliveryassociates: 'Snow',
            vehicle: 'Jon',
            device1: 'Snow',
            device2: 'Snow',
            route: 'Snow',
            actions: 'actions',
            hello: 0
        },


    ];

React.useEffect(()=>{
    setTableData(Data)
},[])
    return (
        <Page title="Dashboard">
            <Container maxWidth='lg'>
                <HeaderBreadcrumbs
                    heading="StandBy Associates"
                    links={[
                        { name: '', href: '' },]}
                    action={
                        <>
                            <Button
                                variant="contained"
                                startIcon={<Iconify icon="eva:plus-fill" />}
                                component={RouterLink}
                                to={'/dashboard/addstandy'}
                            // onClick={() => { navigate(`/dashboard/maintenance`) }}


                            >
                                StandBy Associates
                            </Button>
                            <Tooltip arrow title="Download File">

                                <IconButton style={{ marginLeft: "5px" }}>
                                    <FileDownloadIcon onClick={handleDownload} />
                                </IconButton>
                            </Tooltip>
                        </>
                    }
                />
                <MaterialReactTable
                    columns={columns}
                    checkboxSelection
          
                    data={Data }
              

                />
            </Container>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Typography id="modal-modal-title" variant="h6" component="h2">
                        Send Message
                    </Typography>
                    <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                        <p id="modal-description">Are you sure you want to send a message to the selected users?</p>
                        <TextField id="message" label="Message" multiline rows={4} variant="outlined" fullWidth sx={{ mb: 2 }} />
                        <div className="d-flex justify-content-end">
                            <Button variant="contained" className="mx-2" onClick={handleSendMessage}>
                                Send Message
                            </Button>
                            <Button variant="contained" color="error" onClick={handleCloseModal}>
                                Cancel
                            </Button>
                        </div>
                    </Typography>
                </Box>
            </Modal>
        </Page>
    );
}
