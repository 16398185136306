/* eslint-disable */
import * as React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import MaterialReactTable from "material-react-table";
import { useSnackbar } from 'notistack';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import EditIcon from "@mui/icons-material/Edit";
import { Delete as DeleteIcon } from "@mui/icons-material";
import { useNavigate, Link as RouterLink } from 'react-router-dom';
import { Box, IconButton, Container,Button } from '@mui/material';
import Page from '../../../components/Page';
import { PATH_DASHBOARD } from '../../../routes/paths';
// import HeaderBreadcrumbs from 'src/components/HeaderBreadcrumbs';
import HeaderBreadcrumbs from '../../../components/HeaderBreadcrumbs';
// import Button from 'src/theme/overrides/Button';
// import Button from '../../../theme/overrides/Button';
import Iconify from '../../../components/Iconify';
import axiosInstance from '../../../utils/axios';

export default function FilterTableEmployeeDemage({dataone}) {
  
  // async function handleDelete(rowdata) {
  //   console.log(rowdata);
  //   try {
  //     await axiosInstance.delete(`admin/vehicledemages/${rowdata}`).then((response) => {
  //       if (response?.data?.message === 'Deleted successfully') {
  //         enqueueSnackbar(response?.data?.message);
  //         GetAllAdmin();
  //       }
  //     });
  //   } catch (error) {
  //     enqueueSnackbar(error?.message, {
  //       variant: 'error',
  //     });
  //     console.error(error);
  //   }
  // }

  const columns = React.useMemo(
    () => [
      {
        accessorKey: "id",
        header: "ID",
        size: 10,
      },
      {
        accessorKey: "incident_type",
        header: "Date",
        size: 10,
      },
      {
        accessorKey: "week",
        header: "Week",
        size: 10,
      },
      {
        accessorKey: "year",
        header: "Year",
        size: 10,
      },
      {
        accessorKey: "severity",
        header: "Severity",
        size: 10,
      },
      {
        accessorKey: "vehicle",
        header: "Vehicle",
        size: 10,
      },
      {
        accessorKey: "at_fault",
        header: "At Fault",
        size: 10,
      },
      
      {
        accessorKey: "claim_number",
        header: "Claim Number",
        size: 10,
      },
      {
        accessorKey: "incident_type",
        header: "Type of Damage",
        size: 10,
      },
      {
        accessorKey: "drug_test_date",
        header: "Drug Test Date",
        size: 10,
      },
      {
        accessorKey: "test_result",
        header: "Results",
        size: 10,
      },
      {
        accessorKey: "est_cost",
        header: "Est. Cost",
        size: 10,
      },
      {
        accessorKey: "investigated_by",
        header: "Investigated by",
        size: 10,
      },
      {
        accessorKey: "displinary_action",
        header: "Displinary Action",
        size: 10,
      },
      
    //   {
    //     accessorKey: "actions",
    //     header: "Actions",
    //     size: 10,
    //     Cell: ({ row }) => (
    //       <Box>
    //         <IconButton
    //           color="primary"
    //           sx={{
    //             border: "1px solid",
    //             borderColor: "primary.main",
    //             marginRight: "5px" // Add margin to create a gap
    //           }}
    //           onClick={() => { navigate(`/dashboard/vdemage/${row.original.id}`,{state:{name:row.original , ID:id}}) }}
    //         >
    //           <EditIcon />
    //         </IconButton>
    //         <IconButton
    //           color="error"
    //           sx={{
    //             border: "1px solid",
    //             borderColor: "error.main",
    //           }}
    //           onClick={() => {handleDelete(row.original.id);}}
    //         >
    //           <DeleteIcon />
    //         </IconButton>
    //       </Box>
    //     ),
    //   },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );
  

  const [tableData, setTableData] = React.useState([]);

  const data = [
    {
      id: 1,
      date: "2024-04-09",
      severity: "Low",
      employee: "John Doe",
      atFault: "No",
      claimStatus: "Pending",
      damageType: "Scratch",
      drugTest: "Yes",
      testResults: "Negative",
      estCost: "$500",
      investigatedBy: "Jane Smith",
      disciplinaryAction: "Written Warning",
    },
    {
      id: 2,
      date: "2024-04-10",
      severity: "High",
      employee: "Alice Johnson",
      atFault: "Yes",
      claimStatus: "Approved",
      damageType: "Dent",
      drugTest: "No",
      testResults: "N/A",
      estCost: "$1000",
      investigatedBy: "Bob Brown",
      disciplinaryAction: "Termination",
    },

  ];
  

  React.useEffect(() => {
    setTableData(data);
  }, []);

  return (
    <Page title="Vehicle Demage">
      <Container maxWidth='lg'>
      <HeaderBreadcrumbs
          heading="Vehicle Demage"
          links={[
            { name: '', href: '' },]}
          action={
            <>
            </>
            // <Button
            //   variant="contained"
            //   startIcon={<Iconify icon="eva:plus-fill" />}
            //   component={RouterLink}
            //   to={`/dashboard/demage/${id}`}
            //   // onClick={() => { navigate(`/dashboard/vdemage` ) }}


            // >
            // Vehicle Demage
            // </Button>
          }
        />
        <MaterialReactTable
          columns={columns}
          data={dataone[0]?.vehicle_demages || []}
          enableRowActions={false} // Disable default row actions
        />
      </Container>
    </Page>
  );
}
