/* eslint-disable */
import * as Yup from 'yup';
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router-dom';
import { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { LoadingButton } from '@mui/lab';
import { Grid, Card, Stack, DialogActions, Dialog, MenuItem, Box } from '@mui/material';
import { FormProvider, RHFSelect } from '../../../components/hook-form';
import axios from '../../../utils/axios';
import { PATH_DASHBOARD } from '../../../routes/paths';
import axiosInstance from '../../../utils/axios';

function Dia({ isModalOpen1, handleCloseModal1,GetAllAdmin }) {
  const navigate = useNavigate();
  const [Data, setdata] = useState([]);
  const { enqueueSnackbar } = useSnackbar();
  const data = [];
  const year = [];
  for (var i = 1; i <= 60; i++) {
    data.push({
      id: i,
      name: `week ${i}`,
    });
  }
  for (var i = Data?.startYear; i <= Data?.currentYear; i++) {
    year.push({
      // id: i,
      name: `${i}`,
    });
  }
  // console.log(year,'year');
  const NewRaritySchema = Yup.object().shape({
    file: Yup.mixed().required('File is required'),
    week: Yup.string().required('week is required'),
    year: Yup.string().required('year is required'),
  });

  const defaultValues = useMemo(
    () => ({
      file: null,
      week: '',
      year: '',
    }),
    []
  );

  const methods = useForm({
    resolver: yupResolver(NewRaritySchema),
    defaultValues,
  });

  const {
    handleSubmit,
    setValue,
    
    formState: { isSubmitting ,errors},
  } = methods;

  // console.log(errors);
  const onSubmit = async (data) => {
    try {
      const formData = new FormData();
      formData.append('file', data.file);
      formData.append('week', data.week);
      formData.append('year', data.year);

      const response = await axios.post('admin/customerfeedbackextracts',  formData);

      enqueueSnackbar('Created Successfully');
      handleCloseModal1();
      // navigate(PATH_DASHBOARD.rarity.rarity);
      GetAllAdmin();
    } catch (error) {
      enqueueSnackbar(error?.message, {
        variant: 'error',
      });
      console.error(error);
    }
  };
  const GetYear = () => {
    axiosInstance
      .get('/admin/years')
      .then((response) => {
        // console.log(response.data)
        if (response?.data) {
          // console.log(response?.data);
          setdata(response?.data);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };
  useEffect(() => {
    GetYear();
  }, []);

  return (
    <Dialog open={isModalOpen1} onClose={handleCloseModal1} maxWidth="md">
      <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={1}>
          <Grid item xs={12} md={12}>
            <Card sx={{ p: 3 }}>
              <RHFSelect
                name="week"
                label="Select Week"
                size="large"
                InputLabelProps={{ shrink: true }}
                SelectProps={{ native: false, sx: { textTransform: 'capitalize' } }}
              >
                {data?.map((e) => (
                  <MenuItem
                    value={e?.name}
                    sx={{
                      mx: 1,
                      my: 0.5,
                      borderRadius: 0.75,
                      typography: 'body2',
                      textTransform: 'capitalize',
                    }}
                    // onClick={()=>setemID(e?.id)}
                  >
                    {e?.name}
                    {/* hi */}
                  </MenuItem>
                ))}
              </RHFSelect>
              <Box sx={{ mt: 2 }} />
              <RHFSelect
                name="year"
                label="Select Year"
                size="large"
                InputLabelProps={{ shrink: true }}
                
                SelectProps={{ native: false, sx: { textTransform: 'capitalize' } }}
              >
                {year?.map((e) => (
                  <MenuItem
                    value={e?.name}
                    sx={{
                      mx: 1,
                      my: 0.5,
                      borderRadius: 0.75,
                      typography: 'body2',
                      textTransform: 'capitalize',
                    }}
                    // onClick={()=>setemID(e?.id)}
                  >
                    {e?.name}
                    {/* hi */}
                  </MenuItem>
                ))}
              </RHFSelect>
              <Stack spacing={3} mt={2}>
                <input type="file" onChange={(e) => setValue('file', e.target.files[0])} />
                <p style={{color:'red'}}>{errors?.file?.message}</p>
                <Grid item xs={4} md={4}>
                  <LoadingButton type="submit" variant="contained" size="large" loading={isSubmitting}>
                    Add File
                  </LoadingButton>
                </Grid>
              </Stack>
            </Card>
          </Grid>
        </Grid>
      </FormProvider>
      <DialogActions>
        {/* <Button onClick={handleCloseModal1} color="primary">
        Close
      </Button> */}
      </DialogActions>
    </Dialog>
    
  );
}

export default Dia;