/* eslint-disable */
import * as React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import MaterialReactTable from 'material-react-table';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import EditIcon from '@mui/icons-material/Edit';
import { Delete as DeleteIcon } from '@mui/icons-material';
import { useNavigate, Link as RouterLink } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { Box, IconButton, Container, Button } from '@mui/material';
import Page from '../../../components/Page';
import { PATH_DASHBOARD } from '../../../routes/paths';
// import HeaderBreadcrumbs from 'src/components/HeaderBreadcrumbs';
import HeaderBreadcrumbs from '../../../components/HeaderBreadcrumbs';
// import Button from 'src/theme/overrides/Button';
// import Button from '../../../theme/overrides/Button';
import Iconify from '../../../components/Iconify';
import axiosInstance from '../../../utils/axios';

export default function FilterTable({ Repair }) {


  const columns = React.useMemo(
    () => [
  
      {
        accessorKey: 'date',
        header: 'Date',
        size: 20,
      },
      {
        accessorKey: 'warranty',
        header: 'Warranty',
        size: 20,
      },
      {
        accessorKey: 'body',
        header: 'Body',
        size: 20,
      },
      {
        accessorKey: 'engine',
        header: 'Engine',
        size: 20,
      },
      {
        accessorKey: 'shop',
        header: 'Shop',
        size: 20,
      },
      {
        accessorKey: 'decription',
        header: 'Description',
        size: 20,
      },
      {
        accessorKey: 'est_cost',
        header: 'Est. Cost',
        size: 20,
      },
      //   {
      //     accessorKey: "actions",
      //     header: "Actions",
      //     size: 10,
      //     Cell: ({ row }) => (
      //       <Box>
      //         <IconButton
      //           color="primary"
      //           sx={{
      //             border: "1px solid",
      //             borderColor: "primary.main",
      //             marginRight: "5px" // Add margin to create a gap
      //           }}
      //           onClick={() => { navigate(`/dashboard/maintenance/${row.original.id}`) }}
      //         >
      //           <EditIcon />
      //         </IconButton>
      //         <IconButton
      //           color="error"
      //           sx={{
      //             border: "1px solid",
      //             borderColor: "error.main",
      //           }}
      //           onClick={() => {    handleDelete(row.original.id);
      //           }}
      //         >
      //           <DeleteIcon />
      //         </IconButton>
      //       </Box>
      //     ),
      //   },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );




  return (
    <Page title="Repair And Maintenance">
      <Container maxWidth="lg">
        <HeaderBreadcrumbs
          heading="Repair And Maintenance"
          links={[{ name: '', href: '' }]}
          action={
            <></>
            // <Button
            //   variant="contained"
            //   startIcon={<Iconify icon="eva:plus-fill" />}
            //   component={RouterLink}
            //   to={'/dashboard/maintenance'}
            //   // onClick={() => { navigate(`/dashboard/maintenance`) }}

            // >
            //   Repair And Maintenance
            // </Button>
          }
        />
        <MaterialReactTable
          columns={columns}
          data={Repair[0]?.repair_maintenances || []}
          enableRowActions={false} // Disable default row actions
        />
      </Container>
    </Page>
  );
}
