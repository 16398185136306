/* eslint-disable */
import * as React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import MaterialReactTable from 'material-react-table';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import EditIcon from '@mui/icons-material/Edit';
import { Delete as DeleteIcon } from '@mui/icons-material';
import { useNavigate, Link as RouterLink } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { Box, IconButton, Container, Button } from '@mui/material';
import Page from '../../components/Page';
// import { PATH_DASHBOARD } from '../../../routes/paths';
// import HeaderBreadcrumbs from 'src/components/HeaderBreadcrumbs';
import HeaderBreadcrumbs from '../../components/HeaderBreadcrumbs';
// import Button from 'src/theme/overrides/Button';
// import Button from '../../../theme/overrides/Button';
import Iconify from '../../components/Iconify';
import axiosInstance from '../../utils/axios';

export default function Acident({ id }) {
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  console.log(id);
  const [Data, setdata] = React.useState([]);
  console.log(Data, 'Data');
  const idone = localStorage.getItem('e_id');
  const GetAllAdmin = () => {
    axiosInstance
      .get(`admin/incidentandinjuries?employee_id=${idone}`)
      .then((response) => {
        if (response.data.incidentAndInjury) {
          // console.log(response?.data);
          setdata(response?.data?.incidentAndInjury);
        }
        console.log(response, 'resp');
      })
      .catch((error) => {
        console.error(error);
      });
  };
  React.useEffect(() => {
    GetAllAdmin();
  }, []);
  async function handleDelete(rowdata) {
    console.log(rowdata);
    try {
      await axiosInstance.delete(`admin/incidentandinjuries/${rowdata}`).then((response) => {
        if (response?.data?.message === 'Deleted successfully') {
          enqueueSnackbar(response?.data?.message);
          GetAllAdmin();
        }
      });
    } catch (error) {
      enqueueSnackbar(error?.message, {
        variant: 'error',
      });
      console.error(error);
    }
  }
  const columns = React.useMemo(
    () => [
  
      {
        accessorKey: 'date',
        header: 'Date',
        size: 20,
      },
      {
        accessorKey: "week",
        header: "Week",
        size: 10,
      },
      {
        accessorKey: "year",
        header: "Year",
        size: 10,
      },
      {
        accessorKey: 'type',
        header: 'Type',
        size: 20,
      },
      {
        accessorKey: 'category',
        header: 'Category',
        size: 20,
      },
      {
        accessorKey: 'lmet_reported',
        header: 'LMET Reported',
        size: 20,
      },
      {
        accessorKey: 'case_no',
        header: 'Case #',
        size: 20,
      },
      {
        accessorKey: 'medical_treatment',
        header: 'Medical Treatment',
        size: 20,
      },
      {
        accessorKey: 'police',
        header: 'Police',
        size: 20,
      },
      {
        accessorKey: 'dept',
        header: 'Dept.',
        size: 20,
      },
      {
        accessorKey: 'investigated_by',
        header: 'Investigated by',
        size: 20,
      },
      {
        accessorKey: 'disposition',
        header: 'Disposition',
        size: 20,
      },
      {
        accessorKey: 'reported_by',
        header: 'Reported by',
        size: 20,
      },

        {
          accessorKey: "actions",
          header: "Actions",
          size: 10,
          Cell: ({ row }) => (
            <Box>
              <IconButton
                color="primary"
                sx={{
                  border: "1px solid",
                  borderColor: "primary.main",
                  marginRight: "5px" // Add margin to create a gap
                }}
                onClick={() => { navigate(`/dashboard/editinjury/${row.original.id}` , {state:row.original}) }}
              >
                <EditIcon />
              </IconButton>
              <IconButton
                color="error"
                sx={{
                  border: "1px solid",
                  borderColor: "error.main",
                }}
                onClick={() => {    handleDelete(row.original.id);
                }}
              >
                <DeleteIcon />
              </IconButton>
            </Box>
          ),
        },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const [tableData, setTableData] = React.useState([]);

  const data = [
    {
      id: 1,
      date: '2024-04-09',
      warranty: 'Yes',
      body: 'Sedan',
      engine: 'V6',
      shop: 'ABC Auto Repair',
      description: 'Oil change and filter replacement',
      estCost: '$50',
    },
    {
      id: 2,
      date: '2024-04-10',
      warranty: 'No',
      body: 'Truck',
      engine: 'V8',
      shop: 'XYZ Auto Service',
      description: 'Brake pad replacement',
      estCost: '$100',
    },
    // Add more data as needed
  ];

  React.useEffect(() => {
    setTableData(data);
  }, []);

  return (
    <Page title="Repair And Maintenance">
      <Container maxWidth="lg">
        <HeaderBreadcrumbs
          heading="Incident and Injury"
          links={[{ name: '', href: '' }]}
          action={
        
            <Button
              variant="contained"
              startIcon={<Iconify icon="eva:plus-fill" />}
              component={RouterLink}
              to={'/dashboard/addinjury'}
              // onClick={() => { navigate(`/dashboard/maintenance`) }}

            >
              Incident and Injury
            </Button>
          }
        />
        <MaterialReactTable
          columns={columns}
          data={Data}
          enableRowActions={false} // Disable default row actions
        />
      </Container>
    </Page>
  );
}
