/* eslint-disable */
import * as React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import MaterialReactTable from 'material-react-table';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import EditIcon from '@mui/icons-material/Edit';
import { useSnackbar } from 'notistack';
import { Delete as DeleteIcon } from '@mui/icons-material';
import { useNavigate, Link as RouterLink } from 'react-router-dom';

import { Box, IconButton, Typography, Button, Paper, Container, Divider } from '@mui/material';
import Page from '../../components/Page';
import { PATH_DASHBOARD } from '../../routes/paths';

import Iconify from '../../components/Iconify';
import HeaderBreadcrumbs from '../../components/HeaderBreadcrumbs';
import Dia from './Dia';
import EmployeeCon from './EmployeeCon';
import axiosInstance from '../../utils/axios';

export default function Employee() {
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const handleCloseModal1 = () => {
    setIsModalOpen1(false);
  };
  const [Data, setdata] = React.useState([]);
  const [loader, setloader] = React.useState(false);
  async function handleDelete(rowdata) {
    console.log(rowdata);
    try {
      await axiosInstance.delete(`admin/employees/${rowdata}`).then((response) => {
        console.log(response);
        if (response?.data?.message === 'Deleted successfully') {
          enqueueSnackbar('Deleted Sucessfully');
          GetAllAdmin();
        }
      });
    } catch (error) {
      enqueueSnackbar(error?.message, {
        variant: 'error',
      });
      console.error(error);
    }
  }
  console.log(Data, 'Data');
  const GetAllAdmin = () => {
    setloader(true);
    axiosInstance
      .get('admin/employees')
      .then((response) => {
        if (response.data?.employee) {
          setdata(response?.data?.employee);
          setloader(false);
        }
        console.log(response, 'resp');
      })
      .catch((error) => {
        setloader(false);
        console.error(error);
      });
  };
  React.useEffect(() => {
    GetAllAdmin();
  }, []);
  const [isModalOpen1, setIsModalOpen1] = React.useState(false);

  const handleOpenModal1 = () => {
    setIsModalOpen1(true);
  };
  const columns = React.useMemo(
    () => [
      {
        accessorKey: 'name',
        header: 'Name',
        size: 20,
      },
      {
        accessorKey: 'commercial_delivery_vehicle',
        header: 'Commercial Delivery Vehicle',
        size: 20,
      },
      {
        accessorKey: 'dob',
        header: 'Date of Birth',
        size: 20,
      },
      {
        accessorKey: 'driverId',
        header: 'Driver ID',
        size: 20,
      },
      {
        accessorKey: 'driver_id',
        header: 'Driver ID',
        size: 20,
      },
      {
        accessorKey: 'driver_license_number',
        header: 'Driver License Number',
        size: 20,
      },
      {
        accessorKey: 'electric_vehicle',
        header: 'Electric Vehicle',
        size: 20,
      },
      {
        accessorKey: 'email',
        header: 'Email',
        size: 20,
      },
      {
        accessorKey: 'expiration_date',
        header: 'Expiration Date',
        size: 20,
      },
      {
        accessorKey: 'gender',
        header: 'Gender',
        size: 20,
      },
      {
        accessorKey: 'hire_date',
        header: 'Hire Date',
        size: 20,
      },
      {
        accessorKey: 'issuing_state',
        header: 'Issuing State',
        size: 20,
      },

      {
        accessorKey: 'note',
        header: 'Note',
        size: 20,
      },
      {
        accessorKey: 'phone_number',
        header: 'Phone Number',
        size: 20,
      },
      {
        accessorKey: 'prefered_vehicle1',
        header: 'Preferred Vehicle 1',
        size: 20,
      },
      {
        accessorKey: 'prefered_vehicle2',
        header: 'Preferred Vehicle 2',
        size: 20,
      },
      {
        accessorKey: 'prefered_vehicle3',
        header: 'Preferred Vehicle 3',
        size: 20,
      },
      {
        accessorKey: 'start_date',
        header: 'Start Date',
        size: 20,
      },
      {
        accessorKey: 'status',
        header: 'Status',
        size: 10,
        Cell: ({ row }) =>
          row?.original?.status == 'Active' ? (
            <Button
              fullWidth
              variant="contained"
              color="success"
              endIcon={<Iconify icon={'eva:checkmark-circle-2-fill'} />}
            >
              Active
            </Button>
          ) : (
            <Button fullWidth variant="contained" color="error" endIcon={<Iconify icon={'eva:close-circle-fill'} />}>
              Inactive
            </Button>
          ),
      },
      {
        accessorKey: 'add',
        header: 'Employee Conseling',
        size: 10,
        Cell: ({ row, rowIndex }) => {
          const handleRepairClick = (id) => {
            localStorage.setItem('e_id', row.original.id);
            navigate('/dashboard/consuling', { state: { id: row.original.id } });
          };

          return (
            <>
              <Button onClick={() => handleRepairClick(row.original.id)} color="primary">
                Add Conseling
              </Button>
              {/* <Button onClick={handleDamageClick} color="primary">Vehicle Damage</Button> */}
            </>
          );
        },
      },
      {
        accessorKey: 'step_van',
        header: 'Step Van',
        size: 20,
      },
      {
        accessorKey: 'transporterId',
        header: 'Transporter ID',
        size: 20,
      },
      {
        accessorKey: 'transporter_id',
        header: 'Transporter ID',
        size: 20,
      },
      {
        accessorKey: 'updated_at',
        header: 'Updated At',
        size: 20,
      },
      {
        accessorKey: 'vc_cargo_van',
        header: 'VC Cargo Van',
        size: 20,
      },
      {
        accessorKey: 'vc_customer_delivery_vehilce',
        header: 'VC Customer Delivery Vehicle',
        size: 20,
      },
      {
        accessorKey: 'vc_electric_vehicle',
        header: 'VC Electric Vehicle',
        size: 20,
      },
      {
        accessorKey: 'vc_step_van',
        header: 'VC Step Van',
        size: 20,
      },
      {
        accessorKey: 'vehicle_detail_id1',
        header: 'Vehicle Detail ID 1',
        size: 20,
      },
      {
        accessorKey: 'vehicle_detail_id2',
        header: 'Vehicle Detail ID 2',
        size: 20,
      },
      {
        accessorKey: 'vehicle_detail_id3',
        header: 'Vehicle Detail ID 3',
        size: 20,
      },
      {
        accessorKey: 'actions',
        header: 'Actions',
        size: 10,
        Cell: ({ row }) => (
          <Box>
            <IconButton
              color="primary"
              sx={{
                border: '1px solid',
                borderColor: 'primary.main',
                marginRight: '5px',
              }}
              onClick={() => {
                navigate(PATH_DASHBOARD.element.editemployee(row.original.id), { state: row.original });
              }}
            >
              <EditIcon />
            </IconButton>
            <IconButton
              color="error"
              sx={{
                border: '1px solid',
                borderColor: 'error.main',
              }}
              onClick={() => {
                handleDelete(row.original.id);
              }}
            >
              <DeleteIcon />
            </IconButton>
          </Box>
        ),
      },
    ],
    []
  );

  const data = [
    {
      id: 1,
      teamLeader: 'John Doe',
      dateOfOccurrence: '2022-04-20',
      associateName: 'Associate A',
      counselingSeverity: 'High',
      Filter_Associates_By_Status: 'Active',
      problemStatement: 'Lorem ipsum dolor sit amet',
      priorDiscussionSummary: 'Lorem ipsum dolor sit amet',
      correctiveActionSummary: 'Lorem ipsum dolor sit amet',
      consequences: 'Lorem ipsum dolor sit amet',
      file: 'example.pdf',
    },
    {
      id: 2,
      teamLeader: 'Jane Smith',
      dateOfOccurrence: '2022-04-21',
      associateName: 'Associate B',
      counselingSeverity: 'Low',
      Filter_Associates_By_Status: 'Inactive',

      problemStatement: 'Lorem ipsum dolor sit amet',
      priorDiscussionSummary: 'Lorem ipsum dolor sit amet',
      correctiveActionSummary: 'Lorem ipsum dolor sit amet',
      consequences: 'Lorem ipsum dolor sit amet',
      file: 'example2.pdf',
    },
    // Add more data as needed
  ];

  const [tableData, setTableData] = React.useState([]);

  React.useEffect(() => {
    setTableData(data);
  }, []);

  return (
    <Page title="Employee">
      <Container maxWidth="lg">
        <HeaderBreadcrumbs
          heading="Employee"
          links={[{ name: '', href: '' }]}
          action={
            <>
              <Button
                variant="contained"
                startIcon={<Iconify icon="eva:plus-fill" />}
                component={RouterLink}
                to={''}
                onClick={handleOpenModal1}
                style={{ marginRight: '8px' }} // Add margin to the right for spacing
              >
                Import
              </Button>

              <Button
                variant="contained"
                startIcon={<Iconify icon="eva:plus-fill" />}
                component={RouterLink}
                to={PATH_DASHBOARD.element.AddEmployee}
              >
                Employee
              </Button>
            </>
          }
        />
        <MaterialReactTable columns={columns} data={Data} enableRowActions={false} state={{ showSkeletons: loader }} />
      </Container>
      <Dia handleCloseModal1={handleCloseModal1} isModalOpen1={isModalOpen1} GetAllAdmin={GetAllAdmin} />
      {/* <Divider sx={{ my: 5 }} /> */}
      {/* <EmployeeCon/> */}
    </Page>
  );
}
