/* eslint-disable no-unused-vars */
/* eslint-disable eqeqeq */
/* eslint-disable react/prop-types */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-nested-ternary */
import { useEffect, useMemo, useState } from 'react';
import { Grid, Container, Button, IconButton, Box } from '@mui/material';
import { useNavigate } from 'react-router';
import MaterialReactTable from 'material-react-table';
import useSettings from '../../hooks/useSettings';
import { PATH_DASHBOARD } from '../../routes/paths';
import Page from '../../components/Page';

import axiosInstance from '../../utils/axios';

import Rostered from './CustomTable/TableThree/Rostered_Associate';
import Standy from './CustomTable/TableFour/Standy';
import ManagerDailyAlert from './DailyUpdate/ManagerDailyAlert';
import DispatcherAlert from './DailyUpdate/DispatcherAlert';
import AddStandup from './DailyUpdate/AddStandup';
import StandupAnnoucment from './DailyUpdate/StandupAnnoucment';
import DailyPerfomance from './DailyUpdate/DailyPerfomance';
import EndOfDay from './DailyUpdate/End_Of_Day';
import AlertNewDriver from './Perfomance Dashboard/AlertNewDriver';
import Alertsafety from './Perfomance Dashboard/AlertSafety';
import Companylevel from './Perfomance Dashboard/CompanyLevel';
import AlertQuality from './Perfomance Dashboard/AlertQuality';
import LowPerfomance from './Perfomance Dashboard/LowPerfomance';
import Tier from './Perfomance Dashboard/Tier';
import AttendanceTracking from './Perfomance Dashboard/AttendanceTracking';

export default function Perfomance() {
  const [roster, setroster] = useState([]);
  const [standby, setstandby] = useState([]);
  const [manager, setmanager] = useState([]);
  const [dispatch, setdispatch] = useState([]);
  const [standup, setstandup] = useState([]);
  const [daily, setdaily] = useState([]);
  const [endof, setendof] = useState([]);
  const [data, setdata] = useState(null);
  const [allData, setalldata] = useState(null);
  const { themeStretch } = useSettings();
  const currentUser = JSON.parse(localStorage.getItem('currentuser'));
  const navigate = useNavigate();

  useEffect(() => {
    if (currentUser?.role === 'company') {
      if (!currentUser?.is_subscribed) {
        localStorage.clear();
        navigate('/subscription');
      }
    }
  }, []);
  const Vehicle = () => {
    axiosInstance
      .get(`/admin/performancedashboards`)
      .then((response) => {
        // console.log(response.data?.employee?.name)
        if (response?.data?.employee) {
          // console.log(response?.data);
          setalldata(response?.data?.employee);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };
  useEffect(() => {
    Vehicle();
  }, []);

  return (
    <Page title="Dashboard">
      <Container maxWidth={themeStretch ? false : 'xl'}>
        <Grid item xs={12} md={12} lg={12}>
          {/* Table Code Start */}
          <Box className="my-5">
            <div className="container">
              <div className="row">
                <div className="col-lg-12">
                  <Alertsafety allData={allData} data={data} />
                </div>
                <div className="col-lg-12">
                  <Companylevel allData={allData} data={data} />
                </div>
                <div className="col-lg-12 mt-5">
                  <AlertQuality allData={allData} data={data} />
                </div>
                <div className="col-lg-12 mt-5">
                  <LowPerfomance allData={allData} data={data} />
                </div>

                <div className="col-lg-12 mt-5">
                  <AlertNewDriver allData={allData} data={data} />
                </div>

                <div className="col-lg-12 mt-5">
                  <Tier allData={allData} data={data} />
                </div>
                <div className="col-lg-12 mt-5">
                  <AttendanceTracking allData={allData} data={data} />
                </div>
              </div>
            </div>
          </Box>
          {/* Table Code End */}
        </Grid>
      </Container>
    </Page>
  );
}
