/* eslint-disable */
import * as React from 'react';

import MaterialReactTable from 'material-react-table';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { Box, Button, Container, Divider, MenuItem } from '@mui/material';
import { RHFSelect, FormProvider } from '../../../../components/hook-form';
import Page from '../../../../components/Page';

import HeaderBreadcrumbs from '../../../../components/HeaderBreadcrumbs';
import { useEffect } from 'react';
import axiosInstance from '../../../../utils/axios';
import { useMemo } from 'react';
import { useState } from 'react';
import Iconify from '../../../../components/Iconify';

export default function OpenRepair({ allData }) {
  const [Data, setdata] = useState([]);
  const NewSubSchema = Yup.object().shape({});
  const defaultValues = useMemo(
    () => ({
      vehicle: '',
    }),
    []
  );

  const methods = useForm({
    resolver: yupResolver(NewSubSchema),
    defaultValues,
  });

  const {
    handleSubmit,
    control,
    formState: { isSubmitting, errors },
  } = methods;

  const data = [
    {
      name: 'GROUNDED',
    },
    {
      name: 'ACTIVE',
    },
    {
      name: 'INACTIVE',
    },
  ];
  const year = [];

  const currentYear = 2024;
  const startYear = 2017;
  for (var i = startYear; i <= currentYear; i++) {
    year.push({
      // id: i,
      name: `${i}`,
    });
  }

  const [selectedItem, setSelectedItem] = useState(null);
  // const [selectedItemone, setSelectedItemone] = useState(null);
  // const handleOptionChangeone = (event) => {
  //   setSelectedItemone(event.target.value);
  // };
  // console.log(selectedItemone, 'selectedItemone');
  const handleOptionChange = (event) => {
    setSelectedItem(event.target.value);
  };
  const GetAllAdmin = () => {
    const url = selectedItem !==null
      ? `admin/fleetdashboard/openrepairs?status=${selectedItem}`
      : 'admin/fleetdashboard/openrepairs';

    axiosInstance
      .get(url)
      .then((response) => {
        if (response.data && response.data.vehicleDetail) {
          setdata(response.data.vehicleDetail);
        } else {
          console.log('Employee data not found in API response');
        }
      })
      .catch((error) => {
        console.error('Error fetching admin data:', error);
      });
  };

  React.useEffect(() => {
    GetAllAdmin();
  }, [selectedItem]);
  const columns = React.useMemo(
    () => [
      {
        accessorKey: 'name',
        header: 'Vehicle',
        size: 20,
      },
      {
        accessorKey: 'location',
        header: 'Location',
        size: 20,
      },
      {
        accessorKey: 'vehicle_type',
        header: 'Repair Type',
        size: 20,
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const onSubmit = async (data) => {};
  const [tableData, setTableData] = React.useState([]);

  const rows = [
    {
      id: 1,
      description: 'Snow',
    },
    {
      id: 2,
      description: 'Snow',
    },
  ];

  React.useEffect(() => {
    setTableData(rows);
  }, []);
  return (
    <Page title="Dashboard">
      <Container maxWidth="lg">
        <HeaderBreadcrumbs heading="Open repairs" links={[{ name: '', href: '' }]} action={<></>} />
        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
          <div style={{ display: 'flex', width: '100%' }}>
            <div style={{ flex: '20%', marginRight: '2%' }}>
              <RHFSelect
                name={`status`}
                label="Status"
                size="large"
                InputLabelProps={{ shrink: true }}
                SelectProps={{ native: false, sx: { textTransform: 'capitalize' } }}
                onChange={handleOptionChange}
              >
                {data?.map((e, i) => (
                  <MenuItem
                    key={e?.id}
                    value={e?.name}
                    sx={{
                      mx: 1,
                      my: 0.5,
                      borderRadius: 0.75,
                      typography: 'body2',
                      textTransform: 'capitalize',
                    }}
                  >
                    {e?.name}
                  </MenuItem>
                ))}
              </RHFSelect>
            </div>
            {/* <div style={{ flex: '20%' }}>
              <RHFSelect
                name={`year`}
                label="Year"
                size="large"
                InputLabelProps={{ shrink: true }}
                SelectProps={{ native: false, sx: { textTransform: 'capitalize' } }}
                onChange={handleOptionChangeone}
              >
                {year?.map((e, i) => (
                  <MenuItem
                    key={e?.name}
                    value={e?.name}
                    sx={{
                      mx: 1,
                      my: 0.5,
                      borderRadius: 0.75,
                      typography: 'body2',
                      textTransform: 'capitalize',
                    }}
                  >
                    {e?.name}
                  </MenuItem>
                ))}
              </RHFSelect>
            </div> */}
          </div>
        </FormProvider>

        <Divider sx={{ mt: 3 }} />
        <MaterialReactTable columns={columns} checkboxSelection data={Data || []} />
      </Container>
    </Page>
  );
}
