/* eslint-disable */
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Container, Box, Typography, MenuItem, Select, Divider, Card, CardContent, Stack, Grid } from '@mui/material'; // Import Typography for text
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import Page from '../../../components/Page';
import HeaderBreadcrumbs from '../../../components/HeaderBreadcrumbs';
import FilterTable from './FilterTable';
import FiltertwoTable from './FilterTableTwo';
import axiosInstance from '../../../utils/axios';

function FilterVechile() {
  const [data, setData] = useState([]);
  const [dataone, setDataone] = useState([]);
  const [selectedItem, setSelectedItem] = useState(null);
  // console.log(data, dataone, 'faiz');
  const GetAllAdmin = () => {
    axiosInstance
      .get(selectedItem == null ? `/admin/vehicledetails` : `/admin/vehicledetails?name=${selectedItem}`)
      .then((response) => {
        console.log(response);
        if (response.data.vehicleDetail) {
          if (selectedItem == null) {
            setData(response.data.vehicleDetail);
          } else {
            setDataone(response.data.vehicleDetail);
          }
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };
  useEffect(() => {
    GetAllAdmin();
  }, [selectedItem]);
  console.log(data, dataone, 'faizu');
  const NewSubSchema = Yup.object().shape({
    Incident: Yup.string().required('Incident is required'),
  });
  const defaultValues = {
    Incident: '',
  };

  const methods = useForm({
    resolver: yupResolver(NewSubSchema),
    defaultValues,
  });

  const {
    handleSubmit,
    formState: { isSubmitting, errors },
    register,
  } = methods;

  const handleSelectChange = (event) => {
    setSelectedItem(event.target.value);
  };
  // console.log(selectedItem  ,'faiz');
  return (
    <Page title="Vehicle">
      <Container maxWidth="lg">
        <HeaderBreadcrumbs heading="" links={[{ name: '', href: '' }]} action={<> </>} />
        <Typography variant="h5" style={{ marginTop: '-40px', marginBottom: '10px' }}>
          Vehicle Information
        </Typography>
        <Select
          {...methods.register('Incident')}
          label="Incident"
          defaultValue=""
          fullWidth
          onChange={handleSelectChange}
        >
          {data.map((item) => (
            <MenuItem key={item.name} value={item.name}>
              {item.name}
            </MenuItem>
          ))}
        </Select>

        {selectedItem && (
          <>

            <Grid container spacing={3}>
              <Grid item xs={12} md={6}>
                <Card sx={{ my: 3 }}>
                  <CardContent>
                    <Stack spacing={2}>
                      <Stack direction="row" justifyContent="space-between">
                        <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                          VIN
                        </Typography>
                        <Typography variant="body1">   {dataone[0]?.vin}</Typography>
                      </Stack>
                      <Divider />
                      <Stack direction="row" justifyContent="space-between">
                        <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                          License Plate
                        </Typography>
                        <Typography variant="body1">{dataone[0]?.license_plate}</Typography>
                      </Stack>
                      <Divider />

                      <Stack direction="row" justifyContent="space-between">
                        <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                          State
                        </Typography>
                        <Typography variant="body1">{dataone[0]?.state}</Typography>
                      </Stack>
                      <Divider />
                      <Stack direction="row" justifyContent="space-between">
                        <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                          Vehicle Type:
                        </Typography>
                        <Typography variant="body1">{dataone[0]?.vehicle_type}</Typography>
                      </Stack>
                      <Divider />
                      <Stack direction="row" justifyContent="space-between">
                        <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                          Make
                        </Typography>
                        <Typography variant="body1">{dataone[0]?.make}</Typography>
                      </Stack>
                      <Divider />
                      <Stack direction="row" justifyContent="space-between">
                        <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                          Model
                        </Typography>
                        <Typography variant="body1">{dataone[0]?.model}</Typography>

                      </Stack>
                    </Stack>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={12} md={6} >

                <Card sx={{ my: 3 }} >
                  <CardContent >
                    <Stack spacing={2}>
                      <Stack direction="row" justifyContent="space-between">
                        <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                          Ownership
                        </Typography>
                        <Typography variant="body1">{dataone[0]?.ownership}</Typography>
                      </Stack>
                      <Divider />
                      <Stack direction="row" justifyContent="space-between">
                        <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                          Status:
                        </Typography>
                        <Typography variant="body1">{dataone[0]?.status}</Typography>
                      </Stack>
                      <Divider />
                      <Stack direction="row" justifyContent="space-between">
                        <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                          Station
                        </Typography>
                        <Typography variant="body1">{dataone[0]?.station == null ? 'Empty' : dataone[0]?.station}</Typography>
                      </Stack>
                      <Divider />
                      <Stack direction="row" justifyContent="space-between">
                        <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                          Mileage
                        </Typography>
                        <Typography variant="body1">{dataone[0]?.mileage == null ? 'Empty' : dataone[0]?.mileage}</Typography>
                      </Stack>
                      <Divider />
                      <Stack direction="row" justifyContent="space-between">
                        <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                          Registration Expiration
                        </Typography>
                        <Typography variant="body1">{dataone[0]?.registration_expiration}</Typography>
                      </Stack>
                      <Divider />
                      <Stack direction="row" justifyContent="space-between">
                        <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                          Inspection Expiration
                        </Typography>
                        <Typography variant="body1">{dataone[0]?.inspection_expiration}</Typography>
                      </Stack>
                      <Divider />
                      <Stack direction="row" justifyContent="space-between">
                        <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                          Ez Pass Tag
                        </Typography>
                        <Typography variant="body1">{dataone[0]?.ez_pass_number}</Typography>
                      </Stack>
                    </Stack>
                  </CardContent>
                </Card>
              </Grid>

            </Grid>
            <div style={{ marginTop: '50px' }}>
              <FilterTable Repair={dataone} />

              <Divider sx={{ my: 5 }} />
              {/* <FiltertwoTable Demage={dataone[0]?.vehicle_demages} /> */}
              <FiltertwoTable demage={dataone} />
            </div>
          </>
        )}
      </Container>
    </Page>
  );
}

export default FilterVechile;
